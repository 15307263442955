import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient,} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';
import {BaseService} from './base-service';
import {constants} from '../shared/constants/constants';
import {ApplicationService} from './application.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {createTimeout} from '../lib/Util';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class MeterService extends BaseService {
    onMeterStatus = new Subject<any>();
    onPinEntrySuccess = new BehaviorSubject<any>(null);

    private updateRate = 10000;

    private timerSub: Subscription = null;
    private pinEntryTimerSub: Subscription = null;

    private meterNotificationId = null;
    private meterStatus = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService,
                private notification: ToastrService,
                private router: Router) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
    }

    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => this.getStatus())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onMeterStatus.next(res);
                }
            }
        );
    }

    startContinuousPinEntry(pin: number): void {
        if (this.pinEntryTimerSub) {
            return;
        }
        this.pinEntryTimerSub = timer(0, 5000).pipe(
            flatMap((cycle) => {
                return this.putOpticalReaderPin(pin).pipe(
                    map((res) => true),
                    catchError((e) => {
                        if (e.status === 401) {
                            this.onPinEntrySuccess.next(true);
                            this.pinEntryTimerSub.unsubscribe();
                            this.pinEntryTimerSub = null;
                        }
                        return of(false);
                    })
                );
            })
        ).subscribe(
            (res) => {
                if (res) {
                    this.pinEntryTimerSub.unsubscribe();
                }
            }
        );
    }


    getStatus(handle_error = true): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.meter.status;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.meter_status}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            map((res: any) => {
                if ('current_status' in res) {
                    const connected = res.current_status === 'CONNECTED_WITH_METER' ? 1 : 0;
                    localStorage.setItem('isMeterConnected', connected.toString());
                }
                return res;
            }),
            catchError((error: any) => {
                if (handle_error) {
                    return this.handleError(error);
                }
                return of(error);
            })
        );
    }

    putOpticalReaderPin(pin: number): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.meter.connect;
        const payload = {smart_reader: {pincode: pin.toString()}};
        return this.http.put(
            url, payload, {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res) => {
                return this.mapDefault(res);
            }),
        );
    }

    private checkMeterStatus(res): void {
        if (!('current_status' in res)) {
            console.log('Field "current_status" missing in response');
            return;
        }

        let notification = null;
        switch (res.current_status.toUpperCase()) {
            case 'READY_FOR_METER_INCLUSION':
                this.meterStatus = 1;
                localStorage.removeItem('isMeterConnected');

                if (this.meterNotificationId === 0) {
                    notification = this.notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
                        timeOut: 0,
                        enableHtml: true,
                        toastClass: 'toast-meterstatus toast'
                    });
                    const s = createTimeout(500).subscribe(() => {
                        $('.go-to-meterstate').click(() => {
                            this.router.navigate(['/registrieren'], {queryParams: {jumpToMeterstate: true}});
                        });
                        s.unsubscribe();
                    });
                    this.meterNotificationId = notification.toastId;
                    notification.onHidden.subscribe(
                        (value) => {
                            this.notification.clear(this.meterNotificationId);
                            this.meterNotificationId = 0;
                        }
                    );
                }
                break;
            case 'CONTACT_WITH_METER':
                this.meterStatus = 2;
                localStorage.removeItem('isMeterConnected');

                if (this.meterNotificationId === 0) {
                    notification = this.notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
                        timeOut: 0,
                        enableHtml: true,
                        toastClass: 'toast-meterstatus toast'
                    });
                    const s = createTimeout(500).subscribe(() => {
                        $('.go-to-meterstate').click(() => {
                            this.router.navigate(['/registrieren'], {queryParams: {jumpToMeterstate: true}});
                        });
                        s.unsubscribe();
                    });

                    this.meterNotificationId = notification.toastId;
                    notification.onHidden.subscribe(
                        (value) => {
                            this.notification.clear(this.meterNotificationId);
                            this.meterNotificationId = 0;
                        }
                    );
                }
                break;
            case 'CONNECTED_WITH_METER':
                this.meterStatus = 3;
                localStorage.setItem('isMeterConnected', '1');

                if (this.meterNotificationId > 0) {
                    this.notification.clear(this.meterNotificationId);
                }

                clearInterval(this.meterNotificationId);
                break;
        }
    }
}
