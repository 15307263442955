import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {UserService} from './user.service';
import {MvpTileConfig} from './mvp.service';
import {Popover} from '../popovers/popover/popover.service';
import {map} from 'rxjs/operators';
import {RemoveTilePopoverConfig} from '../popovers/static.popover.config';
import {LiveDetailsComponent} from '../tiles/live/live-details/live-details.component';
import {MeterDetailsComponent} from '../tiles/meter/meter-details/meter-details.component';
import {FinanceDetailsComponent} from '../tiles/finance/finance-details/finance-details.component';
import {MvpDetailsComponent} from '../tiles/mvp/mvp-details/mvp-details.component';
import {AppliancesDetailsComponent} from '../tiles/appliances/appliances-detail/appliances-detail.component';
import {ConsumptionAlertDetailsComponent} from '../tiles/consumption-alert/consumption-alert-details/consumption-alert-detail.component';
import {ComparisonDetailsComponent} from '../tiles/compare/comparison-details/comparison-details.component';
import {PowerCheckerDetailsComponent} from '../tiles/powerchecker/powerchecker-details/powerchecker-details.component';
import {TodayDetailsComponent} from '../tiles/today/today-details/today-details.component';

@Injectable({
    providedIn: 'root'
})
export class TileService implements OnDestroy {
    currentTiles: TileDef[] = [];

    selectionChanged = new Subject<TileDef[]>();

    private _powerCheckerTilePosition = 8;

    constructor(private _userService: UserService,
                private popover: Popover) {
        this.init();
    }

    ngOnDestroy() {
        this.clearTiles();
    }

    updateTileList(newList: TileDef[]) {
        this.currentTiles = newList;
        this._userService.setActiveUserTiles(this.currentTiles);
        this.selectionChanged.next(this.currentTiles);
    }

    openDetailView(type: TILE_TYPE, data = null): void {
        let component = null;
        let isNotDefaultTile = false;
        switch (type) {
            case TILE_TYPE.LIVE:
                component = LiveDetailsComponent;
                break;
            case TILE_TYPE.METER:
                component = MeterDetailsComponent;
                break;
            case TILE_TYPE.FINANCE:
                component = FinanceDetailsComponent;
                break;
            case TILE_TYPE.CONSUMPTION_ALERT:
                component = ConsumptionAlertDetailsComponent;
                break;
            case TILE_TYPE.APPLIANCES:
                component = AppliancesDetailsComponent;
                break;
            case TILE_TYPE.TODAY:
                component = TodayDetailsComponent;
                break;
            case TILE_TYPE.COMPARISON:
                component = ComparisonDetailsComponent;
                break;
            case TILE_TYPE.POWER_CHECKER:
                component = PowerCheckerDetailsComponent;
                isNotDefaultTile = true;
                break;
            case TILE_TYPE.MVP:
                component = MvpDetailsComponent;
                break;
        }

        if (!component) {
            console.log('no valid component type');
            return;
        }

        let tileDef: TileDef = null;
        if (isNotDefaultTile) {
            if (type === TILE_TYPE.POWER_CHECKER) {
                tileDef = PowerCheckerTile;
            }
            if (type === TILE_TYPE.MVP) {
                tileDef = MVPTile;
            }
        } else {
            tileDef = defaultTilesTyped.find((e) => e.type === type);
        }

        if (!data) {
            data = {color: tileDef.detailColor};
        } else {
            data.color = tileDef.detailColor;
        }

        const config = {
            content: component,
            data,
            hasBackdrop: true,
        };
        const s = this.popover.open(config).afterClosed$.subscribe(() => s.unsubscribe());
    }

    toggleTile(tile: TileDef) {
        if (tile.selected) {
            this.currentTiles.forEach((it) => {
                if (it.type === tile.type) {
                    it.selected = !it.selected;
                }
            });
            return;
        }

        const old_idx = this.currentTiles.findIndex((it) => it.type === tile.type);
        this.currentTiles.splice(old_idx, 1);

        tile.selected = true;
        this.currentTiles.push(tile);
        this.updateTileList(this.currentTiles);
    }

    tileAvailable(type: TILE_TYPE) {
        const found = this.currentTiles.find((el) => el.type === type);
        return !!found;
    }

    tilesAvailable(): boolean {
        for (const tile of this.currentTiles) {
            if (tile.selected === false) {
                return true;
            }
        }
        return false;
    }

    tileSelected(type: TILE_TYPE) {
        for (const it of this.currentTiles) {
            if (it.type === type) {
                return it.selected;
            }
        }
        return false;
    }

    setSelected(value: boolean, tile: TILE_TYPE, fromTile = false) {
        if (fromTile && !value) {
            const s = this.popover.open(RemoveTilePopoverConfig).afterClosed$.pipe(
                map(res => res.data)
            ).subscribe((res) => {
                if (res) {
                    this.setTileSelectedState(value, tile);
                }
                s.unsubscribe();
            });
            return;
        }
        this.setTileSelectedState(value, tile);
    }

    enableTileType(tileType: TILE_TYPE): void {
        if (tileType === TILE_TYPE.POWER_CHECKER) {
            this.currentTiles.splice(this._powerCheckerTilePosition, 0, PowerCheckerTile);
        }
    }

    enableMVPTileType(position: number, selected: boolean, config: MvpTileConfig): void {
        // if the tile is already available to the user, set the selected state
        if (this.tileAvailable(TILE_TYPE.MVP)) {
            this.currentTiles.forEach((it) => {
                if (it.type === TILE_TYPE.MVP) {
                    it.selected = selected;
                }
            });
        } else {
            const tile: TileDef = {
                type: TILE_TYPE.MVP,
                title: config.title,
                info: config.description,
                thumbnail: config.imageUrl,
                selected,
                addIndex: this.currentTiles.length - 1
            };
            this.currentTiles.splice(position, 0, tile);
        }
        this.updateTileList(this.currentTiles);
    }

    disableTileType(tileType: TILE_TYPE): void {
        const idx = this.currentTiles.findIndex((it) => it.type == tileType);
        if (idx > -1) {
            this.currentTiles.splice(idx, 1);
            this.updateTileList(this.currentTiles);
            return;
        }
        // console.log(`Tile with type ${tileType} already disabled`);
    }


    getCurrentTiles(): TileDef[] {
        return this.currentTiles;
    }


    clearTiles(): void {
        this.currentTiles = [];
        this.updateTileList(this.currentTiles);
    }

    init(): void {
        const tiles = this._userService.getActiveUserTiles();
        if (tiles) {
            this.currentTiles = tiles;
        } else {
            this.currentTiles = defaultTilesTyped;
            this._userService.setActiveUserTiles(this.currentTiles);
            this._userService.setConsumptionAlertAttribute(false);
        }
        this.selectionChanged.next(this.currentTiles);
    }

    private setTileSelectedState(value: boolean, tile: TILE_TYPE): void {
        this.currentTiles.forEach((it) => {
            if (it.type === tile) {
                it.selected = value;
            }
        });
        this.updateTileList(this.currentTiles);
    }
}

export enum TILE_TYPE {
    LIVE = 'LIVE',
    TODAY = 'TODAY',
    APPLIANCES = 'APPLIANCES',
    COMPARISON = 'COMPARISON',
    METER = 'METER',
    FINANCE = 'FINANCE',
    CONSUMPTION_ALERT = 'CONSUMPTION_ALERT',
    PHASE_CHECKER = 'PHASE_CHECKER',
    POWER_CHECKER = 'POWER_CHECKER',
    MVP = 'MVP',
}

export interface TileDef {
    type: TILE_TYPE;
    title: string;
    info: string;
    thumbnail: string;
    selected: boolean;
    addIndex: number;
    detailColor?: string;
}

const thumbnailPath = '/assets/img/thumbs/';
const thumbnailFileType = '.webp';
const getThumbnailPath = (tile: string): string => {
    return `${thumbnailPath}${tile}${thumbnailFileType}`;
};

export const defaultTilesTyped: TileDef[] = [
    {
        type: TILE_TYPE.LIVE,
        title: 'Live',
        info: 'Anzeige Ihres Stromverbrauchs in Echtzeit',
        thumbnail: getThumbnailPath('live'),
        selected: true,
        addIndex: 0,
        detailColor: '#00aae1'
    },
    {
        type: TILE_TYPE.TODAY,
        title: 'Heute',
        info: 'Ihr heutiger Stromverbrauch und die entstandenen Kosten im Überblick',
        thumbnail: getThumbnailPath('today'),
        selected: true,
        addIndex: 1,
        detailColor: '#64b42d'
    },
    {
        type: TILE_TYPE.APPLIANCES,
        title: 'Geräte',
        info: 'Anzeige des Verbrauchs einzelner Geräte in Ihrem Haushalt und der dabei entstandenen Kosten',
        thumbnail: getThumbnailPath('appliances'),
        selected: true,
        addIndex: 2,
        detailColor: '#c81e82'
    },
    {
        type: TILE_TYPE.COMPARISON,
        title: 'Vergleich',
        info: 'Verbrauchsstatistik und Analysen',
        thumbnail: getThumbnailPath('comparison'),
        selected: true,
        addIndex: 3,
        detailColor: '#f59b00'
    },
    {
        type: TILE_TYPE.METER,
        title: 'Zählerstand',
        info: 'Anzeige Ihres Zählerstands',
        thumbnail: getThumbnailPath('meter'),
        selected: true,
        addIndex: 4,
        detailColor: '#e60055'
    },
    {
        type: TILE_TYPE.FINANCE,
        title: 'Abschlags-Check',
        info: 'Gutschrift- oder Nachzahlungsprognose',
        thumbnail: getThumbnailPath('finance'),
        selected: true,
        addIndex: 5,
        detailColor: '#009ba5'
    },
    {
        type: TILE_TYPE.CONSUMPTION_ALERT,
        title: 'Verbrauchs-Alarm',
        info: 'Alarmfunktion bei hohem Stromverbrauch',
        thumbnail: getThumbnailPath('consumption-alert'),
        selected: true,
        addIndex: 6,
        detailColor: '#eb4b0a'
    },
    // {
    //     type: TILE_TYPE.PHASE_CHECKER,
    //     title: 'PhasenChecker',
    //     info: 'Auslastung der Phasen im Blick',
    //     thumbnail: getThumbnailPath('phase-checker'),
    //     selected: true,
    //     addIndex: 7,
    //     detailColor: '#ffa'
    // },
];

const PowerCheckerTile: TileDef = {
    type: TILE_TYPE.POWER_CHECKER,
    title: 'PowerChecker',
    info: 'Schalten Ihres PowerCheckers',
    thumbnail: getThumbnailPath('powerchecker'),
    selected: true,
    addIndex: 7,
    detailColor: '#00aae1'
};

const MVPTile: TileDef = {
    type: TILE_TYPE.MVP,
    title: '',
    info: '',
    thumbnail: '',
    selected: false,
    addIndex: 8,
};
