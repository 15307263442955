import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit
} from '@angular/core';
import * as moment from 'moment';
import {Globals} from '../../../services/globals.service';
import {ApiService} from '../../../services/api.service';
import {UserService} from '../../../services/user.service';
import {LiveDataService} from '../../../services/live-data.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {LiveChartComponent} from '../../../charts/live-chart/live-chart.component';
import {ElectricityService} from '../../../services/electricity.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {HomestateService} from '../../../services/homestate.service';
import {OpticalReaderService} from '../../../services/optical-reader.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {of, throwError} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {RemainingTimeIndicatorComponent} from '../../../components/remaining-time-indicator/remaining-time-indicator.component';
import {HappyHourService} from '../../../services/happy-hour.service';

@Component({
    selector: 'app-live-tile',
    templateUrl: './live-tile.component.html',
    styleUrls: ['./live-tile.component.scss'],
    providers: [Globals]
})

export class LiveTileComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly type: TILE_TYPE = TILE_TYPE.LIVE;

    refresh: any = [];
    chart: any = {};
    current_consumption = '0';

    currentDataset = [];

    isERNAUser = false;
    valueUnit = 'Watt';

    happyHourFrom = null;
    happyHourTo = null;

    status = {
        trend: 0,
        noZone: true,
    };

    isNotRealtime = false;
    userHasHappyHour = false;

    @ViewChild('happyHourIndicator', {static: false}) hhIndicator: RemainingTimeIndicatorComponent;
    @ViewChild('liveChart', {static: true}) liveChart: LiveChartComponent;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockService: MockDataService,
                private _userService: UserService,
                private liveData: LiveDataService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private electricity: ElectricityService,
                private tiles: TileService,
                private homeState: HomestateService,
                private opticalReader: OpticalReaderService,
                private happyHour: HappyHourService) {
        super();
    }

    /**
     * on init hook
     */
    ngOnInit() {
        this.isERNAUser = this._userService.isEDGUser();
        this.valueUnit = this.isERNAUser ? 'Wh' : 'Watt';
        this.liveChart.setUnit(this.valueUnit);
    }

    ngAfterViewInit() {
        this.liveChart.reset();
        this.liveChart.updateZoomLevel(60 * 60 * 2 * 1000, '%H:%M Uhr');
        this.initializeComponent();
    }

    /**
     * on destroy hook
     */
    ngOnDestroy() {
        clearInterval(this.refresh);
        super.ngOnDestroy();
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    /**
     *
     */
    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    /**
     * Initializes the Component with requests made
     */
    private initializeComponent(): void {
        // initialize chart for demo mode
        if (this.application.isDemoMode()) {
            this.getMockConsumption();
            this.getMockCurrentConsumption();
            this.refresh = setInterval(() => {
                this.getMockConsumption();
                this.getMockCurrentConsumption();
            }, 10000);
            return;
        }

        if (this._userService.isEnviamUser()) {
            this.determineUserHasHappyHour();
            if (this._userService.isEDGUser()) {
                const s = this.opticalReader.onMeterReaderStatus.subscribe(
                    () => null,
                    (error) => console.log(error),
                    () => s.unsubscribe()
                );
                console.log('here');
                this.initPowerApiConnection(true);
                return;
            }
        }

        if (this._userService.isEDGUser()) {
            const s = this.opticalReader.onMeterReaderStatus.subscribe((res) => {
                // this.isNotRealtime = res.mode === 'RT_INACTIVE' && res.battery_status > 0;
            });
            this.addSub(s);
            this.initConsumptionApiConnection();
            return;
        }

        this.initPowerApiConnection();
    }

    private initPowerApiConnection(hourly = false): void {
        this.liveData.startCurrentConsumptionUpdate(hourly);
        const liveS = this.liveData.onLiveConsumptionReceived.pipe(
            flatMap((values) => !values ? throwError({msg: 'initial'}) : of(values))
        ).subscribe(
            (res) => {
                if (!('results' in res)) {
                    return;
                }

                const data = res.results;
                if (data.length === 0) {
                    this.liveChart.showLoadingState(true);
                    return;
                }

                // filter by the last 5 minutes
                const start = moment().subtract(5, 'minutes');
                const filtered = data.filter((el) => {
                    const ts = moment(el.timestamp);
                    return ts >= start && ts <= moment();
                });

                this.currentDataset = filtered;
                this.liveChart.showLoadingState(false);
                this.liveChart.addNewSeries(
                    filtered,
                    'power',
                    1,
                    {zindex: 0, isTileChart: true}
                );

                // find last value
                const fiveMinCopy = filtered.slice().reverse();
                let latest = fiveMinCopy.find((el) => 'power' in el);
                if (latest) {
                    this.current_consumption = latest.power.toLocaleString('de-DE');
                } else {
                    const fullHourCopy = data.slice().reverse();
                    latest = fullHourCopy.find((el) => 'power' in el);
                    this.current_consumption = latest.power.toLocaleString('de-DE');
                }
            },
            (error) => null
        );
        this.addSub(liveS);

        // homestate status live update
        this.homeState.startLiveUpdate();
        const homeS = this.homeState.onHomestateStatus.subscribe(
            (res) => this.handleHomestateStatusResponse(res)
        );
        this.addSub(homeS);
    }

    /**
     * Handle Homestate Status response
     * @param data
     */
    private handleHomestateStatusResponse(data): void {
        if (data.current_zone === 4) {
            this.status.noZone = true;
            return;
        }
        this.status.noZone = false;
        this.status.trend = data.current_zone;
    }

    private initConsumptionApiConnection(): void {
        this.electricity.startLast24hTimerUpdate();
        const s = this.electricity.onConsumption24hUpdate.subscribe((res) => {
            if (!res) {
                return;
            }
            const filtered = res.filter(element => {
                const ts = moment(element.timestamp);
                return ts > moment().subtract(1, 'day') && 'measured' in element;
            });
            if (filtered.length > 0) {
                this.current_consumption = filtered[filtered.length - 1].measured.toLocaleString('de-DE');
                this.liveChart.showLoadingState(false);
                this.liveChart.addNewSeries(
                    filtered,
                    'measured',
                    1,
                    {isTileChart: true});
            } else {
                this.liveChart.showLoadingState();
            }
        });
        this.addSub(s);
    }


    /**
     * Request local mock data
     */
    private getMockConsumption(): void {
        const offset = 5;
        const limit = 0;
        const interval = 1;
        const level = 1;

        const s = this._mockService.getLiveData(offset, level, limit, interval)
            .subscribe(
                (response) => {
                    if (response === null || response === undefined) {
                        return;
                    }
                    if (response.length === 0) {
                        return;
                    }
                    this.liveChart.showLoadingState(false);
                    this.liveChart.addNewSeries(
                        response,
                        'power',
                        1,
                        {zindex: 0, isTileChart: true}
                    );
                }
            );
        this.addSub(s);
    }

    /**
     * Get the current value based on mock data.
     */
    private getMockCurrentConsumption(): void {
        const s = this._mockService.getCurrentConsumption2().subscribe(
            (value: { power: number, timestamp: number }) => {
                if (!('power' in value)) {
                    console.log('Strange values:', value);
                    return;
                }
                const current_consumption = value.power;
                const diff = current_consumption - Math.floor(current_consumption);
                if (diff < 0.5) {
                    this.current_consumption =
                        Math.floor(current_consumption).toLocaleString('de-DE');
                } else {
                    this.current_consumption =
                        Math.ceil(current_consumption).toLocaleString('de-DE');
                }
            }
        );
        this.addSub(s);
    }

    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        const s = this.happyHour.getParticipation().subscribe(
            // this._apiService.userParticipatesInHappyHour().subscribe(
            (res) => {
                if (res) {
                    const now = moment();
                    const parsed_response_data = res as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter(
                        (element) => now >= moment(element.from_date, 'DD/MM/YYYY')
                    );
                    const sorted = filtered.sort((a, b) => {
                        const a_from = moment(a.from_date, 'DD/MM/YYYY');
                        const b_from = moment(b.from_date, 'DD/MM/YYYY');
                        if (a_from.unix() > b_from.unix()) {
                            return -1;
                        } else if (a_from.unix() < b_from.unix()) {
                            return 1;
                        }
                        return 0;
                    });

                    if (sorted.length > 0) {
                        if ('value' in sorted[0]) {
                            this.userHasHappyHour = sorted[0].value === 1;
                            this.getHappyHourSchedule();
                        }
                    }
                }
                s.unsubscribe();
            },
            (error) => {
                console.log('error', error);
            }
        );
    }

    /**
     * Request the users happyhour schedule
     */
    private getHappyHourSchedule(): void {
        const date = moment();
        const s = this.happyHour.getSchedule(date.year(), date.month() + 1).subscribe(
            (res) => {
                if (res) {
                    const today = res.find((element) => {
                        return element.dayofweek === date.isoWeekday();
                    });

                    this.happyHourFrom = moment()
                        .hour(today.starttime)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toDate();

                    this.happyHourTo = moment()
                        .hour(today.endtime)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toDate();

                    if (this.hhIndicator) {
                        this.hhIndicator.setStartTime(this.happyHourFrom, this.happyHourTo);
                    }

                    // this.initializeChart();
                    this.liveChart.addZones(this.calculateNewZones());
                    if (this.currentDataset) {
                        // this.updateChart(this.currentDataset);
                        this.liveChart.addNewSeries(this.currentDataset,
                            'power',
                            1,
                            {zindex: 0}
                        );
                    }
                }
                s.unsubscribe();
            }
        );
    }

    private calculateNewZones(): any {
        return [
            {color: 'rgb(0, 170, 225)'},
            {value: this.happyHourFrom.getTime()},
            {color: 'rgb(20, 60, 140)',},
            {value: this.happyHourTo.getTime()},
            {color: 'rgb(0, 170, 225)'},
        ];
    }


    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Live'
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Live-Details'
            }
        });
    }
}
