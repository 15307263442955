import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {ApplicationService} from './application.service';
import {environment} from '../../environments/environment';
import {catchError, flatMap, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService extends BaseService {
    onInstantaneousUpdate = new Subject<any>();

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private useTestData = true;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    getModel(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.registration.model;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.registration_model}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            flatMap((res: { status: string, data: any }) => {
                if (res) {
                    if (!this.responseValid(res)) {
                        return throwError({msg: 'Model response invalid, post mapping.'});
                    }
                    return of(res['data']);
                }
            }),
            flatMap((mappedResponse) => {
                if (!this.modelResponseValid(mappedResponse)) {
                    return throwError({msg: 'Model response invalid, post mapped validation.'});
                }
                return of(mappedResponse);
            }),
            catchError((error: any) => this.handleError(error))
        );
    }

    getOnline(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.registration.online;
        if (this.application.isDemoMode()) {
            url = 'assets/data/demo/' + constants.demo.files.registration_online + '.json';
        }
        return this.http.get(
            url, {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => {
                return res;
            }),
            catchError((error: any) => this.handleError(error))
        );
    }

    setEmail(email: string): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.registration.email;

        return this.http.put(
            url,
            {email},
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => res),
            catchError((error: any) => this.handleError(error))
        );
    }

    updatePassword(oldpass, newpass1, newpass2): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.registration.setPassword;

        const body = {oldpass, newpass1, newpass2};
        return this.http.put(
            url, body, {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => res),
            catchError((error: any) => this.handleError(error))
        );
    }

    getRegistrationModel(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.registration.model;
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map(res => res),
            catchError((error) => this.handleError(error))
        );
    }

    resetPassword(email: string): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.registration.resetPassword + '/' + email;

        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map(res => res),
            catchError((error) => this.handleError(error))
        );
    }

    validateVoucher(voucher: string, email: string): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.registration.voucher + '/' + voucher + '/' + email;

        return this.http.get(
            url,
            {headers: this.getDefaultHeaders()}
        ).pipe(
            map(res => res),
            catchError((error) => this.handleError(error))
        );
    }

    registerUser(user: { email_address: string, pincode: string, voucher_code: string }): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.registration.noGateway;
        return this.http.post(
            url,
            user,
            {headers: this.getDefaultHeaders()}
        ).pipe(
            map(res => res),
            catchError((error) => this.handleError(error))
        );
    }

    registerDevice(mac: string): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.registration.onboard + '/' + mac;
        return this.http.put(
            url,
            {},
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map(res => res),
            catchError((error) => this.handleError(error))
        );
    }

    optInDevice(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.registration.optIn;
        return this.http.put(
            url,
            {opt_in: 1},
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map(res => res),
            catchError((error) => this.handleError(error))
        );
    }

    private modelResponseValid(response: any): boolean {
        const identifier = 'model_identifier' in response;
        const labelpartner = 'labelpartner' in response;
        return identifier && labelpartner;
    }


}
