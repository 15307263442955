import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {ChangeEmailTexts} from '../../shared/texts/change-email.texts';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
    readonly TEXTS = ChangeEmailTexts;

    submitDisabled = true;
    firstEmail = '';
    secondEmail = '';

    form = new FormGroup({
        email1: new FormControl('', [Validators.required]),
        email2: new FormControl('', [Validators.required]),
    });

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.form.valueChanges.subscribe((changes) => {
            this.firstEmail = changes.email1;
            this.secondEmail = changes.email2;
            this.checkEmailsMatching();
        });
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    submitChange(): void {
        this.popoverRef.close({success: true, value: this.firstEmail});
    }

    private checkEmailsMatching(): void {
        if (this.firstEmail === '' || this.secondEmail === '') {
            this.submitDisabled = true;
            return;
        }

        if (this.firstEmail === this.secondEmail) {
            this.submitDisabled = false;
            return;
        }

        this.submitDisabled = true;
        return;

    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }


}
