import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    submitDisabled = true;
    oldPassword = '';
    firstNewPassword = '';
    secondNewPassword = '';

    form = new FormGroup({
        oldPassword: new FormControl('', [Validators.required]),
        newPassword1: new FormControl('', [Validators.required]),
        newPassword2: new FormControl('', [Validators.required]),
    });


    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.form.valueChanges.subscribe((valueChange) => {
            this.oldPassword = valueChange.oldPassword;
            this.firstNewPassword = valueChange.newPassword1;
            this.secondNewPassword = valueChange.newPassword2;
            this.validateInput();
        });
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    submitChange(): void {
        this.popoverRef.close(
            {
                success: true,
                values: {
                    oldPassword: this.oldPassword,
                    firstNewPassword: this.oldPassword,
                    secondNewPassword: this.oldPassword,
                }
            }
        );
    }

    private validateInput(): void {
        if (this.oldPassword === '' || this.firstNewPassword === '' || this.secondNewPassword === '') {
            this.submitDisabled = true;
            return;
        }

        if (this.firstNewPassword === this.secondNewPassword) {
            this.submitDisabled = false;
            return;
        }

        this.submitDisabled = true;
        return;

    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }
}
