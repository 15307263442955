import {of, throwError, timer} from 'rxjs';
import {
    FirmwareUpdateAvailablePopover,
    ManualPinEntryInfoPopoverConfig,
    ManualPinEntryPopoverConfig,
    OnboardingNoContactPopoverConfig, OnboardingWrongSerialPopoverConfig,
    PinEntryPopoverConfig, PinFailedPopoverConfig, RadioLinkLostPopover
} from '../../popovers/static.popover.config';
import {ToastrService} from 'ngx-toastr';
import {HeartbeatService} from '../../services/heartbeat.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, flatMap, map, mergeMap} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {InitializationService} from '../../services/initialization.service';
import {AnalyticsService} from '../../services/analytics.service';
import {Globals} from '../../services/globals.service';
import {UserService} from '../../services/user.service';
import {Tariff} from '../../classes/user';
import {Title} from '@angular/platform-browser';
import {RegistrationService} from '../../services/registration.service';
import {MeterStatuses} from '../../shared/constants/meter-statuses.constants';
import {Observable} from 'rxjs/Observable';
import {ApiService} from '../../services/api.service';
import {AnimationItem} from 'lottie-web';
import {MeterService} from '../../services/meter.service';
import {ApplicationService} from '../../services/application.service';
import {Popover} from '../../popovers/popover/popover.service';
import * as moment from 'moment';
import {onboardingSteps} from '../../shared/constants/onboarding.constants';
import {constants} from '../../shared/constants/constants';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {InfoViewComponent} from '../../popovers/info-view/info-view.component';


@Component({
    selector: 'iona-app',
    templateUrl: './register.component.html',
    styleUrls: [
        'register.component.scss',
        'register-base.component.scss',
        'register-connection.component.scss',
        'register-sections.component.scss'
    ],
    viewProviders: [ApiService],
    providers: [Globals]
})
export class RegisterComponent implements OnInit, OnDestroy {

    moment = moment;
    readonly onboardingSteps = onboardingSteps;

    refresh: any = [];
    checkMeterStatusInterval: any = [];

    // original
    currentStep = this.onboardingSteps.accountCreation;
    // currentStep = this.onboardingSteps.detailFailed;
    // currentStep = this.onboardingSteps.tariffEntry;
    // currentStep = this.onboardingSteps.opticalReader.deviceSelection;
    // currentStep = this.onboardingSteps.devices.plug.wifiConnected;

    tries = 0;  // Anzahl fehlgeschlagener Versuche das Meter zu Verbinden
    meterStatus = 0;    // Aktueller Meter-Verbindungsstatus

    device = 'box';
    deviceTitle: string;
    macPlaceholder = ' ____ : ____ : ____ ';

    statusTries: any = {    // Anzahl fehlgeschlagener Meter-Verbindungsversuche nach aktuellem Status gegliedert
        step0: 0,
        step1: 0,
        step2: 0,
    };

    statusError: any = {    // Fehlertext bei Verbindungs-Fehler bei zu vielen Versuchen. Ist je nach aktuellem Status unterschiedlich
        title: '',
        text: ''
    };

    connect: any = {
        type: null,
        tries: 0
    };

    user: any = {   // Nutzerdaten
        voucher: null,
        email: null,
        password: null,
        mac: null
    };

    format: any = { // gefordertes Format der MAC
        mac: [
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/
        ]
    };

    input: any = { // Inhalt Input-Feld der MAC
        mac: ''
    };

    tariffInfo: Tariff = {
        name: '',
        basePrice: null,
        workPrice: null,
        dateStart: null,
        dateEnd: null,
    };

    // animation
    saveTariffDisabled = true;
    lottieConfig2 = {
        path: 'assets/anim/onboarding_meter_animation_new.json',
        autoplay: false,
        name: 'Smart Meter Animation',
        loop: true,
        renderer: 'canvas'
    };
    animationReady = false;
    anim: AnimationItem;

    isERNAUser = false;
    isEnviamUser = false;
    readerType: 'A' | 'B' = 'A';
    readerPIN = null;
    pinEntrySubmissionDisabled = true;

    firstMac = null;
    secondMac = null;

    private preventPinErrorPopover = false;
    private preventPinInfoPopover = false;
    private popoverOpen = false;
    private debugLog = true;
    private registrationTries = 0;
    private registrationSub = null;
    private lastRegistrationCall = new Date().getTime();

    constructor(private _titleService: Title,
                private router: Router,
                private route: ActivatedRoute,
                private toast: ToastrService,
                public _apiService: ApiService,
                private _globals: Globals,
                private _userService: UserService,
                private application: ApplicationService,
                private registration: RegistrationService,
                private initialization: InitializationService,
                private analytics: AnalyticsService,
                private auth: AuthService,
                private popover: Popover,
                private heartbeat: HeartbeatService,
                private meter: MeterService,
                private opticalReader: OpticalReaderService) {
    }

    ngOnInit() {
        this._titleService.setTitle('Registrieren | iONA');

        // get previously stored device
        const stored_device = this._userService.getUserDevice();
        if (this.debugLog) {
            console.log('OnInit: got stored device', stored_device);
        }
        if (stored_device !== null && stored_device !== undefined) {
            if (stored_device === constants.application.devices.box) {
                this.device = 'box';
                this.deviceTitle = 'iONA-Box';
            } else if (stored_device === constants.application.devices.plug) {
                this.device = 'plug';
                this.deviceTitle = 'PowerChecker';
            } else if (stored_device === constants.application.devices.plug_optical) {
                this.device = 'plug';
                this.deviceTitle = 'PowerChecker';
                this.isERNAUser = true;
            }
        }

        this.refresh = setInterval(() => {
            if (this.currentStep === 'Z') {
                this.checkOnline();
            }
        }, 10000);

        // Directly Jump to Onboarding?
        this.route.queryParams.subscribe(params => {
            if (typeof params['jumpToOnboarding'] !== 'undefined' && params['jumpToOnboarding']) {
                if (this.debugLog) {
                    console.log('got here from failed login');
                }
                if (this._userService.getActiveUserName() !== null) {
                    if (this.debugLog) {
                        console.log('current user: ', this._userService.getActiveUserName());
                    }
                    this._apiService.getRegistrationModel().subscribe(
                        (model_response: any) => {
                            if (model_response.status === 'ok') {
                                if ('model_identifier' in model_response.data) {
                                    if (this.debugLog) {
                                        console.log('model response', model_response.data);
                                    }
                                    this._userService.setActiveUserProvider(model_response.data.labelpartner);
                                    this.isERNAUser = this._userService.isEDGUser();
                                    switch (model_response.data.model_identifier) {
                                        case constants.application.devices.plug:
                                            this.selectDevice('plug');
                                            break;
                                        case constants.application.devices.plug_optical:
                                            this.isERNAUser = true;
                                            if (this._userService.getActiveUserProvider() === 'enviam') {
                                                this.isEnviamUser = true;
                                            }
                                            this.selectDevice('plug');
                                            break;
                                        default:
                                            if (this.debugLog) {
                                                console.log('Device: box');
                                            }
                                            this.selectDevice('box');
                                            this.setStep(this.onboardingSteps.devices.box.powerConnect);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                            this.router.navigate(['/registrieren']);
                        }
                    );

                    // this.setStep(this.onboardingSteps.devices.box.macEntry);
                } else {
                    this.router.navigate(['/registrieren']);
                }
            }

            if (typeof params['jumpToMeterstate'] !== 'undefined' && params['jumpToMeterstate']) {
                if (this._userService.getActiveUserName() !== null) {
                    if (this.device === 'plug') {
                        this.setStep(this.onboardingSteps.devices.plug.connecting);
                    } else {
                        this.setStep(this.onboardingSteps.devices.box.connecting);
                    }
                    // this.setStep('C');
                    this.startMeterStatusUpdate();
                } else {
                    this.router.navigate(['/registrieren']);
                }
            }

        });

        // MAC Id bereits im Local Storage gesetzt?
        if (this._globals.getIonaMacId() !== null && this._globals.getIonaMacId().length > 0) {
            this.input.mac = this._globals.getIonaMacId();
        }

    }

    ngOnDestroy() {
        clearInterval(this.refresh);
        clearInterval(this.checkMeterStatusInterval);
    }

    /**
     * Voucher validieren
     * @param voucher
     * @param email
     */
    validateVoucher(voucher: string, email: string) {
        if (voucher.startsWith('IGY')) {
            // console.log('innogy detected');
            this.popover.open({
                content: InfoViewComponent,
                hasBackdrop: true,
                position: 'absolute',
                placement: 'center center',
                data: {showContinue: false}
            }).afterClosed$.subscribe((res) => {
                // console.log('closed with data', res);
            });
            return;
        }

        this._apiService.validateVoucher(voucher, email).subscribe(
            // this.registration.validateVoucher(voucher, email).subscribe(
            () => {
                // Erfolg -> Email und Voucher im User setzen und weiter gehts zum nächsten Step
                this.user.voucher = voucher;
                this.user.email = email;

                this.setStep(this.onboardingSteps.passwordEntry);

                this.analytics.trackEvent({
                    action: 'onboarding_voucher_code_enter',
                    properties: {
                        category: 'Onboarding',
                        label: 'true'
                    }
                });
            },
            (error_res: any) => {
                if (error_res.error) {
                    switch (error_res.error.code) {
                        case 106: {
                            this.toast.error('Der eingegebene iONA-Code ist uns nicht bekannt!');
                            break;
                        }
                        default: {
                            this.toast.error('Validierung des iONA-Code fehlgeschlagen!');
                        }
                    }

                    this.analytics.trackEvent({
                        action: 'onboarding_voucher_code_enter',
                        properties: {
                            category: 'Onboarding',
                            label: 'false'
                        }
                    });
                } else {
                    this.toast.error('Bitte geben Sie E-Mail-Adresse und iONA-Code ein!');
                }
            }
        );
    }

    setFirstMACAddress(value: string): void {
        this.firstMac = value;
        this.setStep(this.onboardingSteps.devices.box.macEntry2);
    }

    setFirstPlugMACAddress(value: string): void {
        this.firstMac = value;
        this.setStep(this.onboardingSteps.devices.plug.macEntry2);
    }

    /**
     * Nutzer registrieren
     *
     * @param password
     * @param confirm
     */
    registerUser(password: string, confirm: string) {
        if (password !== confirm) {
            this.toast.error('Ihre Passwörter stimmen nicht überein!');
            return;
        }

        const user = {
            email_address: this.user.email,
            pincode: password,
            voucher_code: this.user.voucher
        };

        // this.registration.registerUser(user).pipe(
        //     flatMap((register_res) => {
        //         this.user.password = password;
        //         return this.auth.login(this.user.email, this.user.password, true).pipe(
        //             catchError(error => of(null))
        //         );
        //     }),
        //     flatMap((login_res) => {
        //         return this.registration.getModel().pipe(
        //             catchError(error => of(null))
        //         );
        //     }),
        //     map((model_response) => {
        //         if (model_response.status === 'ok') {
        //             if ('model_identifier' in model_response.data) {
        //                 console.log('model identifier', model_response.data);
        //                 // apparently its pretty hard to persistenlty assing a device id....
        //                 switch (model_response.data.model_identifier) {
        //                     case constants.application.devices.plug:
        //                         this.selectDevice('plug');
        //                         break;
        //                     default:
        //                         this.selectDevice('box');
        //                 }
        //                 return true;
        //             }
        //         }
        //         return false;
        //     }),
        //     catchError(error => {
        //         return throwError(error);
        //     })
        // ).subscribe(
        //     (result) => {
        //         console.log('result', result);
        //     },
        //     (err) => {
        //         console.log(err);
        //         if ('error' in err) {
        //             if ('error' in err.error) {
        //                 if (err.error.error.code === 264) {
        //                     const msg = err.error.error.message;
        //                     if (msg.includes('MAX length')) {
        //                         this.toast.error('Das gewählte Passwort ist zu lang. Bitte verwenden Sie maximal 16 Zeichen.', 'Passwort ungültig', {timeOut: 6000});
        //                     } else if (msg.includes('Special characters')) {
        //                         this.toast.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.', 'Passwort ungültig', {timeOut: 6000});
        //                     }
        //                 }
        //                 if (err.error.error.code === 102) {
        //                     const msg = err.error.error.message;
        //                     if (msg.includes('already active on')) {
        //                         this.toast.error('Sie haben bereits ein Konto erstellt.', 'Fehler', {timeOut: 6000});
        //                     }
        //
        //                 }
        //             }
        //         }
        //         if (err._body) {
        //             let error: any = JSON.parse(err._body);
        //             switch (error.error.code) {
        //                 case 102: {
        //                     this.toast.info('Sie sind bereits registriert!');
        //                     this.setStep(this.onboardingSteps.devices.box.powerConnect);
        //
        //                     break;
        //                 }
        //                 case 264: {
        //                     console.log('CASE 264 ');
        //                 }
        //                 default: {
        //                     this.toast.error('Registrierung fehlgeschlagen!');
        //                 }
        //             }
        //         } else {
        //             this.toast.error('Registrierung fehlgeschlagen!');
        //         }
        //     }
        // );

        // this.registration.registerUser(user).subscribe(
        this._apiService.registerUser(user).subscribe(
            (response) => {
                this.user.password = password;
                this._apiService.loginUser(this.user.email, this.user.password, true);

                this._apiService.onLoggedIn.subscribe(() =>
                    this._apiService.getRegistrationModel().subscribe(
                        (model_response: any) => {
                            if (model_response.status === 'ok') {
                                if ('model_identifier' in model_response.data) {
                                    // apparently its pretty hard to persistenlty assing a device id....

                                    this.isERNAUser = this._userService.isEDGUser();

                                    switch (model_response.data.model_identifier) {
                                        case constants.application.devices.plug:
                                            this.selectDevice('plug');
                                            break;
                                        case constants.application.devices.plug_optical:
                                            this.selectDevice('plug');
                                            break;
                                        default:
                                            this.selectDevice('box');
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                );
            },
            (err: any) => {
                console.log(err);
                if ('error' in err) {
                    if ('error' in err.error) {
                        if (err.error.error.code === 264) {
                            const msg = err.error.error.message;
                            if (msg.includes('MAX length')) {
                                this.toast.error(
                                    'Das gewählte Passwort ist zu lang. ' + 'Bitte verwenden Sie maximal 16 Zeichen.',
                                    'Passwort ungültig', {timeOut: 6000});
                            } else if (msg.includes('Special characters')) {
                                this.toast.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. ' +
                                    'Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ' +
                                    '! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.',
                                    'Passwort ungültig', {timeOut: 6000});
                            }
                        }
                        if (err.error.error.code === 102) {
                            const msg = err.error.error.message;
                            if (msg.includes('already active on')) {
                                this.toast.error('Sie haben bereits ein Konto erstellt.', 'Fehler', {timeOut: 6000});
                            }

                        }
                    }
                }
                if (err._body) {
                    const error: any = JSON.parse(err._body);
                    switch (error.error.code) {
                        case 102: {
                            this.toast.info('Sie sind bereits registriert!');
                            this.setStep(this.onboardingSteps.devices.box.powerConnect);

                            break;
                        }
                        case 264:
                            console.log('CASE 264 ');
                        default: {
                            this.toast.error('Registrierung fehlgeschlagen!');
                        }
                    }
                } else {
                    this.toast.error('Registrierung fehlgeschlagen!');
                }
            }
        );
    }

    /**
     * Meter registrieren
     * @param mac
     * @param throwError
     */
    registerDevice(mac: string, throwError: boolean = true) {
        this.user.mac = null;

        // mac im LocalStorage speichern
        // this._globals.setIonaMacId(mac);

        if (mac.indexOf('_') < 0) {
            let newMac = mac.replace(/:/g, '');
            let macPart1 = newMac.substring(0, 6);
            let macPart2 = newMac.substring(6);
            newMac = macPart1 + '0000' + macPart2;

            this._apiService.registerDevice(newMac).subscribe(
                (response: any) => {
                    this.registerDeviceSuccess(newMac);
                    if (this.device === 'plug') {
                        if (this.isERNAUser && this.isEnviamUser) {
                            this.connectMeter();
                        }
                        this.setStep(this.onboardingSteps.devices.plug.connecting);
                    } else {
                        this.setStep(this.onboardingSteps.devices.box.connecting);
                    }
                },
                (error: any) => {
                    if (error.error.error.code === 102) {
                        // console.log('register device error:', error.error.error.code, error.error.error.message);
                        if (this.device === 'plug') {
                            this.setStep(this.onboardingSteps.devices.plug.connecting);
                        } else {
                            this.setStep(this.onboardingSteps.devices.box.connecting);
                        }
                        this.registerDeviceSuccess(newMac);
                    } else if (error.error.error.code === 110) {
                        // console.log('register device error:', error.error.error.code, error.error.error.message);
                        this.toast.error('Registrierung fehlgeschlagen');
                    } else if (error.error.error.code === 999) {
                        // console.log('register device error:', error.error.error.code, error.error.error.message);
                        this.toast.error('Registrierung fehlgeschlagen');
                    } else if ('_body' in error) {
                        let jsonError;

                        try {
                            jsonError = JSON.parse(error._body);
                        } catch (e) {
                            if (throwError) {
                                this.registerDeviceError();
                            }
                        }
                    } else if (throwError) {
                        this.registerDeviceError();
                    }
                },
            );
        }
    }

    registerDeviceSuccess(newMac: any) {
        this.user.mac = newMac;
        this.startMeterStatusUpdate();

        this.analytics.trackEvent({
            action: 'onboarding_finish',
            properties: {
                category: 'Onboarding',
                label: 'mode: ' + this.connect.type + '; gateway_id: ' + this.user.mac
            }
        });

        this._apiService.optInDevice().subscribe(
            (response: any) => {
                // console.log('opt in succeeded', response);
            },
            (e) => {
                // console.log('opt in failed with:', e.error.error.code, e.error.error.message);
            }
        );
    }

    registerDeviceError() {
        this.connect.tries++;

        switch (this.connect.type) {
            case 'LAN': {
                // Local Storage MAC entfernen
                this.input.mac = '';
                this._globals.resetIonaMacId();

                this.setStep(this.onboardingSteps.devices.box.notFoundError);

                break;
            }
            case 'AP': {
                if (this.connect.tries >= 3) {
                    this.setStep('Ü');
                } else {
                    this.setStep(this.onboardingSteps.devices.box.connectionError);
                }

                break;
            }
        }
    }

    handleAnimation(anim: any): void {
        this.anim = anim;
        this.anim.stop();

        setTimeout(() => {
            this.playAnimationSegment(0);
            this.animationReady = true;
            this.startMeterStatusUpdate();
        }, 100);
    }

    connectMeter(): void {
        this.meter.putOpticalReaderPin(this.readerPIN).subscribe((res) => {
            console.log('optical reader pin call response: ', res);
        });
    }

    playAnimationSegment(step: number): void {
        if (this.anim === null || this.anim === undefined) {
            return;
        }
        if (!this.animationReady) {
            return;
        }

        if (step === 0) {
            setTimeout(() => {
                this.anim.playSegments([0, 85], true);
            }, 150);
        } else if (step === 1) {
            setTimeout(() => {
                this.anim.playSegments([205, 318], true);
            }, 150);
        } else if (step === 2) {
            setTimeout(() => {
                this.anim.playSegments([350, 508], true);
            }, 150);
        } else if (step === 3) {
            setTimeout(() => {
                this.anim.playSegments([657, 959], true);
            }, 150);
            // setTimeout(() => {
            //     this.anim.playSegments([655, 655], true);
            // }, 150);
        } else if (step === 4) {
            setTimeout(() => {
                this.anim.playSegments([657, 959], true);
            }, 150);
        }
    }

    startMeterStatusUpdate() {
        console.log('regular meter status call');
        if (this.isERNAUser) {
            this.playAnimationSegment(0);
            if (this.isEnviamUser) {

                const sub = timer(0, 5000).pipe(
                    flatMap((t) => {
                        return this.meter.putOpticalReaderPin(this.readerPIN).pipe(
                            map((res) => true),
                            catchError(() => of(false))
                        );
                    }),
                    map((res) => {
                        if (res) {
                            return true;
                        }
                    })
                ).subscribe((res) => {
                    if (res) {
                        sub.unsubscribe();
                        this.ernaEnviamMeterStatusCall();
                        this.checkMeterStatusInterval = setInterval(() => {
                            this.ernaEnviamMeterStatusCall();
                        }, 5000);
                    }
                });
            } else {
                this.ernaMeterStatusCall();
                this.checkMeterStatusInterval = setInterval(() => {
                    this.ernaMeterStatusCall();
                }, 5000);
            }
        } else {
            this.meterStatusCall();
            this.checkMeterStatusInterval = setInterval(() => {
                this.meterStatusCall();
            }, 5000);
        }
    }

    ernaEnviamMeterStatusCall() {
        console.log('erna enviam status call');
        this.opticalReader.getOpticalReaderStatus(true).pipe(
            flatMap((response: any) => this.mapMeterStatusResponse(response))
        ).subscribe(
            (data: any) => {
                if (!data) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                switch (data.current_status) {
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        if (this.meterStatus != 1) {
                            this.meterStatus = 1;
                            this._globals.resetIsMeterConnected();
                            this.playAnimationSegment(1);
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        this.showUpdateAvailableOverlay();
                    case MeterStatuses.COMMISSION_IN_PROGRESS:
                        if (this.meterStatus != 2) {
                            if (data.smartreader.meter_unlocked) {
                                this._globals.setIsMeterConnected();
                                clearInterval(this.checkMeterStatusInterval);
                                this.setStep(this.onboardingSteps.accountSetup);
                            }
                            this.meterStatus = 2;
                            this.playAnimationSegment(2);
                            this._globals.resetIsMeterConnected();
                        }
                        break;
                    case MeterStatuses.VERIFYING_PIN:
                        if (this.meterStatus !== 3) {
                            this.meterStatus = 3;
                            this._globals.setIsMeterConnected();
                            this.playAnimationSegment(3);
                        }
                        if ('pin_entry_mode' in data.smartreader) {
                            if (data.smartreader.pin_entry_mode === 'manual_push_button' ||
                                data.smartreader.pin_entry_mode === 'manual_torch') {
                                if (!this.preventPinInfoPopover) {
                                    this.showManualPinEntryOverlay();
                                }
                            }
                        }
                        break;
                    case MeterStatuses.PIN_FAILED:
                        this.handlePinFailedState();
                        break;
                    case MeterStatuses.CONTACT_NO_DATA_FROM_METER:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.CONTACT_WRONG_METER_SERIAL:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay('radioLinkLostInfo', 'hours', 24)) {
                            break;
                        }
                        if (this.popoverOpen) {
                            return;
                        }
                        this.popover.open(RadioLinkLostPopover).afterClosed$.subscribe(
                            () => {
                            }
                        );
                        this.popoverOpen = true;
                        break;
                    default:
                        this.meterStatus = 0;
                        this._globals.resetIsMeterConnected();
                        this.playAnimationSegment(0);
                }
            },
            (error) => {
                console.log('error', error);
                this.increaseMeterStatusErrorTries();
            }
        );
        return;
    }

    private shouldTriggerTimeBasedOverlay(item: string, timeframe: any, time: number): boolean {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private shouldTriggerAvailableUpdateOverlay(): boolean {
        const storageItem = 'lastUpdateInfo';
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(24, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private showUpdateAvailableOverlay(): void {
        if (!this.shouldTriggerAvailableUpdateOverlay()) {
            return;
        }
        if (this.popoverOpen) {
            return;
        }
        this.popover.open(FirmwareUpdateAvailablePopover).afterClosed$.pipe(
            mergeMap(res => of({type: MeterStatuses.CONNECTED_WITH_METER, res}))
        );
        this.popoverOpen = true;
    }


    /**
     * Trigger yet another overlay for some PIN related issue
     * @private
     */
    private showManualPinEntryOverlay(): void {
        if (!this.popoverOpen) {
            this.popover.open(ManualPinEntryInfoPopoverConfig).afterClosed$.subscribe(() => {
                this.popoverOpen = false;
                this.preventPinInfoPopover = true;
            });
            this.popoverOpen = true;
        }
    }

    /**
     * Handle the PIN_FAILED Meter State
     * @private
     */
    private handlePinFailedState(): void {
        const storageItem = 'lastPinInfo';

        let triggerOverlay = false;
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            triggerOverlay = true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            triggerOverlay = true;
        }

        if (!triggerOverlay) {
            return;
        }

        const sub = this.popover.open(PinFailedPopoverConfig).afterClosed$.pipe(
            mergeMap((dialogData) => {
                if (dialogData.data === null) {
                    this.popoverOpen = false;
                    return null;
                }
                if (dialogData.data) {
                    return this.popover.open(PinEntryPopoverConfig).afterClosed$;
                }
                return this.popover.open(ManualPinEntryPopoverConfig).afterClosed$;
            })
        ).subscribe((result: any) => {
            if (result === null) {
                this.popoverOpen = false;
                return;
            }
            if (typeof result.data === 'string') {
                const pin = parseInt(result.data, 10);
                this.preventPinErrorPopover = true;
                this.meter.startContinuousPinEntry(pin);
                this.meter.onPinEntrySuccess.subscribe((res) => {
                    if (res) {
                        this.preventPinErrorPopover = false;
                    }
                });
            }
            if (result) {
                this.popoverOpen = false;
            }

            this.popoverOpen = false;
        });

        this.popoverOpen = true;
    }

    ernaMeterStatusCall() {
        console.log('erna status call');
        this.opticalReader.getOpticalReaderStatus(true).pipe(
            flatMap((response) => this.mapMeterStatusResponse(response))
        ).subscribe(
            (data: any) => {
                if (!data) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                switch (data.current_status) {
                    // case 'READY_FOR_METER_INCLUSION':
                    case MeterStatuses.INITIAL:
                        if (this.animationReady) {
                            if (this.meterStatus !== 1) {
                                this.playAnimationSegment(1);
                                this.meterStatus = 1;
                                this._globals.resetIsMeterConnected();
                            }
                        }
                        break;
                    case MeterStatuses.CONTACT_WITH_METER:
                        if (this.animationReady) {
                            if (this.meterStatus !== 2) {
                                this.playAnimationSegment(2);
                                this.meterStatus = 2;
                                this._globals.resetIsMeterConnected();
                            }
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (this.animationReady) {
                            if (this.meterStatus !== 3) {
                                this.playAnimationSegment(3);
                                this.meterStatus = 3;
                                this._globals.setIsMeterConnected();
                                clearInterval(this.checkMeterStatusInterval);
                            }
                        }
                        break;
                    case MeterStatuses.CONTACT_NO_DATA_FROM_METER:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.CONTACT_WRONG_METER_SERIAL:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay('radioLinkLostInfo', 'hours', 24)) {
                            break;
                        }
                        if (this.popoverOpen) {
                            return;
                        }
                        this.popover.open(RadioLinkLostPopover).afterClosed$.subscribe(
                            () => {
                            }
                        );
                        this.popoverOpen = true;
                        break;
                    default:
                        this.playAnimationSegment(0);
                }

                this.increaseMeterStatusErrorTries();

            },
            (error) => {
                console.log(error);
                this.increaseMeterStatusErrorTries();
            });
    }

    /**
     * Je nach aktuellem Status eine bestimmte Animation anzeigen und die Versuche der Registrierung mitzählen
     */
    meterStatusCall() {
        this._apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if (!('data' in response)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                if (!('current_status' in response.data)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                switch (response.data.current_status.toUpperCase()) {
                    case 'READY_FOR_METER_INCLUSION':
                        if (this.meterStatus != 1) {
                            this.playAnimationSegment(1);
                            this.meterStatus = 1;
                            this._globals.resetIsMeterConnected();
                        }

                        break;
                    case 'COMMISSION_IN_PROGRESS':
                        if (this.meterStatus != 2) {
                            this.playAnimationSegment(2);
                            this.meterStatus = 2;
                            this._globals.resetIsMeterConnected();
                        }

                        break;
                    case 'CONNECTED_WITH_METER':
                        if (this.meterStatus != 3) {
                            this.playAnimationSegment(3);
                            this.meterStatus = 3;
                            this._globals.setIsMeterConnected();
                            clearInterval(this.checkMeterStatusInterval);
                        }

                        break;
                    default:
                        this.playAnimationSegment(0);
                }
            },
            (error) => {
                console.log(error);
                this.increaseMeterStatusErrorTries();
            });
    }

    /**
     * Versuche der Meter-Registrierung. Ab bestimmter Anzahl an Versuchen wird ein Error-Text angezeigt
     */
    increaseMeterStatusErrorTries() {
        // Versuche erhöhen
        switch (this.meterStatus) {
            case 0:
                this.statusTries.step0++;
                break;
            case 1:
                this.statusTries.step1++;
                break;
            case 2:
                this.statusTries.step2++;
                break;
        }

        // Prüfen ob Maximum erreicht
        switch (this.meterStatus) {
            case 0: // Step0 5 Minuten -> 60 Calls
                if (this.statusTries.step0 >= 60) {
                    this.statusError.title = 'Keine Anmeldung möglich';
                    this.statusError.text = 'Bei der Vorbereitung der iONA Box ist ein Fehler aufgetreten. ' +
                        'Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';
                    this.statusTries = {step0: 0, step1: 0, step2: 0,};
                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 1: // Step1 2 Minuten -> 24 Calls
                // if (this.statusTries.step1 >= 60) {
                if (this.statusTries.step1 >= 24) {
                    this.statusError.title = 'Smart Meter nicht gefunden';
                    this.statusError.text = 'iONA konnte bislang keinen Smart Meter finden. ' +
                        'Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';
                    this.statusTries = {step0: 0, step1: 0, step2: 0,};
                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 2: // Step2 10 Minuten -> 120 Calls
                if (this.statusTries.step2 >= 120) {
                    this.statusError.title = 'Keine Smart Meter-Verbindung';
                    this.statusError.text = 'iONA konnte bislang keine Verbindung zum Smart Meter aufbauen. ' +
                        'Soll ich es weiter versuchen oder möchten Sie den Support kontaktieren?';
                    this.statusTries = {step0: 0, step1: 0, step2: 0,};
                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
        }
    }

    /**
     * Zurücksetzen der Meter-Registrierung und von vorn beginnen
     */
    retryMeterStatus() {
        this.statusTries = {step0: 0, step1: 0, step2: 0,};
        this.startMeterStatusUpdate();
        if (this.device === 'plug') {
            this.setStep(this.onboardingSteps.devices.plug.connecting);
        } else {
            this.setStep(this.onboardingSteps.devices.box.connecting);
        }
    }

    checkOnline() {
        this._apiService.getInitialization().pipe(
            mergeMap((response: any) => {
                if (!('data' in response)) {
                    return throwError(null);
                }
                if (!('profile' in response.data)) {
                    return throwError(null);
                }
                return of(response.data.profile);
            }),
        ).subscribe(
            (result) => {
                if (result.has_electricity_meter <= 0) {
                    this.checkTries();
                }
                console.warn('error occurred');
                // this._router.navigate(['/']);
            },
            (error) => {
                this.checkTries();
            }
        );
        this._apiService.getInitialization().subscribe(
            (data: any) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                    } else {
                        this.checkTries();
                    }
                } else {
                    this.checkTries();
                }
            },
            () => {
                this.checkTries();
            }
        );
    }

    checkTries() {
        this.tries++;
        if (this.tries >= 90) {
            this.setStep('P');
        }
    }

    setIsOnboarding() {
        this._globals.setIsOnboarding();
        // this._apiService.setApplicationState(constants.application.states.live);
        this.application.setApplicationState(constants.application.states.live);
        this.router.navigate(['mein-haushalt']);
    }

    /**
     * Aktuellen Step der Registrierung setzen -> Im Frontend die Steps durchwechseln
     *
     * @param step
     */
    setStep(step: string) {
        // if (!this._globals.getIsMeterConnected()) {
        //     console.log('meter not connected, skipping call');
        //     return;
        // }

        this.currentStep = step;

        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.powerConnect:
            case this.onboardingSteps.devices.box.lanConnect: {
                this.connect = {
                    type: 'LAN',
                    tries: 0
                };
                break;
            }
            case this.onboardingSteps.devices.box.macEntry: {
                if (this.input.mac !== null && this.input.mac.length > 0) {
                    this.registerDevice(this.input.mac, false);
                }

                break;
            }
            case 'W':
            case 'X': {
                this.connect = {
                    type: 'AP',
                    tries: 0
                };

                break;
            }
            case this.onboardingSteps.devices.plug.connecting:
            case this.onboardingSteps.devices.box.connecting:
                if (this.user.email !== null && this.user.email !== undefined) {
                    this._apiService.loginUser(this.user.email, this.user.password);
                }
                break;
            case this.onboardingSteps.accountSetup:
                // this.heartbeat.startLiveUpdate();
                break;
            case this.onboardingSteps.opticalReader.deviceSelection:
                this.startInitialRegistration();
                break;
        }

        // analytics stuff
        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.lanConnect:
            case 'X': {
                this.analytics.trackEvent({
                    action: 'onboarding_start',
                    properties: {
                        category: 'Onboarding',
                        label: 'mode: ' + this.connect.type
                    }
                });

                break;
            }
            case 'I': {
                if (this.connect.type !== null) {
                    this.analytics.trackEvent({
                        action: 'onboarding_cancel',
                        properties: {
                            category: 'Onboarding',
                            label: 'mode: ' + this.connect.type
                        }
                    });
                }

                break;
            }
        }
    }

    private startInitialRegistration(): void {
        if (new Date().getTime() - this.lastRegistrationCall <= 5000) {
            this.startInitialRegistration();
            return;
        }

        this.registrationSub = this.registration.registerDevice(this.user.mac).pipe(
            mergeMap(res => res ? of(true) : throwError(undefined)),
            catchError(error => {
                let isSuccessError = false;
                try {
                    isSuccessError = error.error.code === 102;
                } catch (e) {
                    console.log('e', e);
                    return throwError(undefined);
                }
                return isSuccessError ? of(true) : throwError(undefined);
            })
        ).subscribe(
            () => {
                this.registrationSub.unsubscribe();
                this.registrationSub = null;
            },
            (error) => {
                if (this.registrationTries < 60) {
                    this.startInitialRegistration();
                } else {
                    this.registrationSub.unsubscribe();
                    this.registrationSub = null;
                }
                this.lastRegistrationCall = new Date().getTime();
            },
        );
    }

    private openErrorPopover(data: any): void {
        if (this.popoverOpen) {
            return;
        }

        let config = null;
        let doReallyShow = true;
        switch (data.current_status.toUpperCase()) {
            case 'CONTACT_WRONG_METER_SERIAL': {
                config = OnboardingWrongSerialPopoverConfig;
                const read = data.smartreader.meter_id_read;
                const provisioned = data.smartreader.meter_id_provisioned;
                doReallyShow = read !== provisioned;
                config.data.text = `Der mit dem optischen Ausleser verbundene Stromzähler (${read}) besitzt nicht die korrekte Zählernummer. Bitte stellen Sie sicher, dass der Ausleser mit dem Zähler ${provisioned} verbunden ist.`;
                break;
            }
            case 'CONTACT_NO_DATA_FROM_METER': {
                config = OnboardingNoContactPopoverConfig;
                break;
            }
            default:
                config = OnboardingNoContactPopoverConfig;
                break;
        }

        if (!doReallyShow) {
            return;
        }

        this.popover.open(config).afterClosed$.subscribe(
            (res) => {
                this.connectMeter();
                this.popoverOpen = false;
            }
        );
        this.popoverOpen = true;
    }

    /**
     * On Input focus
     * @param event
     */
    public onInputFocus(event: FocusEvent): void {
        if (event.type === 'focus') {
            event.target['placeholder'] = '';
        } else if (event.type === 'focusout') {
            event.target['placeholder'] = this.macPlaceholder;
        }
    }


    /**
     * On Device selected
     * @param device
     */
    public selectDevice(device): void {
        this.device = device;
        let device_to_set = '';
        switch (device) {
            case 'box':
                this.deviceTitle = 'iONA-Box';
                // this.currentStep = this.onboardingSteps.devices.box.powerConnect;
                device_to_set = constants.application.devices.box;
                break;
            case 'plug':
                this.deviceTitle = 'PowerChecker';
                device_to_set = constants.application.devices.plug;
                if (this.isERNAUser) {
                    // device_to_set = constants.application.devices.plug;
                    // this.currentStep = this.onboardingSteps.opticalReader.deviceSelection;
                    // this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                    if (this.isEnviamUser) {
                        device_to_set = constants.application.devices.plug_optical;
                    }
                }
                break;
            default:
                this.deviceTitle = 'iONA-Box';
                break;
        }
        this._userService.updateUserDevice(device_to_set);
        this.setStep(this.onboardingSteps.analyticsOptIn);
    }

    continueOnboarding(trackingEnabled: boolean): void {
        console.log('continue with tracking enabled:', trackingEnabled);
        this.analytics.changeTrackingState(trackingEnabled);
        switch (this.device) {
            case 'plug':
                if (this.isERNAUser) {
                    this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                    break;
                }
                this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                break;
            default:
                this.setStep(this.onboardingSteps.devices.box.powerConnect);
                break;
        }
    }

    onFormChange(evt): void {
        for (const key of Object.keys(this.tariffInfo)) {
            const el = this.tariffInfo[key];
            if (key !== 'dateEnd') {
                this.saveTariffDisabled = (el === null || el === undefined) || el === '';
            }
        }
    }

    saveProviderInfo(): void {
        this.tariffInfo.workPrice = this.tariffInfo.workPrice / 100;
        if (this.isERNAUser) {
            this._userService.addTarrifInfo(this.tariffInfo);
        }
    }

    setPIN(pin: string): void {
        this.readerPIN = parseInt(pin, 10);
        this.registerDevice(this.input.mac, true);
    }

    openInfo(): void {
        open('https://www.iona-energy.com/de/faq.html', '');
    }

    private mapMeterStatusResponse(response: any): Observable<any> {
        if (!('data' in response)) {
            return of(false);
        }
        if (!('electricity' in response.data)) {
            return of(false);
        }
        if (!('current_status' in response.data.electricity)) {
            return of(false);
        }
        return of(response.data.electricity);
    }


}
