import {ConnectionIndicatorConfig} from '../../components/connection-indicator/connection-indicator.component';

/*
 * Meter connection config
 */
export const MeterConnectionConfig: ConnectionIndicatorConfig = {
    fill: true,
    ranges: [
        {
            max: -1000,
            min: -117,
        },
        {
            max: -117,
            min: -114,
        },
        {
            max: -114,
            min: -1,
        }
    ]
};

export const WifiConnectionConfig: ConnectionIndicatorConfig = {
    fill: true,
    ranges: [
        {
            max: -1000,
            min: -80,
        },
        {
            max: -79,
            min: -66,
        },
        {
            max: -65,
            min: -1,
        }
    ]
};
