import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {SortablejsOptions} from 'angular-sortablejs';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {TileDef, TileService} from '../../services/tile.service';

@Component({
    selector: 'app-sort-tiles',
    templateUrl: './sort-tiles.component.html',
    styleUrls: ['./sort-tiles.component.scss']
})
export class SortTilesComponent implements OnInit {

    sortableTiles = [];

    sort_options: SortablejsOptions = {
        onUpdate: (event: any) => {
            this.updateTileList();
            const tile = this.sortableTiles.filter((item: any) => item.id === this.sortableTiles[event.newIndex].id)[0];
            this.analytics.trackEvent({
                action: 'dashboard_layout_change',
                properties: {
                    category: 'Dashboard',
                    label: 'type: move; card: ' + tile.title + '; position: ' + (event.newIndex + 1) + '; previous_position: ' + (event.oldIndex + 1)
                }
            });
        }
    };

    constructor(public popoverRef: PopoverRef,
                private tilesService: TileService,
                private analytics: TrackAnalyticsService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.initTileList();
    }

    close(): void {
        this.popoverRef.close(null);
    }

    removeTile(tile: TileDef): void {
        this.tilesService.setSelected(false, tile.type);
        this.initTileList();
    }

    private initTileList(): void {
        this.sortableTiles = this.tilesService.getCurrentTiles().filter((it) => it.selected);
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
    }

    private updateTileList(): void {
        const missing = [];
        for (const tile of this.tilesService.getCurrentTiles()) {
            const idx = this.sortableTiles.findIndex((it) => it.type === tile.type);
            if (idx === -1) {
                missing.push(tile);
            }
        }
        this.tilesService.updateTileList(this.sortableTiles.concat(missing));
    }
}
