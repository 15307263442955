import {
    AfterViewChecked,
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[appMatchWidth]'
})
export class MatchWidthDirective implements OnInit, AfterViewInit, AfterViewChecked {
    private height: number;

    constructor(private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngAfterViewInit(): void {
        this.initialize();
    }

    ngAfterViewChecked() {
        this.height = this.elementRef.nativeElement.offsetHeight;
        if (this.elementRef.nativeElement.offsetWidth !== this.height) {
            this.initialize();
        }
    }

    private initialize(): void {
        this.height = this.elementRef.nativeElement.offsetHeight;
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.height}px`);
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initialize();
    }


}
