import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, flatMap, map, mergeMap, tap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class HomestateService extends BaseService {

    onHomestateStatus = new Subject<any>();

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private cachedConfig = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => {
                return this.getStatus();
            })
        ).pipe(
            map((res) => {
                return res;
            }),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onHomestateStatus.next(res);
                }
            }
        );
    }

    getStatus(): Observable<any> {
        // todo make this nice
        if (!this.isMeterConnected()) {
            return of(null);
        }

        return this.http.get(
            this.API_BASE_URL + constants.api.routes.homeState.current,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            catchError((error: any) => this.handleError(error))
        );
    }

    getConfig(): Observable<any> {
        // todo make this nice
        if (!this.isMeterConnected()) {
            return of(null);
        }

        return of(this.cachedConfig).pipe(
            mergeMap(cfg => {
                if (cfg) {
                    return of(cfg);
                } else {
                    let url = this.API_BASE_URL + constants.api.routes.homeState.config;
                    if (this.application.isDemoMode()) {
                        url = `assets/data/demo/${constants.demo.files.home_state_config}.json`;
                    }
                    return this.http.get(
                        url,
                        {headers: this.getDefaultHeaders(this.auth.getToken())}
                    ).pipe(
                        flatMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
                        tap(data => this.cachedConfig = data),
                        catchError((error: any) => this.handleError(error))
                    );
                }

            })
        );
    }

    private isMeterConnected(): boolean {
        const connected = +localStorage.getItem('isMeterConnected');
        if (connected === 1) {
            return true;
        } else if (connected === null || connected === undefined) {
            return true;
        }
        return false;
    }
}
