import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';

export class BaseService {

    static instances = [];

    protected readonly AUTH_BASE_URL = environment.urls.auth;
    protected readonly API_BASE_URL = environment.urls.api;
    protected readonly DEMO_FILES_PATH = '/assets/data/demo/';

    static killAll(): void {
        for (const i of BaseService.instances) {
            i.destroy();
        }
    }

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        BaseService.instances.push(this);
    }

    destroy(): void {
    }

    /**
     * Leftover until the authorizaion flow is implemented correctly
     * @param accessToken
     */
    protected getDefaultHeaders(accessToken: string = '') {
        const headers = new HttpHeaders({
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        if (accessToken && accessToken !== '' && accessToken.length > 0) {
            return new HttpHeaders({Authorization: 'Bearer ' + accessToken});
        }

        return headers;
    }

    /**
     * Handle any ocurring response errors
     * @param error
     */
    protected handleError(error: HttpErrorResponse): Observable<any> {
        if (error.status === 401) {
            this.auth.logoutUser();
            return of(false);
        }
        return throwError(error);
    }

    /**
     * Validate whether the response is generally valid
     * @param res
     */
    protected responseValid(res): boolean {
        if (!res) {
            return false;
        }
        if ('status' in res) {
            if (res.status === 'ok') {
                return 'data' in res;
            }
        }
        return false;
    }

    protected mapDefault(res: any): any {
        if (res) {
            if (!this.responseValid(res)) {
                console.log('Response invalid');
                return null;
            }
            return res['data'];
        }

        return null;
    }

}
