import {Component, OnInit, OnDestroy} from '@angular/core';

import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {getDate, getDayName} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {ApplicationService} from '../../../services/application.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';

@Component({
    selector: 'app-today-details',
    templateUrl: './today-details.component.html',
    styleUrls: ['./today-details.component.scss'],
    viewProviders: []
})

export class TodayDetailsComponent extends BasePopover implements OnInit, OnDestroy {
    refresh: any = [];

    week: any = {
        dayname: null,
        date: null
    };

    today: any = {
        kwh: 0,
        costs: '0,00',
        hours: []
    };

    history: any = {
        kwh: 0,
        costs: '0,00'
    };

    trend: any = {
        direction: 0,
        percent: 0,
        scale: {
            left: 1,
            right: 1
        }
    };

    leftState = 'inactive';
    rightState = 'inactive';

    infoVisible = false;

    constructor(private _apiService: ApiService,
                private _mockData: MockDataService,
                private electricityService: ElectricityService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef) {
        super(popoverRef);
    }

    ngOnInit() {
        this.week = {
            dayname: getDayName(7),
            date: getDate(7)
        };

        if (this.application.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.initializeApiConnection();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        clearInterval(this.refresh);
    }

    /**
     * Initializes connection to the mock API
     */
    private initializeMockData(): void {
        this.getMockConsumption();

        this.refresh = setInterval(() => {
            this.week = {
                dayname: getDayName(7),
                date: getDate(7)
            };
            this.getMockConsumption();
        }, 10000);
        return;

    }

    /**
     * Initializes connection to live API
     */
    private initializeApiConnection(): void {

        this.electricityService.startLiveHoursUpdate();
        const conS = this.electricityService.onConsumptionHoursUpdate.subscribe(
            (res) => {
                this.week = {
                    dayname: getDayName(7),
                    date: getDate(7)
                };

                if (res) {
                    let consumption = 0;
                    let costs = 0;

                    this.today.hours = [];

                    for (const hour_value of res) {
                        if (('measured' in hour_value) && ('cost_measured' in hour_value)) {
                            consumption += hour_value.measured;
                            costs += hour_value.cost_measured;

                            this.today.hours.push({
                                hour: new Date(hour_value.timestamp).getHours(),
                                kwh: (hour_value.measured / 1000).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }),
                                costs: hour_value.cost_measured.toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            });
                        } else {
                            // console.log('attributes missing');
                        }

                    }

                    this.today.hours.reverse();

                    this.today.kwh = (consumption / 1000).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                    this.today.costs = costs.toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });

                    this.getTrend(consumption);
                }

            }
        );
        this.addSub(conS);
    }


    /**
     * Get Mock consumption-alert data
     */
    private getMockConsumption(): void {
        const s = this._mockData.getDayConsumption(0).subscribe(
            (data) => {
                if ('data' in data) {
                    // console.log('detail data', data);
                    let consumption = 0;
                    let costs = 0;

                    this.today.hours = [];

                    for (const con of data.data) {
                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;

                            this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }),
                                costs: con.cost_measured.toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            });

                        }

                    }

                    this.today.hours.reverse();

                    this.today.kwh = (consumption / 1000).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                    this.today.costs = costs.toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });

                    // this.getTrend(consumption-alert);
                    this.getMockTrend(consumption);
                }
                s.unsubscribe();

            }, error => s.unsubscribe()
        );
    }

    /**
     * Get the current Trend based on last weeks data
     * @param consumption_today
     */
    private getTrend(consumption_today) {
        // this._apiService.getDayConsumption(7)
        const s = this.electricityService.getConsumptionForDay(7).subscribe(
            (res: any) => {
                if (res) {
                    this.calculateTrend(res, consumption_today);
                }
                s.unsubscribe();
            }, error => s.unsubscribe()
        );
    }

    /**
     * Get Mock trend
     */
    private getMockTrend(consumption_today): void {
        const s = this._mockData.getDayConsumption(7).subscribe(
            (data) => {
                this.calculateTrend(data.data, consumption_today);
                s.unsubscribe();
            }
        );
    }

    /**
     * Calculates the current trend based on todays summed consumption-alert and the collected dataset from a week ago
     * @param data
     * @param today
     */
    private calculateTrend(data, today): void {
        let consumption = 0;
        let costs = 0;
        let now = new Date();

        for (let con of data) {
            let date: any = new Date(con.timestamp);
            if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                if (('measured' in con) && ('cost_measured' in con)) {
                    consumption += con.measured;
                    costs += con.cost_measured;
                }
            }
        }

        this.history = {
            kwh: (consumption / 1000).toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            costs: costs.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        };

        if (today > consumption) {
            let percent = Math.round(((today - consumption) / consumption) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: 1,
                percent: percent,
                scale: {
                    left: scale / 100,
                    right: 1
                }
            };
        } else if (today < consumption) {
            let percent = Math.round((1 - (today / consumption)) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: -1,
                percent: percent,
                scale: {
                    left: 1,
                    right: scale / 100
                }
            };
        } else {
            this.trend = {
                direction: 0,
                percent: 0,
                scale: {
                    left: 1,
                    right: 1
                }
            };
        }


        /// determine the ui state here because everything else is gargles
        if (this.history.kwh === 0 && this.today.kwh === 0) {
            return;
        }

        if (this.history.kwh > this.today.kwh) {
            this.leftState = 'inactive';
            this.rightState = 'active';
        } else if (this.history.kwh < this.today.kwh) {
            this.rightState = 'higher';
            this.leftState = 'inactive';
        } else if (this.history.kwh === this.today.kwh) {
            this.rightState = 'active';
            this.leftState = 'active';
        }
    }

}
