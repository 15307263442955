import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef,} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {interval, of, Subject, Subscription, throwError} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {LiveDataService} from '../../../services/live-data.service';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {liveDetailERNAZoomLevels, liveDetailZoomLevels} from '../constants/live.constants';
import * as moment from 'moment';
import {LiveChartComponent} from '../../../charts/live-chart/live-chart.component';
import {determineDurationPassed} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {Router} from '@angular/router';
import {OpticalReaderService} from '../../../services/optical-reader.service';
import {HeartbeatService} from '../../../services/heartbeat.service';
import {flatMap, map} from 'rxjs/operators';
import {HappyHourService} from '../../../services/happy-hour.service';
import {RemainingTimeIndicatorComponent} from '../../../components/remaining-time-indicator/remaining-time-indicator.component';

@Component({
    selector: 'app-live-details',
    templateUrl: './live-details.component.html',
    styleUrls: ['./live-details.component.scss'],
    viewProviders: []
})
export class LiveDetailsComponent extends BasePopover implements OnInit, OnDestroy, AfterViewInit {

    private mockSub: Subscription = null;

    infoVisible = false;
    zoomLevels: any = null;

    currentZoomLevelIdx = 1;
    position = 1;

    disabled = true;
    current_consumption = null;

    status: any = {
        trend: 0,
        noZone: true,
        since: null
    };

    private current_demo_idx = 0;
    private mock_data_subscription: Subscription;

    /* Happy hour stuff */
    private onUserHasHappyHour = new Subject<boolean>();
    userHasHappyHour = false;

    yesterdayHappyHourStart = null;
    yesterdayHappyHourEnd = null;
    todayHappyHourStart = null;
    todayHappyHourEnd = null;
    currentHappyHourStart = null;
    currentHappyHourEnd = null;

    currentDataset = [];

    /* Day based data selection */
    private dateFormat = 'DD.MM.YYYY';
    private dateFormatDebug = 'DD.MM.YYYY hh:mm:ss';
    today = moment().format('DD.MM.YYYY');
    baseDate = null;
    lastStartDate = null;
    specificDateMode = false;
    specifiedDateDisplay = null;

    /* ERNA additions */
    isEDGUser = false;
    valueUnit = 'Watt';

    isNotRealtime = false;
    energySaverWarningCollapsed = true;
    energySaverWarningVisible = true;
    lastValueTimestamp = null;

    @ViewChild('happyHourIndicator', {static: false}) hhIndicator: RemainingTimeIndicatorComponent;
    @ViewChild('liveChart', {static: true}) liveChart: LiveChartComponent;
    @ViewChild('dateInput', {static: true}) dateInput: ElementRef;

    constructor(private _apiService: ApiService,
                private _mockService: MockDataService,
                private _userService: UserService,
                private liveData: LiveDataService,
                private homeState: HomestateService,
                private application: ApplicationService,
                private electricity: ElectricityService,
                private router: Router,
                private opticalReader: OpticalReaderService,
                protected popoverRef: PopoverRef,
                private heartbeat: HeartbeatService,
                private happyHour: HappyHourService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.isEDGUser = this._userService.isEDGUser();
        this.valueUnit = this.isEDGUser ? 'Wh' : 'Watt';
        this.liveChart.setUnit(this.valueUnit);
        this.zoomLevels = this.isEDGUser ? liveDetailERNAZoomLevels : liveDetailZoomLevels;

        if (this.isEDGUser) {
            if (this._userService.isEnviamUser()) {
                const s = this.opticalReader.onMeterReaderStatus.subscribe((res) => {
                    this.isNotRealtime = res.mode === 'RT_INACTIVE' && res.battery_status > 0;
                });
                this.addSub(s);
                this.initHappyHour();
            }
        }

        if (this._userService.isEnviamUser()) {
            this.initHappyHour();
        }
    }

    ngAfterViewInit(): void {
        if (this.application.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        if (this._userService.isEDGUser()) {
            if (this._userService.isEnviamUser()) {
                this.initializePowerBasedApiConnection();
                return;
            }

            this.liveChart.updateZoomLevel(this.zoomLevels[this.zoomLevels.length - 1].resolution * 1000, this.zoomLevels[0].format);
            this.initializeConsumptionBasedApiConnection();
            return;
        }

        this.initializePowerBasedApiConnection();
    }

    ngOnDestroy() {
        this.mockSub = this.unsubscribeAndDelete(this.mockSub);
        this.mock_data_subscription = this.unsubscribeAndDelete(this.mock_data_subscription);
        this.heartbeat.destroy();
        super.ngOnDestroy();
    }

    onDateChange(value) {
        this.baseDate = moment(value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.specificDateMode = true;
        this.position = 0;
        this.requestConsumptionForDate();
    }

    zoomIn() {
        if (this.specificDateMode) {
            this.position = 0;
            this.baseDate = this.lastStartDate;
        }
        this.setZoom(this.currentZoomLevelIdx - 1);
    }

    zoomOut() {
        if (this.specificDateMode) {
            this.position = 0;
            this.baseDate = this.lastStartDate;
        }
        this.setZoom(this.currentZoomLevelIdx + 1);
    }

    setZoom(level: number) {
        if ((level !== this.currentZoomLevelIdx) && (!this.disabled)) {
            const zoom = this.zoomLevels.filter((item: any) => item.level === level);
            if (zoom.length < 1) {
                return;
            }
            this.currentZoomLevelIdx = level;
            this.liveChart.updateZoomLevel(zoom[0].resolution * 1000, zoom[0].format);
            this.resetChart();
        }
    }

    stepForward() {
        if (this.specificDateMode) {
            this.position--;
            this.requestConsumptionForDate();
            return;
        }

        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }
    }

    stepBack() {
        if (this.specificDateMode) {
            ++this.position;
            this.requestConsumptionForDate();
            return;
        }

        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
    }

    resetPosition() {
        if (!this.disabled) {
            this.position = 1;
            this.specificDateMode = false;
            this.resetChart();
        }
    }

    resetChart() {
        this.liveChart.reset();
        this.disabled = true;

        if (this.application.isDemoMode()) {
            this.current_demo_idx = 0;
            this.getMockConsumption();
            return;
        }

        if (this.specificDateMode) {
            this.requestConsumptionForDate();
        } else {
            this.getLiveConsumption();
        }
    }

    toggleWarning(event): void {
        const id = event.target.id;
        if (id !== 'hide-button') {
            this.energySaverWarningCollapsed = !this.energySaverWarningCollapsed;
        }
    }

    hideWarning(): void {
        this.energySaverWarningVisible = false;
    }

    routeToSettings(): void {
        this.close();
        this.router.navigate(['einstellungen']);
    }

    /**
     * Initialize ERNA based user api connections
     * using the consumption api instead of live power values
     */
    private initializeConsumptionBasedApiConnection(): void {
        this.getLiveConsumption();

        this.electricity.startLast24hTimerUpdate();
        this.electricity.onConsumption24hUpdate.subscribe((res) => {
            const filtered = res.filter(element => {
                const ts = moment(element.timestamp);
                return ts > moment().subtract(1, 'day');
            });
            if (filtered.length > 0) {
                let copy = filtered.slice();
                const found = copy.reverse().find((el) => el.measured > 0);
                if (found) {
                    this.determineCurrentConsumption(found.measured);
                }
                this.disabled = false;
            }
        });

        this.electricity.startFilteredConsumptionUpdate();
        this.electricity.onConsumptionFilteredUpdate.pipe(
            map((res) => {
                if (this.specificDateMode) {
                    return null;
                }
                const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx)[0];
                const from = moment().subtract(this.position * zoom.offset, 'minutes');
                const to = moment().subtract((this.position * zoom.offset) - zoom.offset, 'minutes');
                let filtered = res.filter(el => {
                    const ts = moment(el.timestamp);
                    return ts >= from && ts <= to;
                });

                const last = filtered[filtered.length - 1];
                if ('measured' in last) {
                    if (last.measured === 0) {
                        filtered = filtered.slice(0, filtered.length - 1);
                    }
                }
                return filtered;
            })
        ).subscribe((res: any) => {
            if (!res) {
                return;
            }
            if (res.length < 0) {
                this.disabled = true;
                this.liveChart.showLoadingState();
                return;
            }

            res.forEach((el) => {
                if (!('measured' in el)) {
                    el['measured'] = 0;
                }
                return el;
            });

            this.liveChart.addNewSeries(res, 'measured', 1, {zindex: 0});
            this.liveChart.showLoadingState(false);
            this.disabled = false;
        });
    }

    /**
     * Initialize live api connections
     */
    private initializePowerBasedApiConnection(): void {
        if (this._userService.isEnviamUser()) {
            this.determineUserHasHappyHour();
        }

        this.getLiveConsumption();

        // current consumption value
        this.liveData.startCurrentConsumptionUpdate(true);
        this.addSub(this.liveData.onLiveConsumptionReceived.pipe(
            flatMap((values) => !values ? throwError({msg: 'initial'}) : of(values))
        ).subscribe(
            (res) => {
                const data = res.results;
                if (data.length === 0) {
                    return;
                }
                // filter by the last 5 minutes
                const start = moment().subtract(5, 'minutes');
                const filtered = data.filter((el) => {
                    const ts = moment(el.timestamp);
                    return ts >= start && ts <= moment();
                });

                // find last value
                const fiveMinCopy = filtered.slice().reverse();
                let latest = fiveMinCopy.find((el) => 'power' in el);
                if (latest) {
                    this.determineCurrentConsumption(latest.power);
                    this.lastValueTimestamp = null;
                } else {
                    const fullHourCopy = data.slice().reverse();
                    latest = fullHourCopy.find((el) => 'power' in el);
                    if (latest) {
                        this.determineCurrentConsumption(latest.power);
                        const tsFormat = 'DD.MM.YYYY HH:MM';
                        this.lastValueTimestamp = moment(latest.timestamp).format(tsFormat);
                    } else {
                        this.current_consumption = '--';
                    }
                }
            },
            (error) => null
        ));

        this.liveData.startFilteredLiveConsumptionUpdate();
        this.addSub(this.liveData.onFilteredLiveConsumptionReceived.subscribe(
            (res) => {
                if (!this.specificDateMode) {
                    console.log('res before handling live data response', res);
                    this.handleLiveDataResponse(res);
                }
            },
            (error) => {
                console.warn('error', error);
                this.liveChart.showLoadingState();
                this.disabled = false;
            }
        ));

        // homestate status live update
        this.homeState.startLiveUpdate();
        const homeS = this.homeState.onHomestateStatus.subscribe(
            (res) => {
                this.handleHomestateStatusResponse(res);
            },
            (error) => {
                console.log('error homesate', error);
            }
        );
        this.addSub(homeS);
    }


    /**
     * Handle live data response from API.
     * @param response
     */
    private handleLiveDataResponse(response: any): void {
        if ('results' in response) {
            const results = response.results;
            this.currentDataset = results;

            if (results.length < 1) {
                this.liveChart.removeSeries(0, 2);
                this.liveChart.showLoadingState();
                return;
            }

            if (this.specificDateMode) {
                const center = results[Math.floor(results.length / 2)];
                this.specifiedDateDisplay = moment(center.timestamp).format(this.dateFormat);
            }

            // calculate max power value
            const max = this.currentDataset.reduce((prev, current) => {
                return (prev.power > current.power) ? prev : current;
            });

            this.liveChart.addNewSeries(results, 'power', 2,
                {zindex: 5});
            this.liveChart.showLoadingState(false);
            this.disabled = false;
        }
    }

    /**
     * Get Live consumption values
     */
    private getLiveConsumption() {

        const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx);

        const offset = zoom[0].offset * (this.position);
        const limit = offset - zoom[0].offset;
        const interval = zoom[0].interval;

        if (!this.isEDGUser) {
            this.liveData.setLiveValues(offset, limit, interval, 0);
        } else {
            if (this._userService.isEnviamUser()) {
                this.liveData.setLiveValues(offset, limit, interval, 0);
            } else {
                this.electricity.setCurrentFilter(offset, limit, interval, 0);
            }
        }

        if (this.userHasHappyHour) {
            this.getHappyHourSchedule(offset);
        }
    }

    /**
     * Determine the current consumption value based on the last element of the current data set
     * @param dataset
     * @private
     */
    private determineCurrentConsumption(value): void {
        const diff = value - Math.floor(value);
        if (diff < 0.5) {
            this.current_consumption = Math.floor(value).toLocaleString('de-DE');
        } else {
            this.current_consumption = Math.ceil(value).toLocaleString('de-DE');
        }
    }

    /**
     * Handle Homestate Status response
     * @param data
     */
    private handleHomestateStatusResponse(data): void {
        if (data.current_zone === 4) {
            this.status.noZone = true;
            return;
        }
        this.determineStatus(data);
    }


    /**
     * Determine the current consumption status
     * @param data
     */
    private determineStatus(data) {
        this.status.noZone = false;
        this.status.trend = data.current_zone;
        this.status.since = determineDurationPassed(data.since);
    }

    /**
     * Request the consumption power data for a specific date
     */
    private requestConsumptionForDate() {
        const zoom = this.zoomLevels.filter(
            (item: any) => item.level === this.currentZoomLevelIdx
        )[0];
        // position needs to be negated to match the desired movement direction
        this.lastStartDate = moment(this.baseDate).add(-this.position * zoom.offset, 'minutes');
        const to = moment(this.lastStartDate).add(zoom.offset, 'minutes');

        const liveDataSub = this.liveData.getLiveDataAlt(
            this.lastStartDate,
            to.toDate(),
            zoom.interval,
            false
        ).subscribe(
            (res) => {
                if (res) {
                    this.handleLiveDataResponse(res);
                }
                liveDataSub.unsubscribe();
            },
            (error) => {
                console.log('error here', error);
                this.liveChart.removeSeries(0, 2);
                this.liveChart.showLoadingState();
            },
        );

        return;
        // const timeframe = this.currentZoomLevelIdx === 1 ? 'hours' : 'days';
        // const consumptionSub = this.electricity.getConsumptionCustom(timeframe, this.lastStartDate.toDate(), to.toDate()).subscribe(
        //     (res) => {
        //         // const from = moment().subtract(this.position * zoom.offset, 'minutes');
        //         // const to = moment().subtract((this.position * zoom.offset) - zoom.offset, 'minutes');
        //         const filtered = res.filter(el => {
        //             const ts = moment(el.timestamp);
        //             return ts >= this.lastStartDate && ts <= to;
        //         });
        //
        //         if (filtered.length > 0) {
        //             this.liveChart.addNewSeries(filtered, 'measured', 1, 0);
        //             this.liveChart.showLoadingState(false);
        //             this.disabled = false;
        //         } else {
        //             this.disabled = true;
        //             this.liveChart.showLoadingState();
        //         }
        //
        //         consumptionSub.unsubscribe();
        //     }
        // );

    }

    /*
    * --- HAPPYHOUR ------------------------------------------------------------------------------------------------------
    */

    /**
     * Initializes the happy hour
     * @private
     */
    private initHappyHour(): void {
        if (!this._userService.isEnviamUser()) {
            return;
        }
        const s = this.onUserHasHappyHour.subscribe(
            (value) => {
                if (value) {
                    this.userHasHappyHour = value;
                    this.getLiveConsumption();
                }
                s.unsubscribe();
            }
        );
    }

    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        this.happyHour.getParticipation().subscribe(
            // this._apiService.userParticipatesInHappyHour().subscribe(
            (res) => {

                if (res) {
                    const now = moment();

                    const parsed_response_data = res as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter((element) => now >= moment(element.from_date, 'DD/MM/YYYY'));
                    const sorted = filtered.sort((a, b) => {
                        const a_from = moment(a.from_date, 'DD/MM/YYYY');
                        const b_from = moment(b.from_date, 'DD/MM/YYYY');
                        if (a_from.unix() > b_from.unix()) {
                            return -1;
                        } else if (a_from.unix() < b_from.unix()) {
                            return 1;
                        }
                        return 0;
                    });

                    if ('value' in sorted[0]) {
                        this.onUserHasHappyHour.next(sorted[0].value === 1);
                    }
                }

            },
            (error) => {
            }
        );
    }

    /**
     * Request the users happyhour schedule
     */
    private getHappyHourSchedule(offset: number): void {
        const date = moment().subtract(offset, 'minutes');
        // console.log('current offset date:', date.format('DD-MM-YYYY'));
        const s = this.happyHour.getSchedule(date.year(), date.month()).subscribe(
            (res) => {
                if (res) {
                    let today_schedule = null;
                    let yesterday_schedule = null;
                    const weekday = date.isoWeekday();
                    // console.log('weekday', weekday);
                    for (let i = 0; i < res.length; ++i) {
                        const element = res[i];
                        if (element.dayofweek === weekday) {
                            today_schedule = element;
                        }

                        if (element.dayofweek === 7) {
                            yesterday_schedule = res[0];
                        } else if (element.dayofweek === 1) {
                            yesterday_schedule = res[6];
                        } else {
                            yesterday_schedule = res[i - 1];
                        }

                    }

                    // update these values only if they were not set before
                    if (this.currentHappyHourStart === null && this.currentHappyHourEnd === null) {
                        this.currentHappyHourStart = date.hour(today_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                        this.currentHappyHourEnd = date.hour(today_schedule.endtime).minute(0).second(0).millisecond(0).toDate();
                    }
                    if (this.hhIndicator) {
                        this.hhIndicator.setStartTime(this.currentHappyHourStart, this.currentHappyHourEnd);
                    }

                    this.todayHappyHourStart = date.hour(today_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                    this.todayHappyHourEnd = date.hour(today_schedule.endtime).minute(0).second(0).millisecond(0).toDate();

                    const yesterday = moment(date).add(1, 'day');
                    this.yesterdayHappyHourStart = yesterday.hour(yesterday_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                    this.yesterdayHappyHourEnd = yesterday.hour(yesterday_schedule.endtime).minute(0).second(0).millisecond(0).toDate();

                    // this.liveChart.updateChart({plotOptions: {area: {zones: this.calculateNewZones()}}});
                    this.liveChart.addZones(this.calculateNewChartZones());
                }
                s.unsubscribe();
            }
        );
    }

    /**
     * Calculates new zones to be plotted based on happy hour schedule
     * @private
     */
    private calculateNewChartZones(): any {
        return [
            {color: 'rgba(0, 170, 225,1)'},
            {value: this.todayHappyHourStart.getTime()},
            {color: 'rgba(20, 60, 140,1)'},
            {value: this.todayHappyHourEnd.getTime()},
            {color: 'rgba(0, 170, 225, 1)'},
            {value: this.yesterdayHappyHourStart.getTime()},
            {color: 'rgba(20, 60, 140,1)'},
            {value: this.yesterdayHappyHourEnd.getTime()},
            {color: 'rgba(0, 170, 225,1)'},
            {value: this.currentHappyHourStart.getTime()},
            {color: 'rgba(20, 60, 140,1)'},
            {value: this.currentHappyHourEnd.getTime()},
            {color: 'rgba(0, 170, 225, 1)'},
        ];
    }

    /**
     * --- MOCK DATA -------------------------------------------------------------------------------
     */

    /**
     * Initialize demo mode mock data
     */
    private initializeMockData(): void {
        this.getMockConsumption();
        this.getMockHomestateStatus();

        this.mockSub = interval(10000).subscribe(
            (cycle) => {
                this.getMockConsumption();
                this.getMockHomestateStatus();
            }
        );

        const s = this._mockService.onCurrentConsumptionValue.subscribe(
            (value: { power: number, timestamp: number }) => {
                this.determineCurrentConsumption(value.power);
                s.unsubscribe();
            }
        );
    }

    /**
     *
     */
    private getMockConsumption() {
        const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx);

        const offset = zoom[0].offset * this.position;
        const limit = offset - zoom[0].offset;
        const interval = zoom[0].interval;
        const level = zoom[0].level;

        const s = this._mockService.getLiveData(offset, level, limit, interval).subscribe(
            (values: any) => {
                if (values.length > 0) {
                    this.liveChart.addNewSeries(
                        values,
                        'power',
                        1,
                        {zindex: 0}
                    );
                    this.liveChart.showLoadingState(false);
                    this.disabled = false;
                } else {
                    this.current_consumption = '0';
                    this.liveChart.showLoadingState();
                    this.disabled = false;
                }
            },
            () => {
                this.liveChart.showLoadingState();
                this.disabled = false;
            }
        );
        this.addSub(s);
    }


    /**
     * Get values for a mocked homestate status
     */
    private getMockHomestateStatus(): void {
        this._mockService.getHomeStateStatus().subscribe((data) => {
            this.determineStatus(data.data);
        });
    }

}
