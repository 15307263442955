import {AfterViewChecked, Component, ElementRef, Injector, OnInit, Renderer2, ViewChild} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {SmartBridgeService} from '../../services/smart-bridge.service';

@Component({
    selector: 'app-energy-saver-control',
    templateUrl: './energy-saver-control.component.html',
    styleUrls: ['./energy-saver-control.component.scss']
})
export class EnergySaverControlComponent implements OnInit {

    public currentThreshold = 0;

    constructor(private popoverRef: PopoverRef,
                private smartBridge: SmartBridgeService,
                private renderer: Renderer2) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.getThreshold();
    }

    onSliderChange(value): void {
        let mapped = 0;
        switch (value) {
            case 0:
                mapped = 25;
                break;
            case 1:
                mapped = 50;
                break;
            case 2:
                mapped = 150;
                break;
        }
        this.setNewThreshold(mapped);
    }


    close(value = false): void {
        if (this.popoverRef) {
            this.popoverRef.close(value);
        }
    }

    private getThreshold(): void {
        this.smartBridge.getBatterySaverThreshold().subscribe((res) => {
            switch (res) {
                case 1:
                    this.currentThreshold = 0;
                    break;
                case 50:
                    this.currentThreshold = 1;
                    break;
                case 150:
                    this.currentThreshold = 2;
                    break;
                default:
                    this.setNewThreshold(100);
                    this.currentThreshold = 1;
            }
        });
    }

    private setNewThreshold(value): void {
        this.smartBridge.setBatterySaverThreshold(value).subscribe(
            (res) => {
            },
            (error) => {
                console.log('Error', error);
            }
        );
    }

    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
        this.currentThreshold = this.popoverRef.data.threshold;
    }

}
