import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

    showBanner = false;
    currentBanner: BannerDef = {main: '', sub: ''};

    constructor(private router: Router) {
    }

    show(banner: BannerDef): void {
        this.showBanner = true;
        this.currentBanner = banner;
    }

    hide(): void {
        this.showBanner = false;
    }

    onClick(): void {
        if (this.currentBanner.route || this.currentBanner.route.length > 0) {
            this.router.navigate(this.currentBanner.route);
        }
    }
}

export interface BannerDef {
    main: string;
    sub: string;
    route?: string[];
}
