import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {ApiService} from '../../../services/api.service';
import {MvpDetailConfig, MvpTileConfig} from '../../../services/mvp.service';
import {HttpClient} from '@angular/common/http';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';

@Component({
    selector: 'app-mvp-tile',
    templateUrl: './mvp-tile.component.html',
    styleUrls: ['./mvp-tile.component.scss'],
    providers: [Globals]
})
export class MvpTileComponent implements OnInit, OnChanges {
    private readonly type: TILE_TYPE = TILE_TYPE.MVP;

    @Input() config: MvpTileConfig = null;
    @Input() detailConfig: MvpDetailConfig = null;

    mvpResError = false;

    constructor(private _globals: Globals,
                private _apiService: ApiService,
                private http: HttpClient,
                private analytics: TrackAnalyticsService,
                private tiles: TileService) {
    }

    ngOnInit() {
        this.initialize();
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type, {config: this.detailConfig});
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.config) {
            this.initialize();
        }
    }

    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private initialize(): void {
        if (!this.config) {
            this.mvpResError = true;
        } else {
            if (!this.config.imageUrl || this.config.imageUrl === '') {
                this.mvpResError = true;
            }
        }
        this.mvpResError = false;

        const s = this.http.get(this.config.imageUrl, {responseType: 'blob'}).subscribe(
            (res) => {
                s.unsubscribe();
            },
            (error) => {
                this.mvpResError = true;
                s.unsubscribe();
            }
        );
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: MVP',
                user_group: this.config.userGroup
            }
        });
    }

    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: MVP-Details'
            }
        });
    }

}
