import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {MockDataService} from '../../../services/mock-data.service';
import {months} from 'moment';
import {getWeek, MONTHS} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {HappyHourService} from '../../../services/happy-hour.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import * as moment from 'moment';
import {catchError, flatMap, map, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {of, Subject, throwError} from 'rxjs';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Tariff} from '../../../classes/user';
import {UserService} from '../../../services/user.service';
import {ProfileService} from '../../../services/profile.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-comparison-details',
    templateUrl: './comparison-details.component.html',
    styleUrls: ['comparison-details.component.scss'],
    viewProviders: []
})

export class ComparisonDetailsComponent extends BasePopover implements OnInit, AfterViewInit {
    MONTHS = MONTHS;

    year = 0;

    mode = 1;
    timeframe = 3;
    position = 1;

    compare: any = {
        left_day: 0,
        left_kw: 0,
        left_month: 0,
        left_year: 0,
        right_day: 0,
        right_kw: 0,
        right_month: 0,
        right_year: 0
    };

    limit = 6;

    disabled = true;

    chart: any = {};

    lastYearColor = '#d27300';
    thisYearColor = '#f59b00';
    positionFinal = false;

    infoVisible = false;

    seriesLegend = [];

    // happyhour
    userHasHappyHour = false;
    private onUserHasHappyHour = new Subject<boolean>();
    private onDataModeChange = new Subject<string>();
    showConsumptionFor: 'happyhour' | 'all' = 'all';

    isErnaUser = false;
    displayMode: 'consumption' | 'cost' = 'consumption';
    tariffs: Tariff[] = null;
    tariffsAvailable = false;
    warningCollapsed = true;

    private contract = {workingprice: 0, basicprice: 0};

    constructor(private analytics: TrackAnalyticsService,
                public _mockData: MockDataService,
                private electricityService: ElectricityService,
                private happyHour: HappyHourService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef,
                private userService: UserService,
                private profile: ProfileService,
                private router: Router) {
        super(popoverRef);
    }

    ngOnInit() {
        this.constructComparisonDates();
        const s = this.onUserHasHappyHour.subscribe(
            (val) => this.userHasHappyHour = val,
            (error) => null,
            () => s.unsubscribe()
        );

        const s2 = this.onDataModeChange.subscribe(
            (value: 'all' | 'happyhour') => {
                this.showConsumptionFor = value;
                this.constructComparisonDates();
                this.resetChart();
            }
        );
        this.addSub(s2);

        this.addSub(this.profile.getContract().subscribe(
            (res: any) => {
                if ('profile' in res) {
                    this.processProfileData(res);
                }
            }
        ));

        this.initializeStaticChart();

        this.isErnaUser = this.userService.isEDGUser();
        if (this.isErnaUser) {
            this.tariffs = this.userService.getTariffInfo();
            if (this.tariffs) {
                this.tariffsAvailable = this.tariffs.length > 0;
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.application.isDemoMode()) {
            this.getMockStatic();
            return;
        }
        if (this.userService.isEnviamUser()) {
            this.determineUserHasHappyHour();
        }
    }

    constructComparisonDates() {
        const dateRight = new Date();
        const dateLeft = new Date();

        this.year = dateRight.getFullYear();

        switch (this.timeframe) {
            case 2: { // Woche
                dateLeft.setDate(dateRight.getDate() - 7);
                break;
            }
            case 3: { // Monat
                dateLeft.setMonth(dateRight.getMonth() - 1);
                break;
            }
            case 4: { // Jahr
                dateLeft.setFullYear(dateRight.getFullYear() - 1);
                break;
            }
            default: { // Tag
                dateLeft.setDate(dateRight.getDate() - 1);
            }
        }

        this.compare = {
            left_day: dateLeft.getDate(),
            left_kw: getWeek(dateLeft),
            left_month: dateLeft.getMonth() + 1,
            left_year: dateLeft.getFullYear(),
            right_day: dateRight.getDate(),
            right_kw: getWeek(dateRight),
            right_month: dateRight.getMonth() + 1,
            right_year: dateRight.getFullYear()
        };
    }

    /**
     * Daten für Chart "Statisch"
     */
    getStatic() {
        const years = [
            {year: this.year - 1, color: '#D27300'},
            {year: this.year, color: '#F59B00'}
        ];

        for (const year of years) {
            const dates = this.determineStaticDatesByTimeframe(year);
            const s = this.electricityService.getConsumptionForTimeframe(dates.from, dates.to, dates.timeframe).pipe(
                flatMap((res) => {
                    if (!res) {
                        return throwError(null);
                    }
                    if (res.length === 0) {
                        return throwError(null);
                    }
                    return of(res);
                }),
                map((res: any) => {
                    let categories = [];
                    const series = {name: null, data: [], color: '#ffffff', originalData: res};

                    switch (this.timeframe) {
                        case 2: {
                            console.log('in case 2');
                            const consumptions = [];
                            for (const con of res) {
                                const kw = getWeek(new Date(con.timestamp));
                                const index = consumptions.map((el) => el.kw).indexOf(kw);
                                let multiplier = 1;

                                if (this.displayMode === 'cost') {
                                    multiplier = this.getTariffMultiplier(con.timestamp);
                                }

                                if (index >= 0) {
                                    consumptions[index].wh +=
                                        'measured' in con ? con.measured * multiplier : 0;
                                } else {
                                    consumptions.push({
                                        kw,
                                        ts: con.timestamp,
                                        wh: 'measured' in con ? con.measured * multiplier : 0
                                    });
                                }
                            }
                            for (const c of consumptions) {
                                categories.push('KW ' + c.kw);
                            }

                            const mapped = consumptions.map(
                                (c) => Number(((c.wh) / 1000).toFixed(2))
                            );

                            series.name = new Date(res[res.length - 1].timestamp)
                                .getFullYear().toString();
                            console.log('setting series name', series.name);
                            series.data = mapped;
                            series.color = year.color;

                            break;
                        }
                        case 4: {
                            console.log('in case 4');
                            let consumption = 0;
                            for (const con of res) {
                                let multiplier = 1;
                                if (this.displayMode === 'cost') {
                                    multiplier = this.getTariffMultiplier(con.timestamp);
                                }
                                consumption += 'measured' in con ? con.measured * multiplier : 0;
                            }

                            const mapped = [Number(((consumption) / 1000).toFixed(2))];
                            categories = ['', ''];

                            series.name = year.year;
                            console.log('setting series name', series.name);
                            series.data = mapped;
                            series.color = year.color;
                            break;
                        }
                        default: {
                            console.log('in default case');
                            for (const con of res) {
                                const date = new Date(con.timestamp);
                                switch (this.timeframe) {
                                    case 3: {
                                        categories.push(this.MONTHS[date.getMonth()]);
                                        break;
                                    }
                                    default: {
                                        categories.push(date.getDate() + '. ' +
                                            this.MONTHS[date.getMonth()]);
                                    }
                                }
                            }

                            const mapped = res.map((consumption) => {
                                    let multiplier = 1;
                                    if (this.displayMode === 'cost') {
                                        multiplier = this.getTariffMultiplier(consumption.timestamp);
                                    }

                                    const value = multiplier *
                                        ('measured' in consumption ? consumption.measured : 0);

                                    return Number(((value / 1000)).toFixed(2));
                                }
                            );

                            series.name = new Date(res[res.length - 1].timestamp)
                                .getFullYear().toString();
                            console.log('setting series name', series.name);
                            series.data = mapped;
                            series.color = year.color;
                        }

                    }

                    this.disabled = false;
                    return {categories, series};
                }),
                catchError((error) => {
                    const chart: any = $('#chart-compare-details').highcharts();
                    if (chart) {
                        chart!.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                        this.disabled = false;
                    }
                    return of(false);
                })
            ).subscribe(
                (result: any) => {
                    if (!result) {
                        return;
                    }
                    console.log('adding series', result.series);
                    this.chart.addSeries(result.series);
                    // const chart: any = $('#chart-compare-details').highcharts();
                    this.chart.ref.update({xAxis: {categories: result.categories}});

                    s.unsubscribe();
                },
            );

        }

    }

    getMockStatic(): void {
        for (let year of [{year: this.year - 1, color: this.lastYearColor}, {
            year: this.year,
            color: this.thisYearColor
        }]) {

            const req_params = this.determineStaticComparisonApiParams(year);
            const from = req_params.from;
            const to = req_params.to;
            const api = req_params.api;

            const s = this._mockData.getConsumptionByDate(api, from, to).subscribe(
                (data: any) => {
                    if ('data' in data) {
                        if (data.data.length > 0) {
                            let categories: any = [];

                            switch (this.timeframe) {
                                case 2: {
                                    const consumption = [];
                                    for (const con of data.data) {
                                        const kw = getWeek(new Date(con.timestamp));
                                        const index = consumption.map(c => c.kw).indexOf(kw);
                                        if (index > -1) {
                                            if ('measured' in con) {
                                                consumption[index].wh += con.measured;
                                            }
                                        } else {
                                            const wh = 'measured' in con ? con.measured : 0;
                                            consumption.push({kw, wh});
                                        }
                                    }
                                    for (const con of consumption) {
                                        categories.push('KW ' + con.kw);
                                    }

                                    const series = {
                                        name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                        data:
                                            consumption.map((con: any) => {
                                                return Number((con.wh / 1000).toFixed(2));
                                            }),
                                        color: year.color
                                    };
                                    this.chart.addSeries(series);
                                    break;
                                }
                                case 4: {
                                    let consumption = 0;
                                    for (const con of data.data) {
                                        if ('measured' in con) {
                                            consumption += con.measured;
                                        }
                                    }
                                    categories = ['', ''];
                                    this.chart.addSeries({
                                        name: year.year,
                                        data: [Number((consumption / 1000).toFixed(2))],
                                        color: year.color
                                    });
                                    break;
                                }
                                default: {
                                    for (const con of data.data) {
                                        const date = new Date(con.timestamp);
                                        switch (this.timeframe) {
                                            case 3: {
                                                categories.push(this.MONTHS[date.getMonth()]);
                                                break;
                                            }
                                            default: {
                                                categories.push(date.getDate() + '. ' + this.MONTHS[date.getMonth()]);
                                            }
                                        }
                                    }
                                    this.chart.addSeries({
                                        name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                        data:
                                            data.data.map((con: any) => {
                                                return Number((con.measured / 1000).toFixed(2));
                                            }),
                                        color: year.color
                                    });
                                }
                            }
                            this.chart.ref.update({xAxis: {categories}});
                        }

                        // if (this.chart.ref) {
                        this.chart.ref.hideLoading();
                        // }

                        this.disabled = false;
                    }
                },
                () => {
                    if (this.chart.ref) {
                        this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    }
                    this.disabled = false;
                }
            );
            this.addSub(s);
        }
    }

    getDynamic() {
        let left_from = new Date(0);
        let left_to = new Date(0);

        let right_from = new Date(0);
        let right_to = new Date(0);

        let api = null;

        switch (this.timeframe) {
            case 2: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setDate(left_from.getDate() + (this.compare.left_kw * 7) - 7);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setDate(left_to.getDate() + (this.compare.left_kw * 7) - 1);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setDate(right_from.getDate() + (this.compare.right_kw * 7) - 7);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setDate(right_to.getDate() + (this.compare.right_kw * 7) - 1);

                api = 'days';

                break;
            }
            case 3: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setFullYear(this.compare.left_year);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setFullYear(this.compare.right_year);

                right_to = right_from;

                api = 'months';

                break;
            }
            case 4: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);

                api = 'months';

                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setDate(this.compare.left_day);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setDate(this.compare.right_day);

                right_to = right_from;

                api = 'days';
            }
        }

        let left_wh = 0;
        let right_wh = 0;

        const left$ = this.electricityService.getConsumptionForTimeframe(left_from, left_to, api).pipe(
            catchError((error) => of(null))
        );
        const right$ = this.electricityService.getConsumptionForTimeframe(right_from, right_to, api).pipe(
            catchError((error) => of(null))
        );

        const sub = forkJoin({left: left$, right: right$}).pipe(
            map((res: any) => {
                const consumptions = {
                    left: {value: 0, timestamp: null},
                    right: {value: 0, timestamp: null}
                };
                for (const key of Object.keys(res)) {
                    if (res[key]) {
                        for (const consumption of res[key]) {
                            let multiplier = 1;
                            if (this.displayMode === 'cost') {
                                multiplier = this.getTariffMultiplier(consumption.timestamp);
                            }
                            const value = 'measured' in consumption ? consumption.measured : 0;
                            consumptions[key].timestamp = consumption.timestamp;
                            consumptions[key].value += value * multiplier;
                        }
                    }
                }
                console.log('consumptions', consumptions);
                return consumptions;
            })
        ).subscribe(
            (results) => {
                if (results) {
                    let left_name = '';
                    let right_name = '';
                    const ldate = moment(results.left.timestamp);
                    const rdate = moment(results.right.timestamp);
                    switch (this.timeframe) {
                        case 1: {
                            left_name = ldate.format('DD.MM.YYYY');
                            right_name = rdate.format('DD.MM.YYYY');
                            break;
                        }
                        case 2: {
                            left_name = `KW ${ldate.week()} ${ldate.year()}`;
                            right_name = `KW ${rdate.week()} ${rdate.year()}`;
                            break;
                        }
                        case 3: {
                            left_name = `${ldate.locale('de-DE').format('MMMM YYYY')}`;
                            right_name = `${rdate.locale('de-DE').format('MMMM YYYY')}`;
                            break;
                        }
                        case 4: {
                            left_name = ldate.year().toString();
                            right_name = rdate.year().toString();
                            break;
                        }
                    }
                    this.chart.addSeries({
                        name: null,
                        data: [
                            {
                                name: left_name,
                                y: Number((results.left.value / 1000).toFixed(2)),
                                color: this.lastYearColor
                            },
                            {
                                name: right_name,
                                y: Number((results.right.value / 1000).toFixed(2)),
                                color: this.thisYearColor
                            }
                        ]
                    });


                    if (this.chart.ref) {
                        this.chart.ref.hideLoading();
                    }

                    this.disabled = false;
                } else {
                    if (this.chart.ref) {
                        this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    }
                    this.disabled = false;
                }
                sub.unsubscribe();
            }
        );
    }

    getMockDynamic(): void {
        const times = this.determineDynamicComparisonApiParams();
        const left_from = times.left_from;
        const left_to = times.left_to;
        const right_from = times.right_from;
        const right_to = times.right_to;
        const api: string = times.api;

        let left_wh = 0;
        let right_wh = 0;

        const s1 = this._mockData.getConsumptionByDate(api, left_from, left_to).subscribe(
            (data: any) => {
                if ('data' in data) {
                    for (const con of data.data) {
                        left_wh += 'measured' in con ? con.measured : 0;
                    }

                    const s2 = this._mockData.getConsumptionByDate(api, right_from, right_to).subscribe(
                        (data2: any) => {
                            if ('data' in data2) {
                                for (const con of data2.data) {
                                    if ('measured' in con) {
                                        right_wh += con.measured;
                                    }
                                }

                                let left_name = '';
                                let right_name = '';
                                const ldate = moment(data.data[0].timestamp);
                                const rdate = moment(data2.data[0].timestamp);
                                switch (this.timeframe) {
                                    case 1: {
                                        left_name = ldate.format('DD.MM.YYYY');
                                        right_name = rdate.format('DD.MM.YYYY');
                                        break;
                                    }
                                    case 2: {
                                        left_name = `KW ${ldate.week()} ${ldate.year()}`;
                                        right_name = `KW ${rdate.week()} ${rdate.year()}`;
                                        break;
                                    }
                                    case 3: {
                                        left_name = `${ldate.locale('de-DE').format('MMMM YYYY')}`;
                                        right_name = `${rdate.locale('de-DE').format('MMMM YYYY')}`;
                                        break;
                                    }
                                    case 4: {
                                        left_name = ldate.year().toString();
                                        right_name = rdate.year().toString();
                                        break;
                                    }
                                }
                                this.chart.addSeries({
                                    name: null,
                                    data: [
                                        {
                                            name: left_name,
                                            y: Number((left_wh / 1000).toFixed(2)),
                                            color: this.lastYearColor
                                        },
                                        {
                                            name: right_name,
                                            y: Number((right_wh / 1000).toFixed(2)),
                                            color: this.thisYearColor
                                        },
                                    ]
                                });

                                this.chart.ref.update({});

                                if (this.chart.ref) {
                                    this.chart.ref.hideLoading();
                                }

                                this.disabled = false;
                            }
                        },
                        () => {
                            if (this.chart.ref) {
                                this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                            }

                            this.disabled = false;
                        }
                    );
                    this.addSub(s2);
                }
            },
            () => {
                if (this.chart.ref) {
                    this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                }

                this.disabled = false;
            }
        );
        this.addSub(s1);
    }


    getHappyHourStatic(): void {
        let api_mode: 'day' | 'week' | 'month' | 'year';
        let left_start;
        let left_end;
        let right_start;
        let right_end;

        const day_format = 'YYYY-MM-DD';
        const month_format = 'YYYY-MM';

        this.positionFinal = false;
        // determine start and end days
        switch (this.timeframe) {
            case 1:
                api_mode = 'day';
                left_start = moment().subtract((this.position * this.limit) - 1, 'days').format(day_format);
                left_end = moment().subtract((this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format).subtract(1, 'year').format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format).subtract(1, 'year').format(day_format);
                break;
            case 2:
                // using day api for weeks
                api_mode = 'week';
                left_start = moment().subtract((7 * this.position * this.limit) - 7, 'days').format(day_format);
                left_end = moment().subtract(7 * (this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format).subtract(1, 'year').format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format).subtract(1, 'year').format(day_format);
                break;
            case 3:
                api_mode = 'month';
                left_start = moment().subtract((this.position * this.limit) - 1, 'months').format(month_format);
                left_end = moment().subtract((this.position - 1) * this.limit, 'months').format(month_format);
                right_start = moment(left_start, month_format).subtract(1, 'year').format(month_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(month_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, month_format).subtract(1, 'year').format(month_format);

                break;
            case 4:
                api_mode = 'year';
                left_start = moment().year();
                left_end = moment().year();
                right_start = moment().subtract(this.position, 'year').year();
                right_end = right_start;
                break;
            default:
                console.log('Unknown case');
                break;
        }

        // console.log(`left from ${left_start} to ${left_end}`);
        // console.log(`right from ${right_start} to ${right_end}`);

        const chart: any = $('#chart-compare-details').highcharts();

        // const this_year_request = this._apiService.getHappyHourConsumptionFor(api_mode, left_start, left_end);
        // const last_year_request = this._apiService.getHappyHourConsumptionFor(api_mode, right_start, right_end);

        const this_year_request = this.happyHour.getConsumptionFor(api_mode, left_start, left_end);
        const last_year_request = this.happyHour.getConsumptionFor(api_mode, right_start, right_end);

        const s = forkJoin([this_year_request, last_year_request]).subscribe(
            (responses) => {
                const this_year_data = responses[0];
                const last_year_data = responses[1];

                if (last_year_data) {
                    this.chart.addSerie(
                        {
                            name: new Date(last_year_data[last_year_data.length - 1].timestamp).getFullYear().toString(),
                            data:
                                last_year_data.map((con: any) => {
                                    return Number((con.measured / 1000).toFixed(2));
                                }),
                            color: this.lastYearColor
                        }
                    );
                }

                if (this_year_data) {
                    let categories = [];
                    if (api_mode === 'day') {
                        for (const consumption of this_year_data) {
                            const date = moment(consumption.timestamp).toDate();
                            const date_string = `${date.getDate()}. ${this.MONTHS[date.getMonth()]}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'week') {
                        for (const consumption of this_year_data) {
                            const date_string = `KW ${moment(consumption.month).week()}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'month') {
                        for (const consumption of this_year_data) {
                            const date = moment(consumption.timestamp).toDate();
                            const date_string = `${this.MONTHS[date.getMonth()]}`;
                            categories.push(date_string);
                        }
                    } else if (api_mode === 'year') {
                        categories = ['', ''];
                    }

                    this.chart.addSerie(
                        {
                            name: new Date(this_year_data[this_year_data.length - 1].timestamp).getFullYear().toString(),
                            data:
                                this_year_data.map((con: any) => {
                                    return Number((con.measured / 1000).toFixed(2));
                                }),
                            color: this.thisYearColor
                        }
                    );

                    if (api_mode !== 'year') {
                        this.chart.options.xAxis.categories = categories;
                        this.chart.ref.update({xAxis: {categories: categories}});
                    }

                }


                this.disabled = false;
                chart.hideLoading();
                s.unsubscribe();
            }
        );
    }

    getHappyHourDynamic(): void {
        let api_mode: 'day' | 'week' | 'month' | 'year';
        let right_start;
        let right_end;
        let left_start;
        let left_end;

        const day_format = 'YYYY-MM-DD';
        const month_format = 'YYYY-MM';

        const date_str_right = `${this.compare.right_year}-${this.compare.right_month}-${this.compare.right_day}`;
        const date_str_left = `${this.compare.left_year}-${this.compare.left_month}-${this.compare.left_day}`;

        switch (this.timeframe) {
            case 1:
                api_mode = 'day';
                right_start = moment(date_str_right, day_format).format(day_format);
                left_start = moment(date_str_left, day_format).format(day_format);
                right_end = right_start;
                left_end = left_start;
                break;
            case 2:
                api_mode = 'week';
                moment.locale('de');
                right_start = moment().year(this.compare.right_year).week(this.compare.right_kw).startOf('week').format(day_format);
                right_end = moment(right_start, day_format).endOf('week').format(day_format);
                left_start = moment().year(this.compare.left_year).week(this.compare.left_kw).format(day_format);
                left_end = moment(left_start, day_format).endOf('week').format(day_format);

                break;
            case 3:
                api_mode = 'month';
                right_start = moment(date_str_right, day_format).format(month_format);
                right_end = right_start;

                left_start = moment(date_str_left, day_format).format(month_format);
                left_end = left_start;
                break;
            case 4:
                right_start = moment(date_str_right, day_format).year();
                right_end = right_start;
                left_start = moment(date_str_left, day_format).year();
                left_end = left_start;
                api_mode = 'year';
                break;
            default:
                console.log('Unknown case');
        }

        const chart: any = $('#chart-compare-details').highcharts();

        const right_request = this.happyHour.getConsumptionFor(api_mode, right_start, right_end);
        const left_request = this.happyHour.getConsumptionFor(api_mode, left_start, left_end);

        const s = forkJoin([right_request, left_request]).subscribe(
            (responses) => {
                const right_data = responses[0];
                const left_data = responses[1];

                if (left_data && right_data) {
                    this.chart.addSerie(
                        {
                            name: null,
                            data: [
                                {
                                    name: null,
                                    y: Number((left_data[0].measured / 1000).toFixed(2)),
                                    color: this.lastYearColor
                                },
                                {
                                    name: null,
                                    y: Number((right_data[0].measured / 1000).toFixed(2)),
                                    color: this.thisYearColor
                                }
                            ]
                        }
                    );
                }

                this.disabled = false;
                chart.hideLoading();
                s.unsubscribe();
            }
        );
        this.disabled = false;
    }

    setMode(mode: number) {
        if (mode !== this.mode) {
            this.mode = mode;

            this.resetChart();

            this.analytics.trackEvent({
                action: 'screen_view',
                properties: {
                    category: 'Screens',
                    label: 'screen: Vergleich - ' + (this.mode === 2 ? 'Anpassbar' : 'Statisch') +
                        '; previous_screen: Vergleich - ' + (this.mode === 2 ? 'Statisch' : 'Anpassbar')
                }
            });
        }
    }

    setTimeframe(timeframe: string) {
        if ((parseInt(timeframe, 10) !== this.timeframe) && (!this.disabled)) {
            this.timeframe = parseInt(timeframe, 10);
            this.position = 1;
            this.constructComparisonDates();
            this.resetChart();
        }
    }

    setCompare(key, value) {
        this.compare[key] = parseInt(value, 10);
        this.resetChart();
    }

    positionForward() {
        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }
    }

    positionBack() {
        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
    }

    positionNow() {
        if (!this.disabled) {
            this.position = 1;
            this.resetChart();
        }
    }

    resetChart() {
        if (this.chart.ref) {
            this.chart.ref.showLoading();
        }

        this.disabled = true;
        if (this.mode === 2) {
            this.initializeDynamicChart();
        } else {
            this.initializeStaticChart();
        }
    }

    setValueMode(mode: 'consumption' | 'cost') {
        this.displayMode = mode;
        this.resetChart();
        switch (this.displayMode) {
            case 'consumption':
                break;
            case 'cost':
                break;
            default:
                console.log('default triggered for some weird reason');
        }
    }

    loop(start: number, times: number) {
        const loop = [];
        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    }


    toggleWarning(): void {
        this.warningCollapsed = !this.warningCollapsed;
    }

    routeToProfile(): void {
        this.close(false);
        this.router.navigate(['meine-daten']);
    }

    initializeStaticChart() {
        this.seriesLegend = [];
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                marginLeft: 80,
                marginBottom: 30,
                marginTop: 50,
                events: {
                    addSeries(evt) {
                        self.seriesLegend.push({name: evt.options.name, color: evt.options.color});
                    },
                    load(load_evt) {
                        if (self.application.isDemoMode()) {
                            self.getMockStatic();
                            return;
                        }
                        if (self.showConsumptionFor === 'all') {
                            self.getStatic();
                        } else {
                            self.getHappyHourStatic();
                        }
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                // type: 'categories',
                categories: [],
                labels: {
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '18px'
                    }
                },
            },
            yAxis: {
                title: {
                    text: self.displayMode === 'cost' ? '€' : 'Watt',
                    align: 'high',
                    textAlign: 'right',
                    x: 36,
                    y: -30,
                    rotation: 0,
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px',
                    }
                },
                labels: {
                    align: 'right',
                    x: -8,
                    y: 5,
                    step: 2,
                    formatter() {
                        if (this.value >= 1) {
                            const unit = self.displayMode === 'cost' ? '€' : 'kWh';
                            return `${this.value.toLocaleString('de-DE')} ${unit}`;
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px'
                    }

                }
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const idx = this.point.index;
                    const originalData = this.series.userOptions['originalData'].reverse();
                    const year = moment(originalData[idx].timestamp).year();
                    const style = `style="border-color: ${this.color}"`;
                    const name = `<div class="header">${this.key} ${year} </div>`;
                    const unit = self.displayMode === 'cost' ? '€' : 'kWh';
                    const value = `<div class="body"> ${this.y.toLocaleString('de-DE').split(',')[0]} ${unit}</div>`;
                    return `<div class="column-callout" ${style}> ${name} ${value}</div>`;
                },
                borderColor: 'white',
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: 'transparent',
                shadow: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            legend: {enabled: false},
            series: [],
            credits: {
                enabled: false
            }
        });
    }

    initializeDynamicChart() {
        this.seriesLegend = [];
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                marginLeft: 80,
                marginBottom: 30,
                marginTop: 50,
                events: {
                    addSeries(evt) {
                        self.seriesLegend.push({name: evt.options.name, color: evt.options.color});
                    },
                    load(load_evt) {
                        if (self.application.isDemoMode()) {
                            self.getMockDynamic();
                            return;
                        }
                        if (self.showConsumptionFor === 'all') {
                            self.getDynamic();
                        } else {
                            self.getHappyHourDynamic();
                        }
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                title: {text: null},
                labels: {
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '18px',
                        color: 'transparent',
                    }
                },
            },
            yAxis: {
                title: {
                    text: self.displayMode === 'cost' ? '€' : 'Watt',
                    align: 'high',
                    textAlign: 'right',
                    x: 36,
                    y: -30,
                    rotation: 0,
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px',
                    }
                },
                labels: {
                    align: 'right',
                    x: -8,
                    y: 5,
                    step: 2,
                    formatter() {
                        if (this.value >= 1) {
                            const unit = self.displayMode === 'cost' ? '€' : 'kWh';
                            return `${this.value.toLocaleString('de-DE')} ${unit}`;
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontFamily: 'Innogy Bold, sans-serif',
                        fontSize: '16px'
                    }

                }
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const style = `style="border-color: ${this.color}"`;
                    console.log(this);
                    const name = `<div class="header">${this.key}</div>`;
                    const unit = self.displayMode === 'cost' ? '€' : 'kWh';
                    const value = `<div class="body"> ${this.y.toLocaleString('de-DE').split(',')[0]} ${unit}</div>`;
                    return `<div class="column-callout" ${style}> ${name} ${value}</div>`;
                },
                borderColor: 'white',
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: 'transparent',
                shadow: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 3,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false
                }
            },
            legend: {enabled: false},
            series: [],
            credits: {
                enabled: false
            }
        });
    }

    private determineStaticComparisonApiParams(year): any {
        let from: any = {};
        let to: any = {};
        let api: string = null;

        switch (this.timeframe) {
            case 2: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * (this.limit * 7)) + 7);
                from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * (this.limit * 7)) + (this.limit * 7));
                to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));

                api = 'days';

                break;
            }
            case 3: {
                from = new Date();
                from.setFullYear(year.year);
                from.setMonth(from.getMonth() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setMonth(to.getMonth() - (this.position * this.limit) + this.limit);

                api = 'months';

                break;
            }
            case 4: {
                from = new Date(0);
                from.setFullYear(year.year);
                from.setMonth(0);

                to = new Date(0);
                to.setFullYear(year.year);
                to.setMonth(11);
                to.setDate(31);

                api = 'months';

                break;
            }
            default: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * this.limit) + this.limit);

                api = 'days';
            }
        }

        return {from, to, api};
    }

    private determineDynamicComparisonApiParams(): any {

        let left_from: any = {};
        let left_to: any = {};

        let right_from: any = {};
        let right_to: any = {};

        let api: string = null;

        switch (this.timeframe) {
            case 2: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setDate(left_from.getDate() + (this.compare.left_kw * 7) - 7);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setDate(left_to.getDate() + (this.compare.left_kw * 7) - 1);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setDate(right_from.getDate() + (this.compare.right_kw * 7) - 7);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setDate(right_to.getDate() + (this.compare.right_kw * 7) - 1);

                api = 'days';

                break;
            }
            case 3: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setFullYear(this.compare.left_year);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setFullYear(this.compare.right_year);

                right_to = right_from;

                api = 'months';

                break;
            }
            case 4: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);

                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);

                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);

                api = 'months';

                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setDate(this.compare.left_day);

                left_to = left_from;

                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setDate(this.compare.right_day);

                right_to = right_from;

                api = 'days';
            }
        }

        return {left_from, left_to, right_from, right_to, api};
    }

    private determineStaticDatesByTimeframe(year): { from: Date, to: Date, timeframe: string } {
        let from: Date = null;
        let to: Date = null;
        let timeframe: string = null;

        switch (this.timeframe) {
            case 2: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * (this.limit * 7)) + 7);
                from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * (this.limit * 7)) + (this.limit * 7));
                to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));

                timeframe = 'days';

                break;
            }
            case 3: {
                from = new Date();
                from.setFullYear(year.year);
                from.setMonth(from.getMonth() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setMonth(to.getMonth() - (this.position * this.limit) + this.limit);

                timeframe = 'months';

                break;
            }
            case 4: {
                from = new Date(0);
                from.setFullYear(year.year);
                from.setMonth(0);

                to = new Date(0);
                to.setFullYear(year.year);
                to.setMonth(11);
                to.setDate(31);

                timeframe = 'months';

                break;
            }
            default: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * this.limit) + 1);

                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * this.limit) + this.limit);

                timeframe = 'days';
            }
        }

        return {from, to, timeframe};
    }

    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        this.happyHour.getParticipation().subscribe(
            // this._apiService.userParticipatesInHappyHour().subscribe(
            (res) => {
                if (res) {
                    const now = moment();

                    const parsed_response_data = res as Array<{ from_date: string, value: number }>;
                    // filter by elements with date earlier than today
                    const filtered = parsed_response_data.filter((element) => now >= moment(element.from_date, 'DD/MM/YYYY'));
                    const sorted = filtered.sort((a, b) => {
                        const a_from = moment(a.from_date, 'DD/MM/YYYY');
                        const b_from = moment(b.from_date, 'DD/MM/YYYY');
                        if (a_from.unix() > b_from.unix()) {
                            return -1;
                        } else if (a_from.unix() < b_from.unix()) {
                            return 1;
                        }
                        return 0;
                    });

                    if (sorted.length > 0) {
                        if ('value' in sorted[0]) {
                            this.onUserHasHappyHour.next(sorted[0].value === 1);
                        }
                    }
                }

            },
            (error) => {
            }
        );
    }

    public setDataMode(mode: string): void {
        this.onDataModeChange.next(mode);
    }

    private getTariffMultiplier(date): number {
        if (this.isErnaUser) {
            if (this.tariffs) {
                date = new Date(date);
                const tariff = this.tariffs.find((el) => {
                    const start = moment(el.dateStart).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
                    const end = el.dateEnd ? new Date(el.dateEnd) : new Date();
                    return date >= start && date <= end;
                });

                return tariff ? tariff.workPrice : this.contract.workingprice;
            }
        }

        return 1;
    }

    private processProfileData(data): void {
        let basicprice = 0;
        if (typeof data.profile.e_fixed_tariff !== 'undefined') {
            basicprice = parseFloat(data.profile.e_fixed_tariff);
        } else {
            basicprice = parseFloat(data.profile.budget_bill);
        }

        // Strompreise auslesen
        const workingprice = parseFloat(data.profile.e_tariff);
        this.contract.basicprice = basicprice;
        this.contract.workingprice = workingprice;
    }

}
