import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import * as moment from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-tariff-data',
    templateUrl: './tariff-data-entry.component.html',
    styleUrls: ['./tariff-data-entry.component.scss']
})
export class TariffDataEntryComponent implements OnInit {

    moment = moment;
    data = {name: null, dateStart: null, basePrice: null, workPrice: null};
    confirmDisabled = true;
    minDate: string = null;
    editMode = false;
    displayDate = false;

    dateFormat = 'YYYY-MM-DD';
    dateFormatDisplay = 'DD.MM.YYYY';

    private tariffIndex = null;

    form = new FormGroup({
        name: new FormControl('', [Validators.required]),
        dateStart: new FormControl(null, [Validators.required]),
        basePrice: new FormControl(null, [Validators.required]),
        workPrice: new FormControl(null, [Validators.required]),
    });

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    onDateChange(value): void {
        const parsed = moment(value, this.dateFormat).utcOffset('+0000');
        this.data.dateStart = value;
        this.displayDate = parsed.isValid();
    }

    submit(mode: string): void {
        const value = this.form.value;
        if (!('dateStart' in value)) {
            value.dateStart = moment(this.data.dateStart)
                .hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        }

        if (mode === 'delete') {
            this.close({mode: 'delete', data: this.tariffIndex});
            return;
        }

        if (!this.editMode) {
            value.workPrice = value.workPrice / 100;
            this.close({mode: 'create', data: value});
            return;
        }

        value.workPrice = value.workPrice / 100;
        this.close({mode: 'update', data: value});
    }


    close(data: any): void {
        this.popoverRef.close(data);
    }

    createForm(data, dateDisabled = true): void {
        this.form = new FormGroup({
            name: new FormControl(data.name, [Validators.required]),
            dateStart: new FormControl(data.dateStart, [Validators.required]),
            basePrice: new FormControl(data.basePrice, [Validators.required]),
            workPrice: new FormControl(Math.round(data.workPrice * 100), [Validators.required]),
        });
        this.form.get('dateStart').disable({onlySelf: true});
        this.displayDate = true;
    }

    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
        const data = this.popoverRef.data;
        if (data.tariffs.length > 0) {
            const start = data.tariffs[data.tariffs.length - 1].dateStart;
            this.minDate = moment(start).add(1, 'day').format(this.dateFormat);
        } else {
            this.minDate = '2018-01-01';
        }

        if (data.edit_mode) {
            this.editMode = true;
            this.data = data.edit_data;
            this.tariffIndex = data.edit_idx;
            this.createForm(data.edit_data, true);
        }
    }
}
