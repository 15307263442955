import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-binary-confirm-popover',
    templateUrl: './binary-confirm-popover.component.html',
    styleUrls: ['./binary-confirm-popover.component.scss']
})
export class BinaryConfirmPopoverComponent implements OnInit {
    title = '';
    text = '';
    positive = '';
    negative = '';
    turquoise = false;

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            if (this.popoverRef.data.nullableBackdrop) {
                this.close(null);
            } else {
                this.close(false);
            }
        });
        this.title = this.popoverRef.data.title;
        this.text = this.popoverRef.data.text;
        this.positive = this.popoverRef.data.positive;
        this.turquoise = this.popoverRef.data.turquoise;
        if (this.popoverRef.data.negative) {
            this.negative = this.popoverRef.data.negative;
        }
    }

}
