import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';

import * as moment from 'moment';
import {SeriesColumnOptions, XAxisPlotLinesOptions, YAxisPlotLinesOptions} from 'highcharts';

@Component({
    selector: 'app-consumption-alert-chart',
    templateUrl: './consumption-alert-chart.component.html',
    styleUrls: ['./consumption-alert-chart.component.scss']
})
export class ConsumptionAlertChartComponent implements OnInit {

    @Input() isDetail = false;

    chart: Chart = null;

    constructor() {
    }

    ngOnInit() {
        this.initializeChart();
    }


    addNewSeries(values: any[], y_attribute: string, remove_series_count = 0, zindex = null): void {
        if (remove_series_count >= 0) {
            this.removeSeries(0, remove_series_count);
        }
        if ('results' in values) {
            return;
        }
        try {
            const s: SeriesColumnOptions = {
                name: null,
                zIndex: 1,
                data: values.map((element) => {
                    return [
                        new Date(element.timestamp).getTime(),
                        element[y_attribute]
                    ];
                }),
                type: 'column'
            };
            if (zindex) {
                s.zIndex = zindex;
            }
            this.chart.addSeries(s, true, false);
            const firstDate = new Date(values.first().timestamp).getTime();
            const lastDate = new Date(values.last().timestamp).getTime();
            const tickPositions = [firstDate, lastDate];
            this.chart.ref.update({xAxis: {tickPositions}}, true);
        } catch (exc) {
            this.showLoadingState(true);
            console.log('Exception: Malformed data.');
        }

    }

    removeSeries(idx: number, count = -1): void {
        if (count === -1) {
            this.chart.removeSeries(idx);
        } else {
            for (let i = idx; i < idx + count; ++i) {
                if (this.chart !== null && this.chart !== undefined) {
                    this.chart.removeSeries(i);
                }
            }
        }
    }

    reset(): void {
        this.chart.ref.showLoading('Auswertung wird geladen…');
    }

    showLoadingState(show: boolean = true): void {
        if (!this.chart) {
            return;
        }
        if (this.chart.ref) {
            if (show) {
                this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            } else {
                this.chart.ref.hideLoading();
            }
        }
    }

    updateMaxValueLine(value, state: boolean): void {
        this.chart.ref.update({yAxis: {plotLines: []}});
        const valFmt = value.toLocaleString('de-DE').split(',');
        // const color = state ? '#0097A7' : '#b00402';
        const color = '#b9280a';
        const valueStr = `${valFmt[0]} kWh`;
        const stateClass = state ? 'positive' : 'negative';
        const container = `<div class="consumption-alert-callout ${stateClass}">${valueStr}</div>`;
        const options: YAxisPlotLinesOptions = {
            color,
            width: 2,
            value,
            zIndex: 100,
            label: {
                align: 'left',
                useHTML: true,
                text: container,
            },

        };
        this.chart.ref.update({yAxis: {plotLines: [options], max: value * 1.01}}, true, true, true);
    }

    private initializeChart(): void {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [30, 0, 50, 40],
                reflow: true,
                events: {
                    load() {
                        this.showLoading('Auswertung wird geladen…');
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                tickAmount: 4,
                alignTicks: true,
                showLastLabel: true,
                showFirstLabel: true,
                labels: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy light, sans-serif',
                    },
                    formatter(): string {
                        const startoftoday = moment().startOf('day');
                        const parsed = moment(this.value);
                        const extends_to_yesterday = parsed.unix() < startoftoday.unix();
                        if (this.isFirst || this.isLast) {
                            if (extends_to_yesterday) {
                                return `${extends_to_yesterday ? 'Gestern' : 'Heute'} ${parsed.format('hh:mm')}`;
                            } else {
                                return `${parsed.format('hh:mm')}`;
                            }
                        }
                    },
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: 'Watt',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -20,
                    x: -8,
                    style: {
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                tickAmount: 4,
                labels: {
                    align: 'right',
                    x: -8,
                    y: 6,
                    step: 1,
                    formatter() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE');
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                gridZIndex: 10,
                gridLineColor: 'rgba(0,0,0,0.20)'
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0,
                    borderWidth: 0,
                    borderColor: '#ffffff',
                    color: '#B9280A',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false,
                    animation: false
                }
            },
            series: [
                {
                    data: null,
                    name: null,
                    type: 'column'
                },
            ],
            credits: {
                enabled: false
            }
        });
    }

}

