import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {catchError, flatMap, map} from 'rxjs/operators';
import {Observable, of, Subject, Subscription, timer} from 'rxjs';
import {constants} from '../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class OpticalReaderService extends BaseService {

    onMeterReaderStatus = new Subject<MeterReaderStatus>();

    // private updateRate = 10000;
    private updateRate = 2000;
    private timerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        super(http, auth, user);
    }

    destroy() {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            this.timerSub = null;
        }
    }

    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => {
                    return this.getOpticalReaderStatus();
                }
            ),
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe((res) => {
            if (res) {
                this.onMeterReaderStatus.next(res);
            }
        });
    }

    getOpticalReaderStatus(raw = false): Observable<MeterReaderStatus> {
        const url = this.API_BASE_URL + constants.api.routes.meterreader.status;
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map(res => this.mapDefault(res)),
            flatMap((data) => {
                if (raw) {
                    return of(data);
                }
                let status = null;
                try {
                    status = data.electricity.current_status;
                } catch (e) {
                    // console.log('status not available');
                }

                let update_progress = null;
                if (data.electricity.current_status === 'UPDATE_INSTALLING') {
                    try {
                        update_progress = data.electricity.smartreader.firmware_update_progress;
                    } catch (e) {
                        // console.log('update progress not available');
                    }
                }

                let firmware_status = null;
                try {
                    firmware_status = data.electricity.smartreader.firmware_status;
                } catch (e) {
                    // console.log('update progress not available');
                }

                return of({
                    meter_id: data.electricity.smartreader.meter_id_provisioned,
                    battery_status: data.electricity.smartreader.battery_status,
                    connection_quality: data.electricity.smartreader.signal_strength,
                    mode: data.electricity.smartreader.mode,
                    status,
                    update_progress,
                    firmware_status
                });
            }),
            catchError((error) => {
                return of(null);
            })
        );
    }
}

export interface MeterReaderStatus {
    meter_id: string;
    battery_status: number;
    connection_quality: number;
    mode: string;
    status?: string;
    update_progress?: number;
    firmware_status?: string;
}
