import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ConnectionIndicatorConfig}
    from '../../../components/connection-indicator/connection-indicator.component';
import {MeterService} from '../../../services/meter.service';
import {InitializationService} from '../../../services/initialization.service';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {padNumber} from '../../../lib/Utility';
import {MeterConnectionConfig} from '../../../shared/constants/connection.constants';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {of, throwError} from 'rxjs';
import {flatMap} from 'rxjs/operators';

@Component({
    selector: 'app-meter-tile',
    templateUrl: './meter-tile.component.html',
    styleUrls: ['./meter-tile.component.scss'],
    providers: [Globals]
})

export class MeterTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.METER;

    connectionConfig: ConnectionIndicatorConfig = MeterConnectionConfig;

    @Output() tileClicked = new EventEmitter<string>();

    refresh = [];
    meterStats = {
        kwh: '0',
        id: null,
    };

    showDiagrams = true;

    connection_quality = 0;

    meter_status = 'disconnected';
    meter_connected = false;

    meterId = '';

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService,
                private meter: MeterService,
                private initialization: InitializationService,
                private instantaneous: InstantaneousService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService) {
        super();
    }

    ngOnInit() {
        /**
         * Daten von API holen
         */
        if (this.application.isDemoMode()) {
            this.getMockMeter();
            this.getMockMeterInfo();
            this.getMockConnectionData();
            return;
        }

        this.getMeterinfo();

        this.meter.startLiveUpdate();
        const meterS = this.meter.onMeterStatus.subscribe(
            (res: any) => {
                if (res) {
                    if ('meter_txrssi' in res) {
                        this.connection_quality = res.meter_txrssi;
                        return;
                    }
                }
                this.connection_quality = 0;
            }
        );
        this.addSub(meterS);

        this.instantaneous.startLiveUpdate();
        const instaS = this.instantaneous.onInstantaneousUpdate.subscribe(
            (res: any) => {
                if (res) {
                    if ('electricity' in res) {
                        this.showDiagrams = true;
                        const rounded = Math.round(res.electricity.current_summation / 1000);
                        this.meterStats.kwh = padNumber(rounded, 6);


                    } else {
                        this.showDiagrams = false;
                    }
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
        this.addSub(instaS);
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    /**
     * Meter-Nummer holen
     */
    getMeterinfo() {
        const s = this.initialization.get().pipe(
            flatMap((res) => {
                if (!res) {
                    return throwError(null);
                }
                if (!('profile' in res)) {
                    return throwError(null);
                }
                if (!('meter_status_electricity' in res.profile)) {
                    return throwError(null);
                }
                if (!('meter_serial_number' in res.profile)) {
                    return throwError(null);
                }
                return of(res.profile);
            })
        ).subscribe(
            (res: any) => {
                const status = res.meter_status_electricity;
                if (status === 0) {
                    this.meter_status = 'connected';
                } else if (status === 1) {
                    this.meter_status = 'disconnected';
                } else {
                    this.meter_status = 'pending';
                }
                this.meter_connected = res.meter_status_electricity === 0;
                this.meterStats.id = this.formatSerialNumber(res.meter_serial_number);
            },
            (error) => null,
            () => s.unsubscribe()
        );
    }

    /**
     * Get mock meter data
     */
    private getMockMeterInfo(): void {
        const s = this._mockData.getInitialize().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('profile' in data.data) {
                        if ('meter_status_electricity' in data.data.profile) {

                            const status = data.data.profile.meter_status_electricity;
                            // console.log('status from response: ', status);
                            if (status === 0) {
                                this.meter_status = 'connected';
                            } else if (status === 1) {
                                this.meter_status = 'disconnected';
                            } else {
                                this.meter_status = 'pending';
                            }

                            // console.log('meter status: ', this.meter_status);

                            this.meter_connected = data.data.profile.meter_status_electricity === 0;
                        }

                        const meterId = data.data.profile.meter_serial_number;
                        this.meterStats.id = this.formatSerialNumber(meterId);
                    }
                }
                s.unsubscribe();
            }
        );
    }


    /**
     * Aktuellen Zählerstand holen
     */
    getMeter() {
        this.instantaneous.get().subscribe(
            (res: any) => {
                if (res) {
                    if ('electricity' in res) {
                        this.showDiagrams = true;
                        const rounded = Math.round(res.electricity.current_summation / 1000);
                        this.meterStats.kwh = padNumber(rounded, 6);
                    } else {
                        this.showDiagrams = false;
                    }
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
    }


    /**
     * Get Mock meter status
     */
    getMockMeter(): void {
        const s = this._mockData.getCurrentConsumption().subscribe(
            (data) => {
                if ('data' in data) {
                    if ('electricity' in data.data) {
                        this.showDiagrams = true;
                        const rounded = Math.round(data.data.electricity.current_summation / 1000);
                        this.meterStats.kwh = padNumber(rounded, 6);
                    } else {
                        this.showDiagrams = false;
                    }
                } else {
                    this.showDiagrams = false;
                }
                s.unsubscribe();
            }
        );
    }

    /**
     * Get Meter Status
     */
    getMeterStatus(): void {
        // this._apiService.getDeviceStatus()
        this.meter.getStatus().subscribe(
            (res: any) => {
                if (res) {
                    if ('meter_txrssi' in res) {
                        this.connection_quality = res.meter_txrssi;
                        return;
                    }
                }
                this.connection_quality = 0;
            }
        );
    }

    /**
     * Get Conneciton mock data
     */
    getMockConnectionData(): void {
        const s = this._mockData.getMeterStatus().subscribe(
            (values) => {
                if ('data' in values) {
                    // this.meterStats.connect = values.data.connected_with_meter.status;
                    this.connection_quality = values.data.meter_txrssi;
                }
                s.unsubscribe();
            }
        );
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'verbunden';
            default:
                return 'nicht verbunden';
        }
    }

    /**
     * Format Meter-serial number
     * @param value
     */
    private formatSerialNumber(value: string) {
        if (value === null || value === undefined || value.length < 1) {
            return value;
        }
        let return_str = '';
        for (let i = 0; i < value.length; i++) {
            if (i === 1 || i === 4 || i === 6 || i === 10) {
                return_str += ' ';
            }
            return_str += value.charAt(i);
        }
        return return_str;
    }


    /**
     * Analytics stuff
     */
    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Meter'
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Meter-Details'
            }
        });
    }
}
