export const liveDetailZoomLevels = [
    {
        name: '1 Tag',
        hint: 'ø pro 15 Minuten',
        level: 4,
        interval: 15 * 60,
        offset: 60 * 24,
        resolution: 60 * 60 * 4,
        format: '%H:%M Uhr'
    },
    {
        name: '12 Stunden',
        hint: 'ø pro Minute',
        level: 3,
        interval: 60,
        offset: 60 * 12,
        resolution: 60 * 60,
        format: '%H:%M Uhr'
    },
    {
        name: '1 Stunde',
        hint: 'ø pro Minute',
        level: 2,
        interval: 60,
        offset: 60,
        resolution: 60 * 15,
        format: '%H:%M Uhr'
    },
    {
        name: '5 Minuten',
        hint: 'ø pro Sekunde',
        level: 1,
        interval: 1,
        offset: 5,
        resolution: 60,
        format: '%H:%M:%S Uhr'
    }
];

export const liveDetailERNAZoomLevels = [
    {
        name: '1 Monat',
        hint: 'ø pro Tag',
        level: 3,
        interval: 60 * 60 * 24,
        offset: 60 * 24 * 30,
        resolution: 60 * 60 * 24 * 2,
        format: '%d.%m.%Y'
    },
    {
        name: '1 Woche',
        hint: 'ø pro Tag',
        level: 2,
        interval: 60 * 60 * 24,
        offset: 60 * 24 * 7,
        resolution: 60 * 60 * 24,
        format: '%d.%m.%Y'
    },
    {
        name: '1 Tag',
        hint: 'ø pro Stunde',
        level: 1,
        interval: 60,
        offset: 60 * 24,
        resolution: 60 * 60 * 2,
        format: '%H:%M Uhr'
    },
];
