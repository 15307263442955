import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';

import {ApiService} from '../../services/api.service';
import {MockDataService} from '../../services/mock-data.service';
import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {InitializationService} from '../../services/initialization.service';
import {ProfileService} from '../../services/profile.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {MeterService} from '../../services/meter.service';
import * as moment from 'moment';
import {Popover} from '../../popovers/popover/popover.service';
import {ChangeEmailComponent} from '../../popovers/change-email/change-email.component';
import {ResetPasswordComponent} from '../../popovers/reset-password/reset-password.component';
import {SettingsTexts} from '../../shared/texts/settings.texts';
import {Tariff} from '../../classes/user';
import {TariffDataEntryComponent} from '../../popovers/tariff-data-entry/tariff-data-entry.component';
import {TariffHistoryComponent} from '../../popovers/tariff-history/tariff-history.component';
import {getProviderIconClass, ProviderMappings} from '../../lib/ProviderUtil';

@Component({
    selector: 'iona-app',
    styleUrls: ['profile.component.scss'],
    templateUrl: './profile.component.html',
    viewProviders: []
})

export class ProfileComponent extends BaseComponent implements OnInit, OnDestroy {

    readonly TEXTS = SettingsTexts;

    moment = moment;

    // provider
    provider = '';
    username = null;
    providerclass = '';
    providerDisplayName = '';

    // tariff
    contract: any = {
        name: '',
        tarif: null,
        basicprice: null,
        workingprice: null
    };

    isERNAUser = false;
    tariffs: Tariff[] = [];
    activeTariff: Tariff = null;
    tariffsAvailable = false;
    createTariffDisabled = false;

    constructor(public _apiService: ApiService,
                public application: ApplicationService,
                private title: Title,
                private notification: ToastrService,
                private mockDataService: MockDataService,
                private userService: UserService,
                private initialization: InitializationService,
                private profile: ProfileService,
                private versionService: VersionService,
                private meter: MeterService,
                private registration: RegistrationService,
                private popover: Popover) {
        super();
    }

    ngOnInit() {
        this.title.setTitle('Meine Daten | iONA');
        this.username = this.userService.getActiveUserName();
        this.provider = this.userService.getActiveUserProvider();
        this.providerclass = getProviderIconClass(this.userService.getActiveUserProvider());
        try {
            this.providerDisplayName = ProviderMappings.find(
                el => el.name === this.userService.getActiveUserProvider()
            ).displayName;
        } catch (e) {
            this.providerDisplayName = this.userService.getActiveUserProvider();
        }
        this.initialize();
    }

    /**
     * Initialize the component
     */
    initialize(): void {
        this.addSub(this.initialization.get().subscribe((res) => {
            if (res) {
                if ('profile' in res) {
                    this.processContractData(res);
                }
            }
        }));

        this.addSub(this.profile.getContract().subscribe(
            (res: any) => {
                if ('profile' in res) {
                    this.processProfileData(res);
                }
            }
        ));

        this.isERNAUser = this.userService.isEDGUser();
        if (this.isERNAUser) {
            this.initializeTariffs();
        }
        this.addSub(this.profile.getContract().subscribe(
            (res: any) => {
                if ('profile' in res) {
                    this.processProfileData(res);
                }
            }
        ));
    }

    ngOnDestroy() {
    }

    openChangeEmailPopover(): void {
        const s = this.popover.open({
            content: ChangeEmailComponent,
            data: {},
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res: any) => {
            console.log(res);
            if (!res.data) {
                s.unsubscribe();
                return;
            }
            if (res.data.success) {
                this.changeEmail(res.data.value);
            }
            s.unsubscribe();
        });
    }

    openResetPasswordPopover(): void {
        const s = this.popover.open({
            content: ResetPasswordComponent,
            data: {},
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res: any) => {
            if (!res.data.success) {
                s.unsubscribe();
                return;
            }
            const d = res.data.values as { oldPassword: string, firstNewPassword: string, secondNewPassword: string };
            this.changePassword(d.oldPassword, d.firstNewPassword, d.secondNewPassword);
            s.unsubscribe();
        });
    }


    /**
     * Change mail adress
     * @param email
     */
    changeEmail(email: string) {
        if (this.application.isDemoMode()) {
            this.notification.info('Die E-Mail-Adresse kann im Demo-Modus nicht geändert werden!');
            return;
        }
        const sub = this.registration.setEmail(email).subscribe(
            // this._apiService.setEmail(email).subscribe(
            (res) => {
                this.notification.success('Ihre E-Mail-Adresse wurde geändert!');
                this.username = email;
            },
            (error: any) => {
                if (error._body) {
                    const err: any = JSON.parse(error._body);
                    switch (error.error.code) {
                        case 104: {
                            this.notification.error('Ihre E-Mail-Adresse ist fehlerhaft!');
                            break;
                        }
                        default: {
                            this.notification.error('Ihre E-Mail-Adresse konnte nicht geändert werden!');
                        }
                    }
                } else {
                    this.notification.error('Ihre E-Mail-Adresse konnte nicht geändert werden!');
                }
                sub.unsubscribe();
            }
        );
    }

    /**
     * Change user password
     * @param oldPass
     * @param newPass
     * @param newPassRepeat
     */
    changePassword(oldPass: string, newPass: string, newPassRepeat: string) {
        if (this.application.isDemoMode()) {
            return this.notification.info('Das Passwort kann im Demo-Modus nicht geändert werden!');
            return;
        }

        if (oldPass.length == 0 || newPassRepeat.length == 0 || newPassRepeat.length == 0) {
            this.notification.error('Bitte alle Felder ausfüllen!');
        }

        this.registration.updatePassword(oldPass, newPass, newPassRepeat).subscribe(
            // this._apiService.updatePassword(oldPass, newPass, newPassRepeat).subscribe(
            () => {
                this.notification.success('Ihr Passwort wurde geändert!');
            },
            (error: any) => {
                if ('error' in error) {
                    if ('error' in error.error) {
                        if (error.error.error.code === 264) {
                            const msg = error.error.error.message;
                            if (msg.includes('MAX length')) {
                                this.notification.error(
                                    'Das gewählte Passwort ist zu lang. Bitte verwenden Sie maximal 16 Zeichen.',
                                    'Passwort ungültig',
                                    {timeOut: 6000}
                                );
                            } else if (msg.includes('Special characters')) {
                                this.notification.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. ' +
                                    'Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ' +
                                    '! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.',
                                    'Passwort ungültig',
                                    {timeOut: 6000}
                                );
                            }
                        }
                    }
                }
            }
        );
    }


    /**
     * Process contract data
     * @param data
     */
    private processContractData(data): void {
        if (this.application.isDemoMode()) {
            this.contract.name = 'Maxi Mustermann';
        } else {
            this.contract.name = data.profile.customer_name;
        }
        this.contract.tarif = data.profile.product_name;
    }

    /**
     * Process Profile information
     * @param data
     */
    private processProfileData(data): void {
        let basicprice = 0;
        if (typeof data.profile.e_fixed_tariff !== 'undefined') {
            basicprice = parseFloat(data.profile.e_fixed_tariff);
        } else {
            basicprice = parseFloat(data.profile.budget_bill);
        }

        const workingprice = parseFloat(data.profile.e_tariff);
        this.contract.basicprice = basicprice.toLocaleString('de-DE',
            {style: 'currency', currency: 'EUR'}
        );
        this.contract.workingprice = `${Math.round(workingprice * 100)} ct/kWh`;

    }

    private initializeTariffs(): void {
        const stored = this.userService.getTariffInfo();
        this.tariffs = stored ? stored : [];
        this.tariffsAvailable = false;
        this.createTariffDisabled = false;
        this.activeTariff = null;
        if (this.tariffs.length > 0) {
            this.tariffsAvailable = true;
            const latest = this.tariffs[this.tariffs.length - 1];
            const d = new Date(latest.dateStart);
            const now = moment().hours(2).minutes(0).seconds(0).milliseconds(0);
            console.log('now', now.toDate());
            console.log('parsed', d);
            if (d >= now.toDate()) {
                this.createTariffDisabled = true;
            }
            this.activeTariff = this.tariffs[this.getActiveTariffIdx()];
        }
    }

    openNewTariffCreationPopover(): void {
        const s = this.popover.open({
            content: TariffDataEntryComponent,
            data: {
                tariffs: this.tariffs
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            if (!res.data) {
                console.log('Something went wrong here');
                return;
            }
            const mode = res.data['mode'];
            if (mode === 'create') {
                if (res.data['data']) {
                    const d = res.data['data'] as any;
                    d.dateEnd = null;
                    this.userService.addTarrifInfo(d);
                    this.initializeTariffs();
                }
            }
            s.unsubscribe();
            this.initializeTariffs();
        });
    }

    openTariffHistoryPopover(): void {
        const s = this.popover.open({
            content: TariffHistoryComponent,
            data: {
                provider: this.provider,
                tariffs: this.tariffs
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            this.initializeTariffs();
            s.unsubscribe();
        });
    }

    editCurrentTariff(): void {
        const currentIdx = this.getActiveTariffIdx();

        const s = this.popover.open({
            content: TariffDataEntryComponent,
            data: {
                tariffs: this.tariffs,
                edit_mode: true,
                edit_data: this.tariffs[currentIdx],
                edit_idx: currentIdx
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            console.log('res.data', res.data);
            if (!res.data) {
                console.log('Something went wrong here');
                return;
            }
            const mode = res.data['mode'];
            if (mode === 'update') {
                if (res.data['data']) {
                    const d = res.data['data'] as any;
                    d.dateEnd = null;
                }
                this.tariffs[currentIdx].name = res.data['data'].name;
                this.tariffs[currentIdx].basePrice = res.data['data'].basePrice;
                this.tariffs[currentIdx].workPrice = res.data['data'].workPrice;
                this.userService.updateTariffs(this.tariffs.reverse());
            }
            if (mode === 'delete') {
                this.userService.removeTariff(res.data['data']);
            }
            console.log(this.contract);
            s.unsubscribe();
            this.initializeTariffs();
        });
    }

    determinePrice(value: number): string {
        return value.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
    }

    private getActiveTariffIdx(): number {
        const comparison_date = moment().hours(2).minutes(0).seconds(0).milliseconds(0).toDate();
        return this.tariffs.findIndex((el) => {
            const start = new Date(el.dateStart);
            const end = el.dateEnd ? new Date(el.dateEnd) : new Date();
            return comparison_date >= start && comparison_date <= end;
        });
    }

    getCurrentTariffName(): string {
        if (this.tariffsAvailable) {
            if (this.activeTariff) {
                return this.activeTariff.name;
            }
        }
        return this.contract.tarif;
    }

    getCurrentTariffBasePrice(): string {
        if (this.tariffsAvailable) {
            if (this.activeTariff) {
                return this.determinePrice(this.activeTariff.basePrice);
            }
        }
        return this.contract.basicprice;
    }

    getCurrentTariffWorkPrice(): string {
        let price = this.contract.workingprice;
        if (this.tariffsAvailable) {
            if (this.activeTariff) {
                const temp = this.activeTariff.workPrice * 100;
                if (temp < 100) {
                    price = `${Math.round(temp)} ct/kWh`;
                } else {
                    price = `${this.activeTariff.workPrice} €/kWh`;
                }
            }
        }
        return price;
    }


}
