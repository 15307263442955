import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as moment from 'moment';
import {AxisLabelsFormatterContextObject, SeriesAreaOptions} from 'highcharts';

@Component({
    selector: 'app-live-chart',
    templateUrl: './live-chart.component.html',
    styleUrls: ['./live-chart.component.scss']
})
export class LiveChartComponent implements OnInit {

    @Input() isDetail = false;

    chart: Chart = null;
    _unit = 'Watt';

    private readonly seriesColor = '#47A7D8';

    constructor() {
    }

    ngOnInit() {
        this.initializeChart();
    }

    setUnit(unit: string): void {
        this._unit = unit;
    }

    addNewSeries(values,
                 y_attribute: string,
                 remove_series_count = 0,
                 options?: AddNewLiveChartSeriesOptions): void {
        if (remove_series_count >= 0) {
            this.removeSeries(0, remove_series_count);
        }

        // catch unmapped plain response
        if ('results' in values) {
            values = values.results;
        }

        const s: SeriesAreaOptions = {
            name: null,
            zIndex: 1,
            data: values.map((element) => {
                return [
                    new Date(element.timestamp).getTime(),
                    element[y_attribute]
                ];
            }),
            type: 'area'
        };

        if (options!.zindex) {
            s.zIndex = options!.zindex;
        }

        this.chart.addSeries(s, true, false);
        if (options!.isTileChart) {
            const firstDate = new Date(values.first().timestamp).getTime();
            const lastDate = new Date(values.last().timestamp).getTime();
            const tickPositions = [firstDate, lastDate];
            this.chart.ref.update({xAxis: {tickPositions}}, true);
        }
    }

    addZones(zones): void {
        if (this.chart.ref) {
            this.chart.ref.update({plotOptions: {area: {zones}}});
        }
    }

    removeSeries(idx: number, count = -1): void {
        if (count === -1) {
            this.chart.removeSeries(idx);
        } else {
            for (let i = idx; i < idx + count; ++i) {
                this.chart.removeSeries(i);
            }
        }
    }

    updateZoomLevel(resolution: number, date_format: string): void {
        this.chart.ref.update({
            xAxis: {
                tickInterval: resolution
            },
            tooltip: {
                xDateFormat: date_format
            },
            series: [
                {
                    name: null,
                    data: [],
                    type: 'area',
                }
            ]
        }, true);
    }

    reset(): void {
        this.chart.ref.showLoading('Auswertung wird geladen…');
    }

    showLoadingState(show: boolean = true): void {
        if (show) {
            this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
        } else {
            if (this.chart.ref) {
                this.chart.ref.hideLoading();
            }
        }
    }

    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'area',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [50, 10, 50, 40],
            },
            title: {
                text: null,
            },
            xAxis: {
                title: {
                    text: 'Zeit',
                    align: 'low',
                    x: -32,
                    y: 0,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                type: 'datetime',
                tickInterval: 1000,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%d.%m',
                    week: '%d.%m',
                    month: '%m.%Y',
                    year: '%Y'
                },
                labels: {
                    enabled: true,
                    // align: 'middle',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy regular, sans-serif',
                    },
                    formatter: this.isDetail ? undefined :
                        (ref) => {
                            return moment(ref.value).format('hh:mm ');
                        }
                },
                crosshair: {
                    width: 1,
                    color: 'rgba(0,0,0,0.6)',
                    zIndex: 3
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: 'Watt',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -20,
                    x: -8,
                    style: {
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                startOnTick: this.isDetail,
                tickAmount: 4,
                labels: {
                    align: 'right',
                    x: -8,
                    y: 6,
                    step: 1,
                    formatter() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE');
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                gridZIndex: 10,
                gridLineColor: 'rgba(0,0,0,0.20)'
            },
            tooltip: {
                xDateFormat: '%H:%M:%S Uhr',
                hideDelay: 0,
                animation: false,
                shadow: true,
                padding: 0,
                positioner(boxWidth: number, boxHeight: number, point: any) {
                    if ((point.plotX + this.chart.plotLeft + boxWidth) > this.chart.plotWidth) {
                        // old
                        // x: (point.plotX + this.chart.plotLeft) - boxWidth - 10,
                        return {
                            x: point.plotX,
                            y: point.plotY + (boxHeight / 2)
                        };
                    } else {
                        // old
                        // x: point.plotX + this.chart.plotLeft + 10,
                        return {
                            x: point.plotX,
                            y: point.plotY + (boxHeight / 2)
                        };
                    }
                },
                useHTML: true,
                formatter() {
                    const d = moment(this.key).format('HH:mm:ss');
                    const style = `style="border-color: ${this.color}"`;
                    const name = `<div class="header">${d} Uhr</div>`;
                    const value = `<div class="body"> ${this.y.toLocaleString('de-DE')} ${self._unit}</div>`;
                    return `<div class="column-callout" ${style}> ${name} ${value}</div>`;
                },
                backgroundColor: 'transparent',
                borderWidth: 0,
                borderRadius: 0
            },
            plotOptions: {
                series: {
                    showInLegend: false,
                    animation: false,
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }

                },
                area: {
                    fillOpacity: 1,
                    fillColor: this.seriesColor,
                    lineWidth: 0,
                    marker: {
                        enabled: false
                    },
                    zoneAxis: 'x',
                    zones: [
                        {value: 0},
                        {color: this.seriesColor},
                    ],
                    color: this.seriesColor,
                    borderWidth: 0
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        });
    }
}

export interface AddNewLiveChartSeriesOptions {
    zindex?: number;
    isTileChart?: boolean;
}
