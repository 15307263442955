import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild} from '@angular/core';

@Component({
    selector: 'app-eon-slider',
    templateUrl: './eon-slider.component.html',
    styleUrls: ['./eon-slider.component.scss']
})
export class EonSliderComponent implements OnInit, OnChanges {

    @Input() value = 0;
    @Input() range = '0 100';
    @Input() step = 1;

    @Output() onValueChange: EventEmitter<number> = new EventEmitter();

    @ViewChild('sliderKnob', {static: true}) sliderKnobRef: ElementRef;
    @ViewChild('sliderProgress', {static: true}) sliderProgressRef: ElementRef;
    @ViewChild('sliderContainer', {static: true}) sliderContainerRef: ElementRef;

    private parsedRange = null;

    private mouseDown = false;
    private sliderContainerInnerWidth = null;

    private lastLeft = 0;
    private eventCounter = 0;

    private numSections = 0;
    private valuePercentage = 0;

    private currentLeft = 0;
    private currentValuePercentage = 0;
    private triggers = [];
    private lastSection = null;

    private knobSize = 40;

    constructor(private renderer: Renderer2,
                private element: ElementRef) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    onKnobMouseDown($event): void {
        this.mouseDown = true;
        this.lastLeft = $event.target.offsetLeft;
    }

    onKnobMouseMove($event): void {
        if (!this.mouseDown) {
            return;
        }

        this.currentLeft = this.lastLeft + $event.deltaX;
        if (this.currentLeft >= 0 && this.currentLeft <= this.sliderContainerInnerWidth - (this.knobSize - 20)) {
            if (this.eventCounter % 5 === 0) {
                //
                // this.updateView(this.currentLeft);
                const currentPercentage = this.mapToRange(this.currentLeft,
                    0, this.sliderContainerInnerWidth - (this.knobSize - 20),
                    0, 1);

                const currentSection = this.triggers.reduce((prev, current) => {
                    const condition = Math.abs(current.percentage - currentPercentage) <
                        Math.abs(prev.percentage - currentPercentage);
                    return condition ? current : prev;
                });

                if (currentSection !== this.lastSection) {
                    this.updateView(currentSection.valueView -10);
                    this.lastSection = currentSection;
                }

                this.currentValuePercentage = currentPercentage;
                this.eventCounter = 0;
            }
        }
        this.eventCounter++;
    }

    onKnobMouseUp($event): void {
        this.mouseDown = false;
        this.lastLeft = $event.target.offsetLeft;
        this.onValueChange.emit(this.lastSection.value);
    }

    private updateView(value): void {
        this.renderer.setStyle(this.sliderKnobRef.nativeElement, 'left', `${value}px`);
        this.renderer.setStyle(this.sliderProgressRef.nativeElement, 'width', `${value + (this.knobSize / 2)}px`);
    }

    private initialize(): void {
        this.renderer.setStyle(this.element.nativeElement, 'width', `100%`);

        this.parsedRange = this.range.split(' ').map((el) => parseInt(el, 10));
        this.sliderContainerInnerWidth = this.sliderContainerRef.nativeElement.clientWidth;
        this.numSections = ((this.parsedRange[1] - this.parsedRange[0]) / this.step);
        this.valuePercentage = 100 / this.numSections;
        this.triggers = [];
        for (let i = 0; i <= this.numSections; ++i) {
            const percentage = i * this.valuePercentage / 100;
            const valueView = (this.sliderContainerInnerWidth - (this.knobSize - 20)) * percentage;
            const value = i;
            this.triggers.push({percentage, valueView, value});
        }

        this.lastSection = this.triggers.find((el) => el.value === this.value);
        this.updateView(this.lastSection.valueView -10);
    }

    private mapToRange(x, in_min, in_max, out_min, out_max): number {
        return ((out_max - out_min) / (in_max - in_min)) * x + out_min;
    }

}
