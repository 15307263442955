import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';

@Component({
    selector: 'app-tile-wrap',
    templateUrl: './tile-wrap.component.html',
    styleUrls: ['./tile-wrap.component.scss']
})
export class TileWrapComponent implements OnInit {

    constructor(private _element: ElementRef,
                private _renderer: Renderer2) {
    }

    ngOnInit() {
        this.matchHeight();
    }

    onResize(evt?: any): void {
        this.matchHeight();
    }

    private matchHeight(): void {
        const width = this._element.nativeElement.parentNode.clientWidth;
        this._renderer.setStyle(this._element.nativeElement.parentNode, 'clientHeight', `${width}px`);
    }
}
