import {Component, OnInit} from '@angular/core';

import {Chart} from 'angular-highcharts';
import {Globals} from '../../../services/globals.service';
import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {DisaggregationService} from '../../../services/disaggregation.service';
import {NilmService} from '../../../services/nilm.service';
import {getMonthName} from '../../../lib/DateUtil';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {AppliancesDiagramCallout, translateAppliance} from '../appliances.utils';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';


@Component({
    selector: 'app-appliances-tile',
    templateUrl: './appliances-tile.component.html',
    styleUrls: ['./appliances-tile.component.scss'],
    providers: [Globals]
})

export class AppliancesTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.APPLIANCES;
    private readonly applianceImagePath = 'assets/img/graphics/appliances/';
    private readonly applianceImageType = 'png';

    monthname: string = null;

    // colors = ['#1ea2b1', '#56b9c5', '#616161', '#747475'];
    colors = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];

    color = 0;

    tempdata: any = [];

    chart: any = {};

    appliances: any = [];

    showConsumers = true;
    showDiagrams = true;
    nilmdata: any = null;

    defaultCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', value: '', showBoeppel: false
    };
    currentCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', value: '', showBoeppel: false
    };

    constructor(private globals: Globals,
                private mockData: MockDataService,
                private disaggregation: DisaggregationService,
                private nilm: NilmService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService) {
        super();
    }

    ngOnInit() {
        this.monthname = getMonthName(0);
        this.initializeChart();

        if (this.application.isDemoMode()) {
            this.getMockElectricalAppliances();
            this.getMockNilmStatus();
            return;
        }

        this.getElectricalApplicances();
        this.getNilmStatus();
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    /**
     * Get Demo Mock data
     */
    private getMockElectricalAppliances(): void {
        const s = this.mockData.getElectricalAppliances(0).subscribe(
            (res) => {
                if ('data' in res) {
                    this.drawDiagram(res.data);
                } else {
                    this.showDiagrams = false;
                }
            }
        );
        this.addSub(s);
    }

    private getElectricalApplicances(): void {
        const s = this.disaggregation.getDisaggregationHistoryByOffset(0).subscribe(
            (data) => {
                if (data) {
                    this.drawDiagram(data);
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
        this.addSub(s);
    }

    private getMockNilmStatus(): void {
        const s = this.mockData.getNILMStatus().subscribe(
            (data) => {
                if ('data' in data) {
                    this.nilmdata = data.data;
                }
            }
        );
        this.addSub(s);
    }

    private getNilmStatus(): void {
        this.addSub(
            this.nilm.getStatus().subscribe(
                (res: any) => this.nilmdata = res,
                (error) => console.log(error)
            )
        );
    }


    /**
     * Sort & align data --> draw the diagram
     * @param data
     */
    private drawDiagram(data: any): void {
        for (const appliance of Object.keys(data.electricity.used_budget.categories)) {
            if (data.electricity.used_budget.categories[appliance].usage > 0) {
                this.tempdata.push({
                    name: appliance,
                    usage: data.electricity.used_budget.categories[appliance].usage
                });
            }
        }

        this.tempdata.sort((a, b) => b.usage - a.usage);

        const series = [];
        for (const appliance of this.tempdata.slice(0, 3)) {
            const color = this.getColor();
            this.appliances.push({name: appliance.name, color});
            series.push({name: appliance.name, y: appliance.usage, color, sliced: true});
        }

        let other = 0;
        for (const appliance of this.tempdata.slice(3)) {
            other += appliance.usage;
        }
        if (other > 0) {
            const color = this.getColor();
            this.appliances.push({name: 'Other', color});
            series.push({name: 'Other', y: other, color, sliced: true});
        }
        this.chart.addSeries({name: 'Series', data: series, type: 'pie'});
        this.showConsumers = this.appliances.length !== 0;
    }

    /**
     * Farbe für Verbraucher
     */
    getColor() {
        if (this.color < this.colors.length) {
            this.color++;
            return this.colors[this.color - 1];
        } else {
            return this.colors[this.colors.length - 1];
        }
    }

    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private determineCalloutData(chartRef): AppliancesDiagramCallout {
        const pointName = chartRef.point.name.toLowerCase();
        const name = translateAppliance(chartRef.point.name);
        const percentage = Math.floor(chartRef.point.percentage).toString();
        const value = Math.floor(chartRef.y);
        const callout: AppliancesDiagramCallout = {
            image: `url(${this.determineApplianceImagePath(pointName)})`,
            color: chartRef.point.color,
            label: `${name}`,
            value: `${value} kWh`,
            showBoeppel: false
        };

        // NILM
        if (pointName !== 'other') {
            if (pointName === 'laundry' ||
                pointName === 'refrigeration' ||
                pointName === 'entertainment' ||
                pointName === 'cooking') {
                if (!NilmService.determineProfileCompleteness(pointName, this.nilmdata)) {
                    // console.log(!this.determineProfileCompleteness(pointName));
                    // console.log('true', true);
                    callout.showBoeppel = true;
                }
            }
        }

        return callout;
    }


    /**
     * Initialize Chart
     */
    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0],
                events: {
                    redraw() {
                        this.reflow();
                    },
                    render(event) {
                        if ('series' in event.target) {
                            if (event.target['series']['length'] > 0) {
                                const points = event.target['series'][0]['points'] as any[];
                                const sorted = points.sort((a, b) => {
                                    return b.percentage - a.percentage;
                                });
                                const name = translateAppliance(sorted[0].name);
                                const percentage = Math.floor(sorted[0].percentage).toString();
                                const value = Math.floor(sorted[0].y).toString();
                                const image = `${sorted[0].name.toLowerCase()}`;
                                const imagePath = self.determineApplianceImagePath(image);
                                self.defaultCallout.color = sorted[0].color;
                                self.defaultCallout.image = `url(${imagePath})`;
                                self.defaultCallout.label = name;
                                self.defaultCallout.value = `${value} kWh`;
                                self.currentCallout = self.defaultCallout;
                            }
                        }
                    }
                }
            },
            title: {text: null},
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner(boxWidth: number, boxHeight: number) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter() {
                    self.currentCallout = self.determineCalloutData(this);
                    return '';
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter() {
                            const value = Math.floor(this.percentage);
                            const style = `style="color:${this.color}"`;
                            return `<div class="label" ${style}> ${value} %</div>`;
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 0,
                        connectorColor: 'white',
                        softConnector: false,
                        style: {
                            fontSize: '20px',
                            fontFamily: 'Innogy light, sans-serif',
                            color: '#39393a'
                        }
                    },
                    innerSize: '100%',
                    borderWidth: 10,
                    borderColor: null,
                    slicedOffset: 0,
                    startAngle: -180,
                    states: {hover: {brightness: 0}},
                    point: {
                        events: {
                            mouseOut() {
                                self.currentCallout = self.defaultCallout;
                            },
                        }
                    }
                }
            },
            series: [],
            credits: {enabled: false}
        });

    }

    private determineApplianceImagePath(appliance: string): string {
        return `${this.applianceImagePath}${appliance}.${this.applianceImageType}`;
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Appliances'
            }
        });
    }

    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Consumer-Details'
            }
        });
    }
}

