import {Component, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as moment from 'moment';
import {InstantaneousService} from '../../../../services/instantaneous.service';
import {BaseComponent} from '../../../../classes/base-component';

@Component({
    selector: 'app-phase-history',
    templateUrl: './phase-history.component.html',
    styleUrls: ['./phase-history.component.scss']
})
export class PhaseHistoryComponent extends BaseComponent implements OnInit {

    chart = null;
    currentStep = 0;
    dataNull = false;
    numDatasets = 0;
    elementsToShow = 7;
    currentSliceOriginal = null;

    private dateFormat = 'YYYY-MM-DD';
    private phaseData = null;
    private phaseDataAligned = null;

    private initialized = false;

    constructor(private instantaneousService: InstantaneousService) {
        super();
    }

    ngOnInit() {
        this.initChart();
        this.initDates();
    }

    initDates(): void {
        let now = moment();
        const current_month_start = moment(now).startOf('month');
        this.instantaneousService.getPhasesForMonths(current_month_start, now).subscribe(
            (res) => {
                if (res) {
                    this.phaseData = res.original;
                    this.phaseDataAligned = res.aligned;
                    this.numDatasets = this.phaseDataAligned.phase1.length;
                    this.updateChartData();
                } else {
                    this.dataNull = true;
                }
            }
        );
    }

    updateChartData(): void {
        if (this.chart) {
            while (this.chart.ref.series.length) {
                this.chart.removeSeries(0);
            }

            if (this.initialized) {
                this.chart.ref.update({plotOptions: {series: {animation: false}}}, true);
                console.log('update config');
            }
        }

        const slices = this.getCurrentSlice(this.phaseDataAligned);
        this.currentSliceOriginal = this.getCurrentSlice(this.phaseData);

        this.chart.addSeries({name: 'Phase 1', data: slices.phase1}, true, false);
        this.chart.addSeries({name: 'Phase 2', data: slices.phase2}, true, false);
        this.chart.addSeries({name: 'Phase 3', data: slices.phase3}, true, false);

        if (!this.initialized) {
            this.initialized = true;
        }
    }

    back(): void {
        this.currentStep++;
        this.updateChartData();
    }

    forward(): void {
        if (this.currentStep !== 0) {
            this.currentStep--;
        }
        this.updateChartData();
    }

    today(): void {
        this.currentStep = 0;
        this.updateChartData();
    }

    private initChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'area',
                margin: [0, 0, 25, 0],
                height: '60%',
                reflow: true,
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter() {
                        if (this.isFirst || this.isLast) {
                            return '';
                        }
                        const temp = moment(this.value);
                        const final = temp.add('10', 'days');
                        return final.locale('de').format('MMM');
                    },
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Innogy regular, sans-serif'
                    }
                },
                tickWidth: 0,
                endOnTick: false,

            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 60,
                    y: 15,
                    formatter() {
                        return this.value !== 0 ? `${this.value.toLocaleString('de')} W` : '';
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy regular',
                        color: '#333333',
                        textOutline: null
                    },
                },
                tickAmount: 4,
                tickWidth: 1,
                tickColor: '#e5e5e5',
                gridLineColor: '#e5e5e5',
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    // animation: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                },
                area: {
                    fillColor: 'rgba(235,75,10, 0.2)',
                    dashStyle: undefined,
                    lineWidth: 0,
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 2,
                        fillColor: '#333',
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                    },
                }
            },
            series: [],
            tooltip: {
                useHTML: true,
                enabled: true,
                shadow: false,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: 'rgb(204,204,204)',
                backgroundColor: '#ffffff',
                formatter() {
                    const idx = this.colorIndex;
                    const value = self.currentSliceOriginal[`phase${idx + 1}`][this.point.index][1];
                    const date = self.currentSliceOriginal[`phase${idx + 1}`][this.point.index][0];
                    const value_template = `<div style="font-size: 16px"> ${value.toLocaleString('de')} Watt </div> `;
                    const date_template = `<div>${moment(date).locale('de').format('MMM YYYY')}</div>`;
                    return `<div style=" text-align: center">${value_template} ${date_template}</div> `;
                },
                style: {
                    fontFamily: 'Innogy regular',
                    fontSize: '18px',
                }
            },
            credits: {
                enabled: false
            },
        });
    }

    private getCurrentSlice(data: any): any {
        const from = this.numDatasets - this.elementsToShow - this.currentStep;
        const to = this.numDatasets - this.currentStep;

        return {
            phase1: data.phase1.slice(from, to),
            phase2: data.phase2.slice(from, to),
            phase3: data.phase3.slice(from, to),
        };
    }

    private datasetValid(res: any[]): boolean {
        try {
            let sum = 0;
            for (const el of res) {
                for (const k of Object.keys(el)) {
                    sum += el[k].power_max;
                    sum += el[k].power_min;
                    sum += el[k].power_avg;
                }
            }
            return sum !== 0;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
