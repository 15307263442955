import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {Router} from '@angular/router';
import {MockDataService} from '../../services/mock-data.service';

import {forkJoin} from 'rxjs/observable/forkJoin';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../../shared/constants/constants';
import {UserService} from '../../services/user.service';
import {User} from '../../classes/user';
import {ApplicationService} from '../../services/application.service';
import {BaseService} from '../../services/base-service';
import {AuthService} from '../../services/auth.service';
import {VisibilityService} from '../../services/visibility.service';
import {LoginTexts} from '../../shared/texts/login.texts';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationTexts} from '../../shared/texts/notification.texts';
import {of, throwError} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
    selector: 'iona-app',
    templateUrl: './login.component.html',
    styleUrls: [
        './login-base.component.scss',
        './login-initial.component.scss',
        './login-password-reset.component.scss',
        './login.component.scss',
    ],
    viewProviders: [],
    providers: [Globals]
})

export class LoginComponent implements OnInit, AfterViewChecked, OnDestroy {

    readonly TEXTS = LoginTexts;
    readonly LoginState = LoginState;
    readonly Notifications = NotificationTexts;

    private start_time: Date;
    private demo_interval;

    email = '';
    password = '';
    emailreset = '';

    state = LoginState.LOGIN;

    loginDisabled = true;
    emailResetDisabled = true;
    displayEmailError = false;
    displayEmailResetError = false;
    displayPasswordError = false;

    // forms
    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required])
    });

    passwordResetForm = new FormGroup({
        email: new FormControl('', [Validators.required])
    });

    static validateEmailFormat(email: string): boolean {
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return regex.test(email);
    }

    constructor(private _titleService: Title,
                public _apiService: ApiService,
                private _globals: Globals,
                private _router: Router,
                private _mockData: MockDataService,
                private _notification: ToastrService,
                private _userService: UserService,
                private application: ApplicationService,
                private auth: AuthService,
                private visibility: VisibilityService) {
        if (this._userService.hasUser()) {
            this.state = LoginState.LOGIN;
        }
    }

    ngOnInit() {
        this.setupForms();
        this._titleService.setTitle('Login | iONA');
        this._apiService.loggedOut.subscribe(
            (next) => {
                if (next === 'demo') {
                    clearInterval(this.demo_interval);
                } else if (next === 'live') {
                    BaseService.killAll();
                } else {
                    console.log('other weird reason');
                }
            }
        );
    }

    ngOnDestroy() {
    }

    ngAfterViewChecked() {
    }

    changeState(newState: LoginState): void {
        this.state = newState;
    }

    login(): void {
        const formValues = this.loginForm.value;
        const user = formValues.email;
        const password = formValues.password;

        if (this.loginDisabled) {
            return;
        }

        this.application.setApplicationState(constants.application.states.live);
        this.auth.login(user, password).pipe(
            mergeMap(res => {
                return !res ? throwError(null) : of(res);
            })
        ).subscribe(
            (res) => {
                this._router.navigate(['/']);
                this.visibility.init();
                this.auth.startContinuousTokenRefresh();
            },
            (error) => {
                if ('provider' in error) {
                    this._notification.error(this.Notifications.GENERAL_LOGIN_ERROR);
                }
            });
    }

    continueWithDemoMode() {
        if (this.state !== LoginState.INITIAL && this.state !== LoginState.LOGIN) {
            console.log('here');
        }

        const init_sub = this._mockData.getInitialize();
        const profile_sub = this._mockData.getProfile();

        // set globally needed variables
        forkJoin([init_sub, profile_sub]).subscribe(
            (values: any) => {
                this._router.navigate(['']);

                const u: User = {
                    email: 'demo',
                    access_token: '',
                    refresh_token: '',
                    provider: values[0].data.profile.labelpartner,
                    nilm_status: null,
                    token_expires: null,
                    tiles: null,
                    storage: null,
                    device: constants.application.devices.plug
                };
                this._userService.setCurrentUser(u);
                this._userService.setActiveUserProvider(values[0].data.profile.labelpartner);

                // calculate expire date
                const demo_mode_min = 5;
                const demo_mode_time = demo_mode_min * 60 * 1000;
                const current_time = new Date();
                const expire_date = new Date();
                expire_date.setMinutes(current_time.getMinutes() + 5);
                this.application.enableDemoMode(expire_date);

                const msg = this.Notifications.DEMO_MODE_START.replace('**', '5');
                this._notification.info(msg);

                this.start_time = new Date();
                this.demo_interval = setInterval(() => {
                    const now = new Date();

                    const diff = now.getTime() - this.start_time.getTime();
                    const diff_s = diff / 1000;
                    const diff_i = parseInt(diff_s.toString(), 10);
                    const diff_min = parseInt((diff_i / 60).toFixed(0), 10);

                    const updated_msg = this.Notifications.DEMO_MODE_START;
                    if (diff_min) {
                        if (diff_i % 60 === 0) {
                            if (diff_i <= 239) {
                                updated_msg.replace('**', `${demo_mode_min - diff_min}`);
                                this._notification.info(updated_msg);
                            } else if (diff_i >= 240 && diff_i <= 299) {
                                updated_msg.replace('**', `${demo_mode_min - diff_min}`);
                                this._notification.info(updated_msg);
                            } else if (diff_i >= 300) {
                                this._apiService.logoutUser();
                                clearInterval(this.demo_interval);
                            }
                        }
                    }
                }, 10000);
            },
            (errors) => {
                console.log(errors);
            }
        );
    }

    resetPassword() {
        if (this.emailResetDisabled) {
            return;
        }
        const email = this.passwordResetForm.value.email;
        this._apiService.resetPassword(email).subscribe(
            (res) => {
                this._notification.success(this.Notifications.RESET_SUCCESS);
            },
            (error) => {
                this._notification.error(this.Notifications.RESET_ERROR);
            }
        );
    }

    private setupForms(): void {
        this.loginForm.valueChanges.subscribe((values) => {
            this.email = values.email;
            if (this.email.length >= 1) {
                this.validateLoginInput('email');
            }
            this.password = values.password;
            if (this.password.length >= 1) {
                this.validateLoginInput('password');
            }
        });

        this.passwordResetForm.valueChanges.subscribe((values) => {
            this.emailreset = values.email;
            if (this.emailreset.length >= 1) {
                this.validatePasswortResetInput();
            }
        });
    }

    private validateLoginInput(type: 'email' | 'password'): void {
        let email_valid;
        let password_valid;
        if (this.email.includes('debug')) {
            email_valid = true;
            password_valid = this.password.length >= 5;
        } else {
            email_valid = LoginComponent.validateEmailFormat(this.email);
            password_valid = this.password.length >= 6;
        }

        if (type === 'email') {
            this.displayEmailError = !email_valid;
        }
        if (type === 'password') {
            this.displayPasswordError = !password_valid;
        }

        this.loginDisabled = !(email_valid && password_valid);
    }

    private validatePasswortResetInput(): void {
        const email_valid = LoginComponent.validateEmailFormat(this.emailreset);
        this.displayEmailResetError = !email_valid;
        this.emailResetDisabled = !email_valid;
    }

}

export enum LoginState {
    INITIAL, LOGIN_SELECT, LOGIN, PASSWORD_RESET,
}
