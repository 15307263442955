import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, flatMap, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {Url} from '../lib/Url';
import * as moment from 'moment';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class HappyHourService extends BaseService {
    // onConsumptionUpdate = new Subject<any>();

    private updateRate = 10000;
    private timerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
    }

    getSchedule(year: number, month: number): Observable<any> {
        const url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.happyHour.schedule);
        url.push(year.toString());
        url.push(month.toString());

        return this.http.get(
            url.toString(),
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((e) => this.handleError(e))
        );
    }

    /**
     * Returns whether the user participates in the HappyHour.
     */
    getParticipation(): Observable<any> {
        const url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.happyHour.participation);

        return this.http.get(
            url.toString(),
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((e) => {
                console.log(e);
                return throwError(e);
            })
        );
    }

    /**
     * Returns the consumption for a specific timefram
     * @param fct
     * @param first
     * @param second
     */
    getConsumptionFor(fct: 'day' | 'week' | 'month' | 'year', first: string, second: string): Observable<any> {
        const url = new Url(null);
        url.push(this.API_BASE_URL);

        switch (fct) {
            case 'day':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;
            case 'month':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.months);
                break;
            case 'year':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.years);
                break;
            case 'week':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;
        }
        url.push(first.toString());
        url.push(second.toString());

        return this.http.get(
            url.toString(),
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: any) => {
                    if (fct === 'week') {
                        if ('data' in res) {
                            const extracted_data = {};
                            const data = res['data'];
                            for (const element of data) {
                                const week = moment(element.timestamp).week();
                                if (extracted_data[week] === null || extracted_data[week] === undefined) {
                                    extracted_data[week] = {value: 0, timestamp: element.timestamp};
                                }
                                extracted_data[week].value += element.measured;
                            }
                            const final_data = [];
                            for (const key of Object.keys(extracted_data)) {
                                const obj = extracted_data[key];
                                final_data.push({
                                    timestamp: obj.timestamp,
                                    measured: obj.value
                                });
                            }
                            return final_data;
                        }
                        return res['data'];
                    } else {
                        return res['data'];
                    }
                }
            ),
            catchError((e) => this.handleError(e))
        );
    }
}
