import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {LiveDataService} from '../../../services/live-data.service';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {ConsumptionAlertChartComponent}
    from '../../../charts/consumption-alert-chart/consumption-alert-chart.component';
import {flatMap, mergeMap, tap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'app-consumption-alert-details',
    templateUrl: './consumption-alert-detail.component.html',
    styleUrls: ['consumption-alert-detail.component.scss'],
    viewProviders: [ApiService]
})
export class ConsumptionAlertDetailsComponent extends BasePopover implements OnInit, OnDestroy {

    refresh: any = [];
    infoVisible = false;

    @ViewChild('consumptionAlertChart', {static: true})
    consumptionAlertChart: ConsumptionAlertChartComponent;
    private currentMaxValue = 0;

    constructor(private analytics: TrackAnalyticsService,
                private mockData: MockDataService,
                private liveData: LiveDataService,
                private homestate: HomestateService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef) {
        super(popoverRef);
    }

    /**
     * Widget Verbrauchsalarm ist aktuell deaktiviert und ohne Funktion
     */
    ngOnInit() {
        if (this.application.isDemoMode()) {
            const s = this.mockData.onCurrentConsumptionValue.subscribe(
                (res: { power: number, timestamp: number }) => this.updateComparison(res.power)
            );
            this.addSub(s);
            this.getHomeStateMock();
            this.getConsumptionMock();
            return;
        }

        this.initializeLiveValueUpdate();
        this.initializeHomeStateUpdate();
    }

    private initializeLiveValueUpdate(): void {
        this.liveData.startConsumptionAlertLiveUpdate();
        const liveS = this.liveData.onConsumptionAlertLiveConsumptionReceived.pipe(
            flatMap((values) => !values ? throwError({msg: 'initial'}) : of(values)),
            mergeMap((res: any) => {
                if (!('results' in res)) {
                    return throwError(null);
                }
                return of(res.results);
            }),
            mergeMap((data: any) => {
                if (data.length > 0) {
                    const start = moment().subtract(24, 'hours').toDate();
                    const end = moment().toDate();
                    const filtered = data.filter((element) => {
                        const ts = moment(element.timestamp).toDate();
                        return ts >= start && ts <= end;
                    });
                    return of(filtered);
                }
            })
        ).subscribe(
            (dataset) => {
                this.consumptionAlertChart.showLoadingState(false);
                this.updateChartWithData(dataset);
            },
            (error) => {
                console.log('here');
                this.consumptionAlertChart.showLoadingState();
            });
        this.addSub(liveS);
    }

    private initializeHomeStateUpdate(): void {
        this.homestate.startLiveUpdate();
        const homeS = this.homestate.onHomestateStatus.pipe(
            mergeMap((status) => {
                return this.homestate.getConfig().pipe(
                    flatMap((config) => {
                        return of({config, status});
                    })
                );
            })
        ).subscribe(
            (res) => {
                this.currentMaxValue = res.config.thresholds.last();
                this.consumptionAlertChart.updateMaxValueLine(this.currentMaxValue, false);
            }
        );
        this.addSub(homeS);
    }


    private getHomeStateMock(): void {
        const s = this.mockData.getHomeStateConfig().subscribe((res) => {
            if (res.status === 'ok') {
                this.handleHomeStateConfig(res.data);
            }
        });
        this.addSub(s);
    }

    private updateChartWithData(data): void {
        this.consumptionAlertChart.showLoadingState(false);
        this.consumptionAlertChart.addNewSeries(data, 'power', 1, 0);
    }

    private handleHomeStateConfig(config: any): void {
        try {
            if (config.thresholds.length < 1) {
                // this.powerText = `${0} Watt`;
                // this.powerValue = 0;
            } else {
                // this.powerText = `${Math.round(config.thresholds.reduce((a, b) => Math.max(a, b))).toLocaleString('de')} Watt`;
                // this.powerValue = Math.round(config.thresholds.reduce((a, b) => Math.max(a, b)));
            }
            // this.showPowerInfo = true;
        } catch (e) {
            console.log('Error', e);
        }
    }

    private updateComparison(current_value): void {
        // if (this.powerText) {
        //     this.currentZone = current_value < this.powerValue ? 'lower' : 'higher';
        // }
    }

    private getConsumptionMock(): void {
        const s = this.mockData.getLiveData(60 * 12, 4, 0, 60 * 30).subscribe(
            (data: any) => {
                this.consumptionAlertChart.showLoadingState(false);
                this.consumptionAlertChart.addNewSeries(data, 'power', 1, 0);
                const sorted = data.slice().sort((a, b) => b.power - a.power);
                this.consumptionAlertChart.updateMaxValueLine(sorted[0].power, true);
                s.unsubscribe();
            },
            () => {
                this.consumptionAlertChart.showLoadingState();
            }
        );
    }

}
