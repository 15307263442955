import {Component, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {NilmService} from '../../../services/nilm.service';
import {DisaggregationService} from '../../../services/disaggregation.service';
import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import {AppliancesDiagramCallout, translateAppliance} from '../appliances.utils';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';


@Component({
    selector: 'app-appliances-details',
    templateUrl: './appliances-detail.component.html',
    styleUrls: ['appliances-detail.component.scss'],
    viewProviders: []
})

export class AppliancesDetailsComponent extends BasePopover implements OnInit {
    translateAppliance = translateAppliance;

    months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    date = '';

    colors = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];
    color = 0;

    tempdata: any = [];

    mode = 1;
    offset = 0;
    month = 0;

    disabled = true;

    chart: any = {};

    appliances = [];

    showConsumers = true;
    nilmdata: any = null;

    infoVisible = false;

    hideCallout = true;
    defaultCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', showBoeppel: false, value: ''
    };
    currentCallout: AppliancesDiagramCallout = {
        image: '', color: '', label: '', showBoeppel: false, value: ''
    };

    constructor(private analytics: TrackAnalyticsService,
                private _apiService: ApiService,
                private _mockData: MockDataService,
                private disaggregation: DisaggregationService,
                private nilm: NilmService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef) {
        super(popoverRef);
    }

    ngOnInit() {
        this.date = this.months[new Date().getMonth()] + ' ' + new Date().getFullYear();
        this.initializeChart();

        if (this.application.isDemoMode()) {
            this.getMockElectricalAppliances();
            this.getMockNilmStatus();
            return;
        }

        this.getElectricalAppliances();
        this.getNilmStatus();
    }

    /**
     * Alle Verbraucher-Daten holen (Monatsübersicht)
     */
    getElectricalAppliances() {
        this.showConsumers = true;
        const s = this.disaggregation.getDisaggregationHistoryByOffset(this.offset).subscribe(
            (data: any) => {
                if (data) {
                    this.drawDiagram(data);
                }
            },
            () => {
                if (this.chart.ref) {
                    this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    this.hideCallout = true;
                }
                this.showConsumers = false;
                this.disabled = false;
            }
        );
        this.addSub(s);
    }

    private getMockElectricalAppliances(): void {
        this.showConsumers = true;
        const s = this._mockData.getElectricalAppliances(this.offset).subscribe(
            (data) => {
                if (data.data) {
                    if ('data' in data) {
                        this.drawDiagram(data.data);
                    }
                } else {
                    if (this.chart.ref) {
                        this.chart.ref.showLoading(
                            'Keine Auswertung für diesen Zeitraum verfügbar!'
                        );
                        this.hideCallout = true;
                    }
                    this.showConsumers = false;
                    this.disabled = false;
                }

            }
        );
        this.addSub(s);
    }

    private getMockNilmStatus(): void {
        const s = this._mockData.getNILMStatus().subscribe(
            (data) => {
                if ('data' in data) {
                    this.nilmdata = data.data;
                }
            }
        );
        this.addSub(s);
    }

    private getNilmStatus(): void {
        this.addSub(
            this.nilm.getStatus().subscribe(
                (res: any) => this.nilmdata = res,
                (error) => console.log(error)
            ));
    }


    /**
     * Alle Verbraucher-Daten holen (Jahresübersicht)
     */
    getElectricalAppliancesYear() {
        this.showConsumers = true;
        this.tempdata = [];

        for (const month of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
            const s = this.disaggregation.getDisaggregationHistoryForMonth(this.offset, month).subscribe(
                (res: any) => {
                    this.month++;
                    if (res) {
                        for (const appliances of Object.keys(res.electricity.used_budget.categories)) {

                            if (res.electricity.used_budget.categories[appliances].usage > 0) {
                                if (appliances in this.tempdata) {
                                    this.tempdata[appliances].push({
                                        usage: res.electricity.used_budget.categories[appliances].usage,
                                        cost: res.electricity.used_budget.categories[appliances].cost
                                    });
                                } else {
                                    this.tempdata[appliances] = [{
                                        usage: res.electricity.used_budget.categories[appliances].usage,
                                        cost: res.electricity.used_budget.categories[appliances].cost
                                    }];
                                }
                            }

                        }
                    }

                    this.updateChart();
                },
                () => {
                    this.month++;
                    this.updateChart();
                }
            );
            this.addSub(s);
        }
    }


    /**
     *
     */
    private getMockElectricalAppliancesYear(): void {
        for (const month of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
            const s = this._mockData.getElectricalAppliancesForMonth(month, this.offset).subscribe(
                (data) => {

                    if (data.data !== null && data.data !== undefined) {

                        ++this.month;
                        if ('data' in data) {
                            for (const appliance of Object.keys(data.data.electricity.used_budget.categories)) {

                                if (data.data.electricity.used_budget.categories[appliance].usage > 0) {
                                    if (appliance in this.tempdata) {
                                        this.tempdata[appliance].push({
                                            usage: data.data.electricity.used_budget.categories[appliance].usage,
                                            cost: data.data.electricity.used_budget.categories[appliance].cost
                                        });
                                    } else {
                                        this.tempdata[appliance] = [{
                                            usage: data.data.electricity.used_budget.categories[appliance].usage,
                                            cost: data.data.electricity.used_budget.categories[appliance].cost
                                        }];
                                    }
                                }

                            }
                        }
                    } else {
                        this.month++;
                        this.updateChart();
                    }

                    this.updateChart();
                }
            );
            this.addSub(s);
        }
    }


    /**
     * Modus wechseln (Monat/Jahr)
     *
     * @param mode
     */
    setMode(mode: number) {
        if (mode === this.mode) {
            return;
        }

        this.mode = mode;
        this.offset = 0;
        this.resetChart();
        this.triggerModeTrackingEvent();
    }

    positionForward() {
        if (!((this.offset > 0) && (!this.disabled))) {
            return;
        }
        this.offset--;
        this.resetChart();
    }

    positionBack() {
        if (this.disabled) {
            return;
        }
        this.offset++;
        this.resetChart();
    }

    /**
     * Diagramm zurücksetzen und Daten neu holen
     */
    resetChart() {
        this.updateDate();
        if (this.chart.ref) {
            this.chart.ref.showLoading();
            this.hideCallout = true;
        }

        this.chart.removeSeries(0);
        this.chart.addSeries({name: null, innerSize: '90%', data: []});

        this.disabled = true;
        this.appliances = [];

        if (this.mode === 2) {
            if (this.application.isDemoMode()) {
                this.getMockElectricalAppliancesYear();
                return;
            }

            this.getElectricalAppliancesYear();
        } else {
            if (this.application.isDemoMode()) {
                this.getMockElectricalAppliances();
                return;
            }

            this.getElectricalAppliances();
        }
    }

    /**
     * Diagramm aktualisieren
     */
    updateChart() {
        this.showConsumers = true;
        this.chart.removeSeries(0);

        if (this.month === 12) {
            const tempdata: any = [];

            for (const appliance of Object.keys(this.tempdata)) {
                let usage = 0;
                let cost = 0;

                for (const month of this.tempdata[appliance]) {
                    usage += month.usage;
                    cost += month.cost;
                }

                tempdata.push({name: appliance, usage, cost});
            }

            tempdata.sort((a, b) => b.usage - a.usage);

            const series = [];
            for (const appliance of tempdata) {
                const color = this.getColor();
                this.appliances.push({
                    name: appliance.name,
                    kwh: Math.round(appliance.usage).toLocaleString('de-DE'),
                    cost: appliance.cost.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR'
                    }),
                    color
                });
                series.push({
                    name: appliance.name,
                    y: Math.round(appliance.usage),
                    x: appliance.cost,
                    color
                });
            }

            this.chart.addSeries({name: 'Series', data: series, innerSize: '90%', type: 'pie'});

            this.showConsumers = this.appliances.length !== 0;

            if (this.appliances.length > 0) {
                this.chart.ref.hideLoading();
                this.hideCallout = false;
            } else {
                this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                this.hideCallout = true;
            }

            this.month = 0;
            this.color = 0;
            this.tempdata = [];
            this.disabled = false;
        }
    }

    /**
     * Datum zurücksetzen
     */
    updateDate() {
        switch (this.mode) {
            case 1: {
                const date = new Date();
                date.setMonth(date.getMonth() - this.offset, 1);
                this.date = this.months[date.getMonth()] + ' ' + date.getFullYear();
                break;
            }
            case 2: {
                const date = new Date();
                date.setFullYear(date.getFullYear() - this.offset);
                this.date = date.getFullYear().toString();
                break;
            }
        }
    }

    /**
     * Farbe für Verbraucher
     */
    getColor() {
        if (this.color < this.colors.length) {
            this.color++;
            return this.colors[this.color - 1];
        } else {
            return this.colors[this.colors.length - 1];
        }
    }

    /**
     * Draw
     * @param data
     */
    private drawDiagram(data): void {
        this.chart.removeSeries(0);
        for (const appliances of Object.keys(data.electricity.used_budget.categories)) {
            if (data.electricity.used_budget.categories[appliances].usage > 0) {
                this.tempdata.push({
                    name: appliances,
                    usage: data.electricity.used_budget.categories[appliances].usage,
                    cost: data.electricity.used_budget.categories[appliances].cost
                });
            }
        }

        this.tempdata.sort((a, b) => b.usage - a.usage);
        const series = [];
        for (const appliances of this.tempdata) {
            const color = this.getColor();
            this.appliances.push({
                name: appliances.name,
                kwh: Math.round(appliances.usage).toLocaleString('de-DE'),
                cost: appliances.cost.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}),
                color
            });
            series.push({
                name: appliances.name,
                y: Math.round(appliances.usage),
                x: appliances.cost,
                color,
                sliced: true
            });
        }

        this.chart.addSeries({name: 'Series', data: series, type: 'pie'});
        if (this.chart.ref) {
            this.chart.ref.hideLoading();
            this.hideCallout = false;
        }

        this.color = 0;
        this.tempdata = [];
        this.disabled = false;

        this.showConsumers = this.appliances.length !== 0;
    }

    private determineCalloutData(chartRef): AppliancesDiagramCallout {
        console.log('ref', chartRef);
        const pointName = chartRef.point.name.toLowerCase();
        const value = Math.floor(chartRef.y);
        const price = chartRef.point.options.x;
        const callout: AppliancesDiagramCallout = {
            image: `url(/assets/img/graphics/appliances/${pointName}.svg)`,
            color: chartRef.point.color,
            label: `${chartRef.y} kWh`,
            value: `${price} €`,
            showBoeppel: false
        };

        // NILM
        if (pointName !== 'other') {
            if (pointName === 'laundry' || pointName === 'refrigeration' || pointName === 'entertainment' || pointName === 'cooking') {
                const profile_complete = NilmService.determineProfileCompleteness(pointName, this.nilmdata);
                if (!profile_complete) {
                    callout.showBoeppel = true;
                }
            }
        }

        return callout;
    }

    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0],
                events: {
                    render(event) {
                        if ('series' in event.target) {
                            if (event.target['series']['length'] > 0) {
                                const points = event.target['series'][0]['points'] as any[];
                                const sorted = points.sort((a, b) =>
                                    b.percentage - a.percentage);
                                if (!sorted[0]) {
                                    return;
                                }
                                const name = translateAppliance(sorted[0].name);
                                const percentage = Math.floor(sorted[0].percentage).toString();
                                const value = Math.floor(sorted[0].y);
                                self.defaultCallout.color = sorted[0].color;
                                self.defaultCallout.image = `url(/assets/img/graphics/appliances/${sorted[0].name.toLowerCase()}.svg)`;
                                self.defaultCallout.label = `${value} kWh`;
                                self.defaultCallout.value = `${sorted[0].x} €`;
                                self.currentCallout = self.defaultCallout;
                            }
                        }
                    }
                }
            },
            title: {text: null},
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner(boxWidth: number, boxHeight: number) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter() {
                    self.currentCallout = self.determineCalloutData(this);
                    return '';
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter() {
                            return `<div class="label"> ${Math.round(this.percentage)} %</div>`;
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 0,
                        connectorColor: 'white',
                        softConnector: false,
                        style: {
                            fontSize: '20px',
                            fontFamily: 'EONBrixSans, sans-serif',
                            color: '#39393a'
                        }
                    },
                    innerSize: '100%',
                    borderWidth: 10,
                    borderColor: null,
                    slicedOffset: 0,
                    startAngle: -180,
                    states: {hover: {brightness: 0}},
                    point: {
                        events: {
                            mouseOut() {
                                self.currentCallout = self.defaultCallout;
                            },
                        }
                    }
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        });

    }

    public determineCompletenessOfApplianceWrapper(el: any): boolean {
        return NilmService.determineCompletenessOfAppliance(el);
    }

    private triggerModeTrackingEvent(): void {
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Meine Geräte - ' + (this.mode === 2 ? 'Jahresübersicht' : 'Monatsübersicht') +
                    '; previous_screen: Meine Geräte - ' + (this.mode === 2 ? 'Monatsübersicht' : 'Jahresübersicht')
            }
        });
    }
}
