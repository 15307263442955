import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {LiveDataService} from '../../../services/live-data.service';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {ConsumptionAlertChartComponent} from '../../../charts/consumption-alert-chart/consumption-alert-chart.component';
import {of, throwError} from 'rxjs';
import {flatMap, mergeMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';

@Component({
    selector: 'app-consumption-alert-tile',
    templateUrl: './consumption-alert-tile.component.html',
    styleUrls: ['./consumption-alert-tile.component.scss'],
    providers: [Globals]
})

export class ConsumptionAlertTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.CONSUMPTION_ALERT;

    currentZone: 'lower' | 'higher' = 'lower';
    showPowerInfo = false;

    powerText = '';
    private powerValue: number = null;

    @ViewChild('consumptionAlertChartComponent', {static: true})
    alertChart: ConsumptionAlertChartComponent;

    currentMaxValue = 0;

    constructor(private _globals: Globals,
                private mockData: MockDataService,
                private liveData: LiveDataService,
                private homestate: HomestateService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService) {
        super();
    }

    /**
     * Widget Verbrauchsalarm ist aktuell deaktiviert und ohne Funktion
     */
    ngOnInit() {
        if (this.application.isDemoMode()) {
            const s = this.mockData.onCurrentConsumptionValue.subscribe(
                (res: { power: number, timestamp: number }) => {
                    this.updateComparison(res.power);
                }
            );
            this.addSub(s);
            this.getConsumptionMock();
            this.getHomeStateMock();
            return;
        }
        this.initializeLiveValueUpdate();
        this.initializeHomeStateUpdate();
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }


    private initializeLiveValueUpdate(): void {
        this.liveData.startConsumptionAlertLiveUpdate();
        const liveS = this.liveData.onConsumptionAlertLiveConsumptionReceived.pipe(
            // flatMap((values) => !values ? throwError({msg: 'initial'}) : of(values)),
            mergeMap((res: any) => {
                if (!res) {
                    return of(null);
                }
                if (!('results' in res)) {
                    return of(null);
                }
                return of(res.results);
            }),
            mergeMap((data: any) => {
                if (!data) {
                    return of(null);
                }
                if (data.length > 0) {
                    const start = moment().subtract(24, 'hours').toDate();
                    const end = moment().toDate();
                    const filtered = data.filter((element) => {
                        const ts = moment(element.timestamp).toDate();
                        return ts >= start && ts <= end;
                    });
                    return of(filtered);
                }
            })
        ).subscribe(
            (dataset) => {
                if (dataset) {
                    this.alertChart.showLoadingState(false);
                    this.updateChartWithData(dataset);
                }
            },
            (error) => {
                console.log('here');
                this.alertChart.showLoadingState();
            });
        this.addSub(liveS);
    }

    private initializeHomeStateUpdate(): void {
        this.homestate.startLiveUpdate();
        const homeS = this.homestate.onHomestateStatus.pipe(
            mergeMap((status) => {
                return this.homestate.getConfig().pipe(
                    flatMap((config) => {
                        return of({config, status});
                    })
                );
            })
        ).subscribe(
            (res) => {
                this.currentMaxValue = res.config.thresholds.last();
                this.alertChart.updateMaxValueLine(this.currentMaxValue, false);
            }
        );
        this.addSub(homeS);
    }

    private getConsumptionMock(): void {
        const s = this.mockData.getLiveData(60 * 12, 4, 0, 60 * 30).subscribe(
            (data: any) => {
                this.updateChartWithData(data);
                s.unsubscribe();
            },
            () => {
                this.alertChart.showLoadingState();
            }
        );
    }

    private updateChartWithData(data): void {
        this.alertChart.showLoadingState(false);
        this.alertChart.addNewSeries(data, 'power', 1, 0);
    }


    private getHomeStateMock(): void {
        const s = this.mockData.getHomeStateConfig().subscribe((res) => {
            if (res.status === 'ok') {
                this.handleHomeStateConfig(res.data);
            }
        });
        this.addSub(s);
    }

    private handleHomeStateConfig(config: any): void {
        try {
            if (config.thresholds.length < 1) {
                this.powerText = `${0} Watt`;
                this.powerValue = 0;
            } else {
                this.powerText = `${Math.round(config.thresholds.reduce((a, b) => Math.max(a, b))).toLocaleString('de')} Watt`;
                this.powerValue = Math.round(config.thresholds.reduce((a, b) => Math.max(a, b)));
            }
            this.showPowerInfo = true;
        } catch (e) {
            console.log('Error', e);
        }
    }

    private updateComparison(current_value): void {
        if (this.powerText) {
            this.currentZone = current_value < this.powerValue ? 'lower' : 'higher';
        }
    }

    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Consumption-Alert'
            }
        });
    }

    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Consumption-Details'
            }
        });
    }

}
