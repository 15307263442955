import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';

@Component({
    selector: 'app-count-selector',
    templateUrl: './count-selector.component.html',
    styleUrls: ['./count-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountSelectorComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountSelectorComponent),
            multi: true,
        }
    ]
})
export class CountSelectorComponent implements ControlValueAccessor, Validator {
    @Input() disabled = true;

    private _value = null;
    private cb: (_: any) => void;
    private cbBlurred: (_: any) => void;

    constructor() {
    }

    registerOnChange(fn: any): void {
        this.cb = fn;
    }

    registerOnTouched(fn: any): void {
        this.cbBlurred = fn;
    }

    writeValue(obj: any): void {
        console.log('writing value', obj);
        this._value = obj;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value === null) {
            return null;
        }
    }

    lowerValue(): void {
        if (this._value > 0) {
            --this._value;
            this._valueChangeCallback();
        }
    }

    raiseValue(): void {
        if (this._value === undefined) {
            this._value = 0;
        } else {
            ++this._value;
        }
        this._valueChangeCallback();
    }

    private _valueChangeCallback() {
        console.log(this._value);
        this.cb(this._value);
    }

    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }
}
