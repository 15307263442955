export enum HouseholdTexts {
    TITLE = 'Mein Haushalt',
    GREETING = 'Hallo ',
    SUBTITLE = ' hier haben Sie die Möglichkeit, Angaben zu Ihrem Haushalt zu machen. ',
    PROPERTY_QUESTION = 'In was für einem Objekt wohnen Sie?',
    SIZE_QUESTION = 'Wie groß ist Ihre Wohnfläche.',
    AGE_QUESTION = 'Wann wurde Ihr Wohnobjekt erbaut?',
    OCCUPANTS_QUESTION = 'Wie viele Personen leben in Ihrem Haushalt?',
    SPACE_HEATING_QUESTION = 'Wie wird ihr Objekt beheizt?',
    WATER_HEATING_QUESTION = 'Wie wird Warmasser hergestellt?',
    APPLIANCES_QUESTION = 'Welche Geräte sind in Ihrem Haushalt vorhanden?',
    BUTTON_SAVE = 'Angaben speichern',
}
