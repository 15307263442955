import {Injectable, OnDestroy} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, flatMap, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class InitializationService extends BaseService {

    private updateRate = 10000;

    private timerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private router: Router,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    get(forMeter = false): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.initialization;
        if (this.application.isDemoMode()) {
            url = 'assets/data/demo/' + constants.demo.files.initialization + '.json';
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            flatMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            flatMap((mapped) => {
                if (!forMeter) {
                    return of(mapped);
                }
                if (this.initializationResponseIsValid(mapped)) {
                    return of(mapped);
                }
                return throwError({
                    msg: 'Response validation for meter tile/detail ' +
                        'failed after initial mapping.'
                });
            }),
            catchError((error_response) => {
                console.log('init error', error_response);
                if ('error' in error_response) {
                    if ('error' in error_response.error) {
                        if ('code' in error_response.error.error) {
                            if (error_response.error.error.code === 125) {
                                this.router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                            }
                        }
                    }
                }

                if ('_body' in error_response) {
                    let jsonError;

                    try {
                        jsonError = JSON.parse(error_response._body);
                    } catch (e) {
                        return throwError(e);
                    }

                    if ('error' in jsonError) {
                        if ('code' in jsonError.error && jsonError.error.code === 125) {
                            // user not yet onboarded -> Zum Onboarding springen
                            console.log('not yet onboarded rerouting');
                            this.router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                        }
                    }
                }

                return throwError(error_response);
            })
        );
    }

    getMeterConnectionInfo(): Observable<string> {
        return this.get().pipe(
            flatMap((response: any) => {
                if (!('profile' in response)) {
                    return throwError(null);
                }
                if (!('meter_status_electricity' in response.profile)) {
                    return throwError(null);
                }
                console.log('response', response.profile.meter_status_electricity);
                return of(response.profile.meter_status_electricity);
            }),
            map((value) => {
                if (value === 0) {
                    return 'connected';
                } else if (value === 1) {
                    return 'disconnected';
                } else {
                    return 'pending';
                }
            })
        );
    }

    private initializationResponseIsValid(response: any): boolean {
        if ('profile' in response) {
            if ('meter_status_electricity' in response.profile) {
                return true;
            }
        }
        return false;
    }
}
