import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {ApplicationService} from './application.service';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseService {

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private useTestData = true;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    getAttributes(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.profile.attributes;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.profile_attributes}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }

    setAttributes(config: any): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.profile.attributes;

        return this.http.post(url, config,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }

    getContract(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.profile.this;
        if (this.application.isDemoMode()) {
            url = 'assets/data/demo/' + constants.demo.files.profile + '.json';
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }
}
