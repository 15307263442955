import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

export const objectIsEmpty = (obj): boolean => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};


export const createTimeout = (duration: number): Observable<void> => {
    return of(null).pipe(delay(duration));
};

export const getBrowserName = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any> window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any> window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
};
