import {Injectable} from '@angular/core';
import {constants} from '../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    setApplicationState(state: string): void {
        localStorage.setItem(constants.application.storage.keys.app_mode, state);
    }

    enableDemoMode(current_time: Date): void {
        localStorage.setItem(constants.application.storage.keys.app_mode, constants.application.states.demo);
        localStorage.setItem(constants.application.storage.keys.app_expire, current_time.toString());
    }

    getAppMode(): string {
        return localStorage.getItem(constants.application.storage.keys.app_mode);
    }

    isLiveMode(): boolean {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.live;
    }

    isDemoMode(): boolean {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.demo;
    }

    isNoneMode(): boolean {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.none;
    }
}
