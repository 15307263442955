import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PowercheckerStateService {

    public stateChange = new BehaviorSubject<boolean>(false);

    constructor() {
    }

}
