import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApiService} from '../../services/api.service';
import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {BaseComponent} from '../../classes/base-component';
import {ContactTexts} from '../../shared/texts/contact.texts';

@Component({
    selector: 'iona-app',
    templateUrl: './contact.component.html',
    viewProviders: []
})

export class ContactComponent extends BaseComponent implements OnInit {
    readonly TEXTS = ContactTexts;

    readonly phone: string = '0800-8888863';
    readonly fax: string = '+4920112-20000';
    readonly email: string = 'kundenservice@iona-energy.com';

    constructor(private _titleService: Title,
                public _apiService: ApiService,
                private _userService: UserService,
                public application: ApplicationService) {
        super();
    }

    ngOnInit() {
        this._titleService.setTitle('Info | iONA');
    }

    openPhone(): void {
        window.open(`tel:${this.phone}`);
    }

    openFax(): void {
        window.open(`fax:${this.fax}`);
    }

    openMail(): void {

        window.open(`mailto:${this.email}`, '');
    }

}
