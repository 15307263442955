import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import * as moment from 'moment';
import {Popover} from '../popover/popover.service';
import {TariffDataEntryComponent} from '../tariff-data-entry/tariff-data-entry.component';
import {UserService} from '../../services/user.service';
import {Tariff} from '../../classes/user';

@Component({
    selector: 'app-tariff-history',
    templateUrl: './tariff-history.component.html',
    styleUrls: ['./tariff-history.component.scss']
})
export class TariffHistoryComponent implements OnInit {

    moment = moment;

    provider = '';
    tariffs: Tariff[] = [];

    dateDisplayFormat = 'DD.MM.YYYY';

    constructor(public popoverRef: PopoverRef,
                public popover: Popover,
                private _userService: UserService) {
        if (this.popoverRef) {
            this.setupBackdrop();
            const data = this.popoverRef.data;
            this.tariffs = data.tariffs.slice().reverse();
            this.provider = data.provider;
        }
    }

    ngOnInit() {
    }

    determineStartDate(tariff): string {
        return moment(tariff.dateStart).format(this.dateDisplayFormat);
    }

    determineEndDate(tariff: Tariff, i: number): string {
        if (tariff.dateEnd) {
            return moment(tariff.dateEnd).format(this.dateDisplayFormat);
        }

        if (new Date(tariff.dateStart) >= new Date()) {
            return '-';
        }

        return '-';
        // return moment().format(this.dateDisplayFormat);
    }

    openEditOverlay(t: Tariff, i: number): void {
        const s = this.popover.open({
            content: TariffDataEntryComponent,
            data: {
                tariffs: this.tariffs,
                edit_mode: true,
                edit_data: t,
                edit_idx: i
            },
            hasBackdrop: true,
            position: 'absolute',
            placement: 'center center',
        }).afterClosed$.subscribe((res) => {
            if (!res.data) {
                console.log('Something went wrong here');
                return;
            }
            const mode = res.data['mode'];
            if (mode === 'delete') {
                this.handleTariffDeletion(res.data['data']);
            }
            if (mode === 'update') {
                this.handleTariffEdit(res.data['data'], i);
            }
            s.unsubscribe();
        });
    }

    close(data: any): void {
        this.popoverRef.close(data);
    }

    determineWorkingPrice(price): string {
        const temp = price * 100;
        if (temp < 100) {
            return `${Math.round(temp)} ct/kWh`;
        }
        return `${price} €/kWh`;
    }

    private handleTariffDeletion(idx: number): void {
        this._userService.removeTariff(idx);
        this.tariffs = this._userService.getTariffInfo().reverse();
        if (this.tariffs) {
            this.close(null);
        }
    }

    private handleTariffEdit(data, editIdx: number): void {
        if (data) {
            const d = data as any;
            d.dateEnd = null;
        }
        this.tariffs[editIdx].name = data.name;
        this.tariffs[editIdx].basePrice = data.basePrice;
        this.tariffs[editIdx].workPrice = data.workPrice;
        this._userService.updateTariffs(this.tariffs.reverse());
        this.tariffs = this._userService.getTariffInfo().reverse();
    }

    private setupBackdrop(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
    }

}
