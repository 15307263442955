import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-value-entry-popover',
    templateUrl: './value-entry-popover.component.html',
    styleUrls: ['./value-entry-popover.component.scss']
})
export class ValueEntryPopoverComponent implements OnInit {
    title = '';
    text = '';
    type = 'text';
    positive = '';
    negative = '';
    turquoise = false;

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    close(value: boolean | any = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
        this.title = this.popoverRef.data.title;
        this.text = this.popoverRef.data.text;
        this.positive = this.popoverRef.data.positive;
        this.turquoise = this.popoverRef.data.turquoise;
        if (this.popoverRef.data.type) {
            this.type = this.popoverRef.data.type;
        }
        if (this.popoverRef.data.negative) {
            this.negative = this.popoverRef.data.negative;
        }
    }

}
