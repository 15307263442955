import {Component, OnInit, OnDestroy} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {getDate, getDayName} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';

@Component({
    selector: 'app-today-tile',
    templateUrl: './today-tile.component.html',
    styleUrls: ['./today-tile.component.scss'],
    viewProviders: [],
    providers: [Globals]
})

export class TodayTileComponent extends BaseComponent implements OnInit, OnDestroy {
    private readonly type: TILE_TYPE = TILE_TYPE.TODAY;

    refresh = null;

    week: any = {
        dayname: null,
        date: null
    };

    today: any = {
        kwh: 0,
        costs: '0,00'
    };

    history: any = {
        kwh: 0,
        costs: '0,00'
    };

    trend: any = {
        direction: 0,
        percent: 0,
        scale: {
            left: 1,
            right: 1
        },
    };

    leftState = 'inactive';
    rightState = 'inactive';

    showDiagrams = true;

    constructor(private globals: Globals,
                private mockDataService: MockDataService,
                private electricityService: ElectricityService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService) {
        super();
    }

    ngOnInit() {
        this.week = {
            dayname: getDayName(7),
            date: getDayName(7)
        };

        if (this.application.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.initializeApiConnection();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.refresh) {
            clearInterval(this.refresh);
        }
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    extractRealValue(value: string): string {
        return value.split(',')[0] + ',';
    }

    extractDecimalValue(value: string): string {
        return value.split(',')[1];
    }

    /**
     * Initializes the API / Mock data connections
     */
    private initializeMockData(): void {
        this.getMockConsumption();

        this.refresh = setInterval(() => {
            this.week = {
                dayname: getDayName(7),
                date: getDate(7)
            };
            this.getMockConsumption();
        }, 10000);
        return;
    }

    /**
     * Initializes connection to live API
     */
    private initializeApiConnection(): void {
        this.electricityService.startLiveDaysUpdate();
        const conS = this.electricityService.onConsumptionDaysUpdate.subscribe(
            (res) => {
                this.week = {
                    dayname: getDayName(7),
                    date: getDate(7)
                };
                if (res) {
                    this.showDiagrams = true;

                    let costsFormated = '';
                    // if (typeof data.data[0].cost_measured != 'undefined') {
                    if (res[0].cost_measured !== null || res[0].cost_measured !== undefined) {
                        costsFormated = res[0].cost_measured.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });
                    }

                    this.today = {
                        kwh: (res[0].measured / 1000).toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        costs: costsFormated
                    };


                    this.getTrend(res[0].measured);
                }
            }
        );
        this.addSub(conS);
    }

    /**
     * Verbrauchs-Daten von API holen
     */
    getConsumption() {
        this.electricityService.getConsumptionForOffset(0).subscribe(
            (res: any) => {
                if (res) {
                    this.showDiagrams = true;

                    let costsFormated = '';
                    // if (typeof data.data[0].cost_measured != 'undefined') {
                    if (res[0].cost_measured !== null || res[0].cost_measured !== undefined) {
                        costsFormated = res[0].cost_measured.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });
                    }

                    this.today = {
                        kwh: (res[0].measured / 1000).toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        costs: costsFormated
                    };
                    this.getTrend(res[0].measured);
                } else {
                    this.showDiagrams = false;
                }
            },
            () => {
                this.showDiagrams = false;
            }
        );
    }


    /**
     * Gather mock consumption-alert data
     */
    public getMockConsumption(): void {
        const s = this.mockDataService.getDayConsumption(0).subscribe(
            (data) => {
                if ('data' in data) {
                    // console.log('detail data', data);
                    let consumption: number = 0;
                    let costs: number = 0;

                    this.today.hours = [];

                    for (let con of data.data) {

                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;

                            this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }),
                                costs: con.cost_measured.toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            });
                        }

                    }

                    this.today.hours.reverse();

                    this.today.kwh = (consumption / 1000).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                    this.today.costs = costs.toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });

                    // this.getTrend(consumption-alert);
                    this.getMockTrend(consumption);
                }
                s.unsubscribe();
            }
        );
    }

    /**
     * Trend berechnen
     *
     * @param today
     */
    private getTrend(today) {
        const s = this.electricityService.getConsumptionForDay(7).subscribe(
            (res: any) => {
                if (res) {
                    this.calculateTrend(res, today);
                }
                s.unsubscribe();
            }, error => s.unsubscribe()
        );
    }

    /**
     * Calculate the current trend based on mock data
     * @param today
     */
    private getMockTrend(today): void {
        const s = this.mockDataService.getDayConsumption(7).subscribe(
            (data) => {
                this.calculateTrend(data.data, today);
                s.unsubscribe();
            }
        );
    }

    /**
     * Calculates the current trend based on todays summed consumption-alert and the collected dataset from a week ago
     * @param data
     * @param today
     */
    private calculateTrend(data, today): void {
        let consumption = 0;
        let costs = 0;
        const now = new Date();

        for (const con of data) {
            let date = new Date(con.timestamp);
            if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                if (('measured' in con) && ('cost_measured' in con)) {
                    consumption += con.measured;
                    costs += con.cost_measured;
                }
            }
        }

        this.history = {
            kwh: (consumption / 1000).toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            costs: costs.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        };

        if (today > consumption) {
            let percent = Math.round(((today - consumption) / consumption) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: 1,
                percent: percent,
                scale: {
                    left: scale / 100,
                    right: 1
                },
            };
        } else if (today < consumption) {
            let percent = Math.round((1 - (today / consumption)) * 100);
            let scale = 0;

            if (percent > 80) {
                scale = 20;
            } else {
                scale = 100 - percent;
            }

            this.trend = {
                direction: -1,
                percent: percent,
                scale: {
                    left: 1,
                    right: scale / 100
                }
            };
        } else {
            this.trend = {
                direction: 0,
                percent: 0,
                scale: {
                    left: 1,
                    right: 1
                }
            };
        }

        /// determine the ui state here because everything else is gargles
        if (this.history.kwh === 0 && this.today.kwh === 0) {
            return;
        }

        this.rightState = 'higher';
        this.leftState = 'inactive';

        // if (this.history.kwh > this.today.kwh) {
        //     this.leftState = 'inactive';
        //     this.rightState = 'active';
        // } else if (this.history.kwh < this.today.kwh) {
        //     this.rightState = 'higher';
        //     this.leftState = 'inactive';
        // } else if (this.history.kwh === this.today.kwh) {
        //     this.rightState = 'active';
        //     this.leftState = 'active';
        // }
    }

    /**
     * Analytic stuff
     */
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Today',
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Today-Details'
            }
        });
    }
}
