import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ApiService} from './api.service';
import {constants} from '../shared/constants/constants';
import {ApplicationService} from './application.service';
import {MeterService} from './meter.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {VisibilityService} from './visibility.service';
import {HeartbeatService} from './heartbeat.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private api: ApiService,
                private router: Router,
                private application: ApplicationService,
                private meter: MeterService,
                private auth: AuthService,
                private visbility: VisibilityService,
                private heartbeat: HeartbeatService) {
    }

    canActivate(): Observable<any> {
        const state = this.application.getAppMode();
        if (state === 'demo') {
            if (!this.checkDemoModeValid()) {
                this.router.navigate(['/login']);
                return of(false);
            }
            return of(true);
        // }
        // else if (state === 'none') {
        //     console.log('is none');
        //     this.router.navigate(['/login']);
        //     return of(false);
        } else if (state === null || state === 'undefined') {
            this.router.navigate(['/login']);
            return of(false);
        }

        return this.meter.getStatus(false).pipe(
            map((res) => {
                if (res) {
                    this.visbility.init();
                    this.auth.startContinuousTokenRefresh();
                    return true;
                }
                return false;
            }),
            catchError((error) => {
                console.log('error in auth guard', error);
                return this.auth.refreshToken().pipe(
                    map((res) => {
                        console.log('refresh res: ', res);
                        if (res) {
                            this.auth.startContinuousTokenRefresh();
                            return res;
                        }
                    })
                );
            })
        );
    }

    private checkDemoModeValid(): boolean {
        const now = new Date();
        const expire = new Date(localStorage.getItem(constants.application.storage.keys.app_expire));
        return expire > now;
    }

}
