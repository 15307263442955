import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, flatMap, map} from 'rxjs/operators';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class NilmService extends BaseService {

    private updateRate = 10000;
    private timerSub: Subscription = null;

    private nilmData = null;

    static filterNilmDeviceModelCount(data: any, device_map): any {
        const result = {
            timeBasedAppliances: {
                dishWasher: null,
                dryer: null,
                oven: null,
                washingMachine: null
            }
        };
        for (const category of device_map) {
            const response_category = data[category.key];
            if (response_category === null || response_category === undefined) {
                continue;
            }
            for (const dev of category.elements) {
                const value = response_category[dev].models;
                if (value === null || value === undefined) {
                    continue;
                }
                result[category.key][dev] = value;
            }
        }
        return result;
    }

    static determineProfileCompleteness(pointName: string, nilmData: any): boolean {
        if (!nilmData) {
            console.log('No NILM data available, returnin.');
            return;
        }

        if (pointName === 'refrigeration') {
            if (nilmData.nonTimeBasedAppliances.refrigeration) {
                // if (this.nilmdata.nonTimeBasedAppliances.refrigeration !== null ||
                //     this.nilmdata.nonTimeBasedAppliances.refrigeration !== undefined) {
                const el = nilmData.nonTimeBasedAppliances.refrigeration;
                return NilmService.determineCompletenessOfAppliance(el);
            }
        } else if (pointName === 'cooking') {
            const oven = nilmData.timeBasedAppliances.oven;
            // const hob = this.nilmdata.timeBasedAppliances.hob;
            const profiles = [oven];
            if (profiles.every(element =>
                element !== false && element !== undefined && element !== null)) {
                const results = [];
                for (const element of profiles) {
                    results.push(NilmService.determineCompletenessOfAppliance(element));
                }
                return results.every(el => el !== false && el !== undefined && el !== null);
            }
        } else if (pointName === 'laundry') {
            const washingMachine = nilmData.timeBasedAppliances.washingMachine;
            const dryer = nilmData.timeBasedAppliances.dryer;
            const dishWasher = nilmData.timeBasedAppliances.dishWasher;
            const profiles = [washingMachine, dryer, dishWasher];
            if (profiles.every(element =>
                element !== false && element !== undefined && element !== null)) {
                const results = [];
                for (const element of profiles) {
                    results.push(NilmService.determineCompletenessOfAppliance(element));
                }
                return results.every(el => el !== false && el !== undefined && el !== null);
            }
        } else if (pointName === 'entertainment') {
            let el;
            // if (this.nilmdata.nonTimeBasedAppliances.entertainment !== null ||
            //     this.nilmdata.nonTimeBasedAppliances.entertainment !== undefined) {
            if (nilmData.nonTimeBasedAppliances.entertainment) {
                el = nilmData.nonTimeBasedAppliances.entertainment;
            }
            if (el.profileComplete || el.profileComplete === false && el.profileAdded === true) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    }

    static determineCompletenessOfAppliance(el: any): boolean {
        return el.profileComplete || el.profileComplete === false && el.profileAdded === true;
    }

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    getStatus(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.nilm.status;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.nilm_status}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            flatMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            flatMap((mapped) =>
                mapped ? of(mapped) : throwError({msg: 'Error after mapping response'})
            ),
            catchError((error: any) => this.handleError(error))
        );
    }


}
