export enum SettingsTexts {
    VIEW_TITLE = 'Meine Daten',
    PERSONAL_DATA_HEADING = 'Persönliche Daten',
    PERSONAL_DATA_REAL_NAME = 'Name',
    PERSONAL_DATA_USERNAME = 'Benutzername',
    CHANGE_EMAIL_BUTTON = 'E-Mail-Adresse ändern',
    CHANGE_PASSWORD_BUTTON = 'Passwort ändern',
    PROVIDER_HEADING = 'Lieferant',
    PROVIDER_PRODUCT = 'Produkt',
    PROVIDER_BASEPRICE = 'Grundpreis',
    PROVIDER_WORKPRICE = 'Arbeitspreis',
    NEW_TARIFF_BUTTON = 'Neuer Tarif',
TARIFF_HISTORY_BUTTON = 'Tarif Historie',
    EDIT_TARIFF_BUTTON = 'Tarif bearbeiten'
}
