import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../services/api.service';
import {ElectricityService} from '../../../services/electricity.service';
import {MONTHS} from '../../../lib/DateUtil';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {static_links} from '../../../shared/constants/web-resources.constants';
import {UserService} from '../../../services/user.service';

@Component({
    selector: 'app-finance-details',
    templateUrl: './finance-details.component.html',
    styleUrls: ['./finance-details.component.scss'],
    viewProviders: [ApiService]
})

export class FinanceDetailsComponent extends BasePopover implements OnInit {

    refresh = [];

    finance = {
        trend: 0,
        amount: 0,
        percent: 0,
        from: null,
        to: null
    };

    config = {
        notify: 0
    };

    data_available = false;

    lotti_config = {
        path: 'assets/anim/finance-detail-2.json',
        renderer: 'svg',
        autoplay: true,
        autoplaySegments: false,
        loop: true,
        name: 'Smart Meter'
    };

    private animation = null;

    infoVisible = false;

    constructor(private toastrService: ToastrService,
                private analytics: TrackAnalyticsService,
                private electricity: ElectricityService,
                protected popoverRef: PopoverRef,
                private userService: UserService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.getFinanceDate();
    }


    getFinanceDate(): void {
        const s = this.electricity.getBillPrediction().subscribe(
            (res: any) => {
                if (res) {
                    if (res.sum_of_prepayments > 12) {
                        this.data_available = true;
                        this.extractFinanceData(res);
                        s.unsubscribe();
                    }
                }
            }
        );

    }

    extractFinanceData(data: any): void {
        const estimated_cost = data.estimated_cost_billing_period;
        const sum_prepayments = data.sum_of_prepayments;

        const difference = estimated_cost - sum_prepayments;
        const percentage = 100 * (difference) / sum_prepayments;

        this.data_available = sum_prepayments > 12 && (percentage > -50 && percentage < 50);
        this.finance.percent = Math.floor(percentage);

        if (percentage > 10 && difference > 10) {
            this.finance.amount = Math.ceil(difference / 10) * 10;
            this.finance.trend = 1;
        } else if (percentage < -10 && difference < -10) {
            this.finance.amount = Math.abs(Math.floor(difference / 10) * 10);
            this.finance.trend = -1;
        } else {
            this.finance.trend = 0;
            this.finance.amount = 0;
        }

        const from: any = new Date(data.billing_period_from);
        const to: any = new Date(data.billing_period_until);

        this.finance.from = from.getDate() + '. ' + MONTHS[from.getMonth()] + ' ' + from.getFullYear();
        this.finance.to = to.getDate() + '. ' + MONTHS[to.getMonth()] + ' ' + to.getFullYear();
    }

    saveNotification() {
        if (this.config.notify > 0) {
            this.config.notify = 0;

            this.analytics.trackEvent({
                action: 'push_disable',
                properties: {
                    category: 'Push',
                    label: 'type: prediction; screen: Abschlags-Check'
                }
            });

            this.toastrService.warning('Benachrichtigung deaktiviert!');
        } else {
            this.config.notify = 1;

            this.analytics.trackEvent({
                action: 'push_enable',
                properties: {
                    category: 'Push',
                    label: 'type: prediction; screen: Abschlags-Check'
                }
            });

            this.toastrService.success('Benachrichtigung aktiviert!');
        }

        /*
        TODO: Einstellungen speichern
         */
        this.toastrService.info('Ihre Einstellung kann noch nicht übernommen werden!');
    }

    openLink(): void {
        const link = static_links.finance[this.userService.getActiveUserProvider()];
        window.open(link);
    }

}
