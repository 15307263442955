import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class DisaggregationService extends BaseService {

    private updateRate = 10000;

    private timerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }

        delete this.timerSub;
    }

    getDisaggregationHistoryByOffset(offset: number): Observable<any> {
        const date: any = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        const method: string = date.getFullYear() + '/' + (date.getMonth() + 1);
        return this.getDisaggregationHistory(method);
    }

    getDisaggregationHistoryForMonth(offset: number, month): Observable<any> {
        const date: any = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        const method: string = date.getFullYear() + '/' + (date.getMonth() + 1);
        return this.getDisaggregationHistory(method);
    }

    private getDisaggregationHistory(method: string): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method;
        // if(this.application.isDemoMode())

        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }
}
