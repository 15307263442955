import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApiService} from '../../services/api.service';
import {UserService} from '../../services/user.service';
import {getProviderIcon, getProviderIconClass, getProviderMenuName} from '../../lib/ProviderUtil';
import {ApplicationService} from '../../services/application.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';

@Component({
    selector: 'iona-app',
    templateUrl: './privacy.component.html',
    viewProviders: [ApiService]
})

export class PrivacyComponent extends BaseComponent implements OnInit, OnDestroy {

    refresh: any = [];

    provider = 0;
    providername = '';
    providerclass = '';
    providerMenuName: string = null;
    username: string = null;

    version = '0.0.0';
    changelog: any = [];
    logs = false;

    constructor(private _titleService: Title,
                public _apiService: ApiService,
                private _userService: UserService,
                private application: ApplicationService,
                private versionService: VersionService) {
        super();
    }

    ngOnInit() {
        this._titleService.setTitle('Datenschutz | iONA');

        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.providerclass = getProviderIconClass(this.providername);
        this.providerMenuName = getProviderMenuName(this.providername);

        this.addSub(this.versionService.getChangelog().subscribe((res) => {
            this.version = res[0].version;
            this.changelog = res;
        }));

        // this._apiService.checkToken();
        // this._apiService.checkMeterStatus();
        //
        // this.refresh = setInterval(() => {
        //     this._apiService.checkToken();
        // }, 10000);
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    showChangelog() {
        this.logs = true;
    }

    hideChangelog() {
        this.logs = false;
    }

}
