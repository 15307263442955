import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {OverlayService} from '../../overlay/overlay.service';
import {UserService} from '../../services/user.service';
import {forkJoin, interval, of, Subscription} from 'rxjs';
import {NilmOverlayResult} from '../../overlay/overlay.component';
import {constants} from '../../shared/constants/constants';
import {MvpConfig, MvpDetailConfig, MvpTileConfig} from '../../services/mvp.service';
import {ApplicationService} from '../../services/application.service';
import {MeterService} from '../../services/meter.service';
import {flatMap, map, mergeMap, switchMap} from 'rxjs/operators';
import {ProfileService} from '../../services/profile.service';
import {NilmService} from '../../services/nilm.service';
import {RegistrationService} from '../../services/registration.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {UserGroupService} from '../../services/user-group.service';
import {Popover} from '../../popovers/popover/popover.service';
import {AddTileComponent} from '../../popovers/add-tile/add-tile.component';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';
import {SortTilesComponent} from '../../popovers/sort-tiles/sort-tiles.component';
import {
    FirmwareUpdateAvailablePopover,
    FirmwareUpdatePopover,
    ManualPinEntryPopoverConfig,
    PinEntryPopoverConfig,
    PinFailedPopoverConfig, RadioLinkLostPopover,
    RemoveTilePopoverConfig
} from '../../popovers/static.popover.config';
import {VisibilityService} from '../../services/visibility.service';
import {MeterReaderStatus, OpticalReaderService} from '../../services/optical-reader.service';
import {BannerComponent} from '../../banner/banner.component';
import * as moment from 'moment';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {MeterStatuses} from '../../shared/constants/meter-statuses.constants';
import {Observable} from 'rxjs/Observable';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
    TILE_TYPE = TILE_TYPE;

    private readonly nilmRefreshInterval = 300000;
    private nilmRefreshSub: Subscription = null;

    userHasPlug = false;

    // MVP
    private userGroup = null;
    private mvpBaseUrl = null;
    mvpTileAvailable = false;
    mvpDetailConfig: MvpDetailConfig = null;
    mvpTileConfig: MvpTileConfig = null;
    mvpPhenotypeImage: string = null;
    mvpConfig: MvpConfig = null;

    __tiles: TileDef[] = [];
    tilesAvailable = false;

    private last_popup_shown: Date = null;

    private visibilitySub = null;
    private popoversOpen = false;
    private preventPinErrorPopover = false;

    @ViewChild('banner', {static: true}) banner: BannerComponent;

    constructor(private title: Title,
                private userService: UserService,
                private overlay_service: OverlayService,
                public application: ApplicationService,
                private meter: MeterService,
                private profile: ProfileService,
                private nilm: NilmService,
                private registration: RegistrationService,
                private versionService: VersionService,
                private userGroupService: UserGroupService,
                private popover: Popover,
                public tileService: TileService,
                private visibility: VisibilityService,
                private opticalReader: OpticalReaderService,
                private updateService: FirmwareUpdateService) {
        super();
    }

    ngOnInit() {
        // history.pushState({isDashboard: true}, 'Dashboard', '/#/');
        this.title.setTitle('Übersicht | iONA');

        // initialize the overlay to use the correct configuration
        this.setupOverlay();

        // this.initTiles();
        this.__tiles = this.tileService.getCurrentTiles();
        this.tilesAvailable = this.tileService.tilesAvailable();
        this.tileService.init();
        this.addSub(this.tileService.selectionChanged.subscribe((res: any) => {
            if (this.tileService) {
                this.tilesAvailable = this.tileService.tilesAvailable();
                this.__tiles = res;
            }
        }));

        // further inits
        this.setupVisibilityCallback();
        this.checkNilmStatus();
        // todo remove model registration call from here
        this.initPlug();
        this.initERNA();
        this.initPhaseChecker();
        this.initMvp();
        this.nilmRefreshSub = interval(this.nilmRefreshInterval).subscribe(
            (v) => this.checkNilmStatus()
        );
    }

    ngOnDestroy() {
        if (this.nilmRefreshSub) {
            this.nilmRefreshSub.unsubscribe();
            this.nilmRefreshSub = null;
        }

        if (this.visibilitySub) {
            this.visibilitySub.unsubscribe();
        }

        delete this.tileService;
    }

    private initMvp(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        const s = this.userGroupService.getUserGroup().subscribe((res) => {
            if (!('groups' in res)) {
                return;
            }
            if (res.groups.length < 1) {
                return;
            }

            const config = res.groups[0];
            const old_attributes = this.userService.getMvpAttributes();
            if (old_attributes) {
                if (old_attributes.user_group !== config.group_id) {
                    this.userService.setMvpTileConfig(null);
                }
            }

            this.userGroup = config.group_id;
            this.mvpBaseUrl = config.parameters.base_url;

            this.userService.updateMvpAttributes(res.mvp);

            // init mvp fct start here
            const last_config = this.userService.getLastMvpTileConfig();
            if (last_config) {
                this.handleMvpConfig(null, last_config);
            }

            let cfg = null;
            if (!this.userGroup) {
                console.log('no user group detected');
                return;
            }

            try {
                cfg = config.parameters;
            } catch (error) {
                console.log('error during mvp tile search');
                return;
            }

            if (config) {
                this.handleMvpConfig(config.parameters, last_config);
            } else {
                this.mvpTileAvailable = false;
                this.userService.setMvpTileConfig(null);
            }
        });
        this.addSub(s);
    }

    private setMvpConfigs(config: MvpConfig): void {
        this.mvpDetailConfig = {
            title: config.title,
            id: config.id,
            colors: config.colors,
            base_url: this.mvpBaseUrl
        };

        this.mvpTileConfig = {
            title: config.title,
            description: config.description,
            imageUrl: `${this.mvpBaseUrl}/${config.id}/res/tiles/l.png`,
            userGroup: this.userGroup
        };

        this.mvpConfig = config;
        this.mvpPhenotypeImage = `${this.mvpBaseUrl}/${this.mvpConfig.id}/res/phenotypes/l.png`;
        this.mvpTileAvailable = true;
    }

    private handleMvpConfig(config: MvpConfig, lastConfig: MvpConfig): void {
        // console.log('');
        // console.log('HANDLE-MVP-CONFIG');
        // NO STORED CONFIG BUT LIVE CONFIG
        if (!lastConfig && (config !== null && config !== undefined)) {
            // console.log('no stored config but live', config);
            this.setMvpConfigs(config);

            const tileAvailable = this.tileService.tileAvailable(TILE_TYPE.MVP);
            const tileSelected = this.tileService.tileSelected(TILE_TYPE.MVP);
            // override current position
            if (tileSelected) {
                // console.log('tile is selected');
                if (config.dashboardConfiguration.forceAdd) {
                    // console.log('forceadd is enabled');
                    this.tileService.enableMVPTileType(config.dashboardConfiguration.position, true, this.mvpTileConfig);
                    this.userService.setMvpTileConfig(config);
                }
                return;
            }

            // console.log('tile not on dashboard adding according to forceadd');
            this.tileService.enableMVPTileType(
                config.dashboardConfiguration.position,
                config.dashboardConfiguration.forceAdd,
                this.mvpTileConfig);

            this.userService.setMvpTileConfig(config);
            return;
        } // END NO STORED BUT LIVE CONFIGS

        // STORED BUT NOT LIVE
        if (!config && lastConfig) {
            // console.log('stored config but no current?');
            this.setMvpConfigs(lastConfig);
            const tileAvailable = this.tileService.tileAvailable(TILE_TYPE.MVP);
            const tileSelected = this.tileService.tileSelected(TILE_TYPE.MVP);
            // console.log('tile available:', tile_available, 'tile selected:', tile_selected);

            if (tileAvailable) {
                this.tileService.enableMVPTileType(lastConfig.dashboardConfiguration.position,
                    tileSelected, this.mvpTileConfig);
                // console.log('stored - tile there adding with false');
            } else {
                // console.log('stored - tile not there adding with true');
                this.tileService.enableMVPTileType(lastConfig.dashboardConfiguration.position,
                    true,
                    this.mvpTileConfig);
            }
            return;

        }

        // console.log('skipped all other cases: both configs there');
        this.setMvpConfigs(config);

        // add the tile if wanted
        const tile_available = this.tileService.tileAvailable(TILE_TYPE.MVP);
        const tile_selected = this.tileService.tileSelected(TILE_TYPE.MVP);

        // console.log('tile available:', tile_available, 'tile selected:', tile_selected);

        if (tile_available) {
            // if there is no last config but the curretn forceadd says true
            if (!lastConfig && config.dashboardConfiguration.forceAdd) {
                // console.log('no last config and new config forceadd');
                this.tileService.enableMVPTileType(lastConfig.dashboardConfiguration.position,
                    true,
                    this.mvpTileConfig);
                return;
            }
            // if the current version is newer than the old one and forceadd is three
            if ((lastConfig.dashboardConfiguration.version < config.dashboardConfiguration.version)
                && config.dashboardConfiguration.forceAdd) {
                // console.log('version update and forceadd');
                this.tileService.enableMVPTileType(lastConfig.dashboardConfiguration.position,
                    true,
                    this.mvpTileConfig);
                this.tileService.disableTileType(TILE_TYPE.MVP);
            }
        } else {
            console.log('not specified');
        }

    }

    private initPhaseChecker(): void {
        const o = of(this.userService.hasPhaseChecker()).pipe(
            flatMap((res) => {
                if (!res) {
                    return this.meter.onMeterStatus;
                }
                return of(null);
            })
        );
        let sub = null;
        sub = o.subscribe(
            (res) => {
                if (res) {
                    if ('lora_mode' in res) {
                        if (res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8) {
                            this.tileService.enableTileType(TILE_TYPE.PHASE_CHECKER);
                            this.tileService.setSelected(true, TILE_TYPE.PHASE_CHECKER);
                            // this.addTile('phase-checker', false);
                        }
                        this.userService.setPhaseCheckerAvailability(true);
                    }
                }
                if (sub) {
                    sub.unsubscribe();
                }
            },
        );
    }

    /**
     * Check the NILM status and display an overlay if something changed
     */
    private checkNilmStatus(): void {
        const profile$ = this.profile.getAttributes();
        const nilm$ = this.nilm.getStatus();
        const joinedSub = forkJoin([profile$, nilm$]).subscribe(
            (responses) => {
                const profile_data = responses[0];
                const nilm_data = responses[1];
                this.determineNewlyAddedAppliances(nilm_data, profile_data);
            },
            (error) => console.warn(error),
            () => joinedSub.unsubscribe()
        );
    }


    /**
     * Check the user has a plug or a box
     */
    private initPlug(): void {
        this.registration.getModel().subscribe(
            (result) => {
                this.handlePlugResponse(result);
            },
            (error) => {
                console.log('error', error);
            }
        );
    }

    /**
     * If the user has a plug, handle the response and act accordingly
     * @param response
     */
    private handlePlugResponse(response: any): void {
        const model = response.model_identifier;
        switch (model) {
            case constants.application.devices.plug:
                this.userService.updateUserDevice(constants.application.devices.plug);
                this.userHasPlug = true;
                break;
            case constants.application.devices.plug_optical:
                this.userService.updateUserDevice(constants.application.devices.plug_optical);
                this.userHasPlug = true;
                break;
            case constants.application.devices.box:
                this.userService.updateUserDevice(constants.application.devices.box);
                break;
            default:
                this.userService.updateUserDevice(constants.application.devices.box);
                break;
        }

        if (this.userHasPlug) {
            if (!this.tileService.tileAvailable(TILE_TYPE.POWER_CHECKER)) {
                this.tileService.enableTileType(TILE_TYPE.POWER_CHECKER);
                this.tileService.setSelected(true, TILE_TYPE.POWER_CHECKER);
            }
        }
    }

    private initERNA(): void {
        if (this.userService.isEDGUser()) {
            this.tileService.disableTileType(TILE_TYPE.APPLIANCES);
            this.tileService.disableTileType(TILE_TYPE.PHASE_CHECKER);
            this.tileService.disableTileType(TILE_TYPE.FINANCE);
            this.tileService.disableTileType(TILE_TYPE.CONSUMPTION_ALERT);
            this.opticalReader.startLiveUpdate();
            this.enableMeterStatusUpdates();
        }
    }

    private shouldTriggerTimeBasedOverlay(item: string, timeframe: any, time: number): boolean {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private shouldTriggerPinOverlay(): boolean {
        if (this.preventPinErrorPopover) {
            return false;
        }

        const storageItem = 'lastPinInfo';
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private shouldTriggerAvailableUpdateOverlay(): boolean {
        const storageItem = 'lastUpdateInfo';
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(24, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private handleStateIndependentValues(res: MeterReaderStatus): Observable<any> {
        if (res.mode === 'RT_INACTIVE') {
            if (res.battery_status > 0) {
                // this.banner.show(Banners.energySaver);
            } else {
                // this.banner.hide();
            }
        }

        return of(res);
    }

    private triggerPinFailedPopoverCycle(): Observable<any> {
        return of(this.shouldTriggerPinOverlay()).pipe(
            mergeMap((trigger) => {
                if (trigger) {
                    return this.popover.open(PinFailedPopoverConfig).afterClosed$.pipe(
                        switchMap((dialogData) => {
                            if (dialogData.data === null) {
                                this.popoversOpen = false;
                                return of(null);
                            }
                            if (dialogData.data) {
                                return this.popover.open(PinEntryPopoverConfig).afterClosed$;
                            }
                            return this.popover.open(ManualPinEntryPopoverConfig).afterClosed$;
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }

    private enableMeterStatusUpdates(): void {
        let previousStatus = '';
        this.opticalReader.onMeterReaderStatus.pipe(
            mergeMap((res: MeterReaderStatus) => {
                return this.handleStateIndependentValues(res);
            }),
            mergeMap((result: MeterReaderStatus) => {
                // if the update state has changed killit
                if (previousStatus === MeterStatuses.UPDATE_INSTALLING &&
                    result.status !== MeterStatuses.UPDATE_INSTALLING) {
                    this.updateService.onUpdateStateReceived.next(null);
                }
                previousStatus = result.status;

                let toReturn = of(null);
                switch (result.status) {
                    case MeterStatuses.PIN_FAILED:
                        if (!this.popoversOpen) {
                            toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                mergeMap(res => of({type: MeterStatuses.PIN_FAILED, res}))
                            );
                        }
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        this.updateService.onUpdateStateReceived.next(result.update_progress);
                        if (!this.popoversOpen) {
                            if (result.update_progress < 100) {
                                toReturn = this.popover.open(FirmwareUpdatePopover).afterClosed$
                                    .pipe(
                                        mergeMap(res => of({
                                            type: MeterStatuses.UPDATE_INSTALLING, res
                                        }))
                                    );
                                this.popoversOpen = true;
                            }
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (result.firmware_status === 'UPDATE_AVAILABLE') {
                            if (!this.shouldTriggerAvailableUpdateOverlay()) {
                                break;
                            }
                            if (this.popoversOpen) {
                                break;
                            }
                            toReturn = this.popover.open(FirmwareUpdateAvailablePopover).afterClosed$.pipe(
                                mergeMap(res => of({type: MeterStatuses.CONNECTED_WITH_METER, res}))
                            );
                            this.popoversOpen = true;
                        }
                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay(
                            'radioLinkLostInfo', 'hours', 24)) {
                            break;
                        }
                        if (this.popoversOpen) {
                            break;
                        }
                        toReturn = this.popover.open(RadioLinkLostPopover).afterClosed$.pipe(
                            mergeMap(res => of({type: MeterStatuses.RADIO_LINK_LOST, res}))
                        );
                        this.popoversOpen = true;
                        break;
                    default:
                        break;

                }
                return toReturn;
            }),
        ).subscribe((result) => {
            if (result) {
                if (result.type === MeterStatuses.PIN_FAILED) {
                    if (typeof result.res.data === 'string') {
                        const pin = parseInt(result.res.data, 10);
                        this.preventPinErrorPopover = true;
                        this.meter.startContinuousPinEntry(pin);
                        this.meter.onPinEntrySuccess.subscribe((res) => {
                            if (res) {
                                this.preventPinErrorPopover = false;
                            }
                        });
                    }
                    this.popoversOpen = false;
                } else if (result.type === MeterStatuses.UPDATE_INSTALLING) {

                } else if (result.type === MeterStatuses.CONNECTED_WITH_METER) {
                    if (result.res.data) {
                        this.updateService.startUpdate().subscribe(() => {
                        });
                    }
                }
                this.popoversOpen = false;
            }
        });

        return;
    }

    /**
     * Setup visibility change funcitonality
     */
    private setupVisibilityCallback(): void {
        if (!this.visibilitySub) {
            this.visibilitySub = this.visibility.onVisible.subscribe(() => {
                this.checkNilmStatus();
            });
        }
    }

    /**
     * Setup the legacy appearing overlay gargles
     */
    private setupOverlay(): void {
        this.overlay_service.onConfirm.subscribe(
            (result: NilmOverlayResult) => {
                this.userService.updateActiveUserNilmStatusForAppliance(result.tag, result.amount);
                const change = {Appliance: {}};
                change.Appliance[result.id] = result.amount;
                if (!this.application.isDemoMode()) {
                    this.profile.setAttributes(change).subscribe(
                        (res) => {
                            console.log('attribute set response', res);
                        }
                    );
                    // this._apiService.setProfile(change);
                }
            }
        );
    }

    /**
     * Determines which appliances were added since the last time
     */
    private determineNewlyAddedAppliances(response, profile_data): void {
        const old_nilm = this.userService.getActiveUserNilmStatus();
        if (old_nilm === null || old_nilm === undefined) {
            this.userService.updateActiveUserNilmStatus(response);
            return;
        }

        // setup nilm_devices filter
        const nilm_devices = [{
            key: 'timeBasedAppliances',
            elements: ['dishWasher', 'washingMachine', 'dryer', 'oven']
        }];

        const new_amounts = NilmService.filterNilmDeviceModelCount(response, nilm_devices);
        const old_amounts = NilmService.filterNilmDeviceModelCount(old_nilm, nilm_devices);

        for (const key in new_amounts) {
            if (!new_amounts.hasOwnProperty(key)) {
                continue;
            }

            const parent_old = old_amounts[key];
            const parent_new = new_amounts[key];

            for (const inner_key in parent_new) {
                if (parent_old[inner_key] === 0) {
                    // console.log('log', inner_key, parent_new[inner_key]);
                    // console.log('log', inner_key, response['timeBasedAppliances'][inner_key].profileComplete);
                    if (parent_new[inner_key] !== 0 &&
                        response['timeBasedAppliances'][inner_key].profileComplete === false) {
                        const config = {
                            title: 'iONA hat ein neues Gerät erkannt',
                            info: 'Wie viele # gibt es in Ihrem Haushalt?',
                            icon: null,
                            amount: -1,
                            tag: inner_key,
                            appliance: null
                        };
                        switch (inner_key) {
                            case 'washingMachine':
                                config.icon = 'A.11';
                                config.appliance = 'Waschmaschinen';
                                config.amount = profile_data.Appliances['A.11'];
                                break;
                            case 'dishWasher':
                                config.icon = 'A.10';
                                config.appliance = 'Spülmaschinen';
                                config.amount = profile_data.Appliances['A.10'];
                                break;
                            case 'dryer':
                                config.icon = 'A.12';
                                config.appliance = 'Trockner';
                                config.amount = profile_data.Appliances['A.12'];
                                break;
                            case 'oven':
                                config.icon = 'A.04';
                                config.appliance = 'Öfen';
                                config.amount = profile_data.Appliances['A.04'];
                                break;
                        }

                        if (document.visibilityState === 'visible') {
                            this.overlay_service.initialize(config);
                            this.overlay_service.showOverlay(true);
                            this.userService.updateActiveUserNilmStatusForAppliance(
                                inner_key, new_amounts.timeBasedAppliances[inner_key]
                            );
                        } else {
                            this.last_popup_shown = new Date();
                        }

                        return;
                    }
                }
            }
        }
    }

    /**
     * On Sort Dashboard tiles button clicked.
     * Opens an overlay on the rhs containing the list
     */
    onSortButtonClick() {
        const s = this.popover.open({
            content: SortTilesComponent,
            data: null,
            hasBackdrop: true,
            position: 'absolute',
            placement: 'end center'
        }).afterClosed$.subscribe(
            (res) => s.unsubscribe());
    }

    /**
     * On Add Dashboard tiles button clicked.
     * Opens an overlay containing an overview of all available tiles
     */
    onAddTileButtonClicked() {
        const s = this.popover.open({
            content: AddTileComponent,
            data: null,
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe(
            (res) => s.unsubscribe());
    }

    /**
     * Removes a chart from the dashboard.
     * Displays a confirmation dialog of type BinaryConfirmPopoverComponent
     * @param tile
     */
    removeTile(tile: TileDef) {
        this.popover.open(RemoveTilePopoverConfig).afterClosed$.pipe(
            map(res => res.data)
        ).subscribe((res) => {
            if (res) {
                this.tileService.setSelected(false, tile.type);
            }
        });
    }
}
