import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[slider]'
})
export class MovableDirective implements OnInit, OnChanges {

    @Input('value') value: number = 0;
    @Output('change') change = new EventEmitter<number>();


    private mouse_down: boolean = false;
    private touch_down: boolean = false;

    private elementSize: number = null;
    private elementOrigin: number = null;
    private containerWidth: number = null;

    constructor(private element: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    private initialize(): void {
        this.elementOrigin = this.element.nativeElement.getBoundingClientRect().x;
        this.elementSize = this.element.nativeElement.offsetWidth;
        this.containerWidth = this.element.nativeElement.parentNode.offsetWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initialize();
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        console.log('mousedown');
        this.mouse_down = true;
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.mouse_down) {
            const new_x = this.calcNewPosition(event.x, event.y);
            this.renderer.setStyle(this.element.nativeElement, 'left', `${new_x}px`);
        }
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        console.log('mouseup');
        this.mouse_down = false;
    }

    //
    // @HostListener('touchstart', ['$event'])
    // onTouchStart(event: TouchEvent) {
    //     // console.log(event);
    //     this.touch_down = true;
    // }
    //
    // @HostListener('touchmove', ['$event'])
    // onTouchMove(event: TouchEvent) {
    //     if (this.touch_down) {
    //         console.log('touchdown');
    //     }
    // }
    //
    // @HostListener('touchend', ['$event'])
    // onTouchEnd(event: TouchEvent) {
    //     this.touch_down = false;
    // }


    /**
     * Calculates the position after the element was dragged.
     * @param x
     * @param y
     */
    private calcNewPosition(x: number, y: number): number {
        const container_start = this.elementOrigin + (this.elementSize / 2);
        const container_end = this.elementOrigin + this.containerWidth - (this.elementSize / 2);

        const current_local_x = x - this.elementOrigin;
        if (x > container_start && x < container_end) {
            return current_local_x - (this.elementSize / 2);
        }
    }
}
