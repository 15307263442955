import {Component, OnInit} from '@angular/core';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {PopoverRef} from '../popover/popover-ref';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-update-popover',
    templateUrl: './update-popover.component.html',
    styleUrls: ['./update-popover.component.scss']
})
export class UpdatePopoverComponent implements OnInit {

    currentProgress = 0;
    private updateSub: Subscription = null;
     animationConfig = {
        path: 'assets/anim/esc.json',
        autoplay: true,
        name: 'ESC Ladeanimation',
        loop: true,
        renderer: 'canvas'
    };

    constructor(public popoverRef: PopoverRef,
                private updateService: FirmwareUpdateService) {
    }

    ngOnInit() {
        this.updateSub = this.updateService.onUpdateStateReceived.subscribe((value) => {
            this.currentProgress = value;
            if (this.currentProgress === null) {
                this.destroyUpdateSubscription();
                this.close();
            }
        });
    }

    private destroyUpdateSubscription(): void {
        if (this.updateSub) {
            this.updateSub.unsubscribe();
        }
    }

    private close(): void {
        this.popoverRef.close(null);
    }
}
