import {AfterViewInit, Component, ElementRef, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {of} from 'rxjs';
import {MvpDetailConfig} from '../../../services/mvp.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';

@Component({
    selector: 'app-mvp-details',
    templateUrl: './mvp-details.component.html',
    styleUrls: ['./mvp-details.component.scss']
})
export class MvpDetailsComponent extends BasePopover implements OnInit, OnChanges, AfterViewInit {

    config: MvpDetailConfig = null;
    detailsUrl: string = null;

    infoVisible = false;
    contentHeight = 0;

    @ViewChild('frame', {static: true}) iFrame: ElementRef<HTMLIFrameElement>;

    constructor(private renderer: Renderer2,
                protected popoverRef: PopoverRef) {
        super(popoverRef);
        this.config = this.popoverRef.data.config;
    }

    ngOnInit() {
        this.initialize();
        this.resizeIFrameOnLoad();
    }

    ngAfterViewInit() {
        this.contentHeight = window.innerHeight * 0.65;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initialize();
    }

    resizeIFrameOnLoad(): void {
        const frame = this.iFrame.nativeElement;
        this.renderer.listen(frame, 'onload', (evt) => {
            const scroll_height = frame.contentWindow.document.body.scrollHeight;
            this.renderer.setStyle(frame, 'height', `${scroll_height}px`);
        });
    }

    test(evt): void {
        // console.log('event, ', evt);
        // const foo = this.iFrame.nativeElement.contentWindow.document.body;
        // console.log('iframe', this.iFrame);
        // console.log('foo pl', foo);
        // console.log('foo hei', foo.style.height);
        // console.log('foo sch', foo.style.scrollHeight);
    }

    private initialize(): void {
        if (this.config.id) {
            const url = `${this.config.base_url}/${this.config.id}/detail/`;
            // console.log('url here', url);
            of({url}).subscribe(
                (res) => {
                    this.onUrlAvailable(res.url);
                }
            );
        }
    }

    private onUrlAvailable(url: string): void {
        const f = this.iFrame.nativeElement as HTMLIFrameElement;
        this.detailsUrl = url;
        f.src = url;
    }
}

