import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';

import {ApiService} from '../../services/api.service';
import {Globals} from '../../services/globals.service';
import {MockDataService} from '../../services/mock-data.service';

import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {InitializationService} from '../../services/initialization.service';
import {ProfileService} from '../../services/profile.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {HouseholdTexts} from '../../shared/texts/household.texts';

@Component({
    selector: 'app-household',
    templateUrl: './household.component.html',
    styleUrls: ['./household.component.scss'],
    viewProviders: [],
    providers: [Globals]
})

export class HouseholdComponent extends BaseComponent implements OnInit, OnDestroy {
    readonly TEXTS = HouseholdTexts;

    refresh: any = [];

    username: string = null;
    fullname: string = null;

    household: any = {
        Attributes: {
            PropertyType: null,
            PropertySize: null,
            PropertyAge: null,
            Occupants: null,
            SpaceHeating: null,
            WaterHeating: null,
            OvenHeating: null,
            GrillHeating: null,
            StoveHeating: null
        },
        Appliances: []
    };

    propertyType = {
        title: this.TEXTS.PROPERTY_QUESTION,
        selectedValue: null,
        items: [
            {title: 'freistehendes Haus', iconName: 'PT.01'},
            {title: 'Doppelhaushälfte', iconName: 'PT.02'},
            {title: 'Bungalow', iconName: 'PT.03'},
            {title: 'Reihenhaus', iconName: 'PT.04'},
            {title: 'Reiheneckhaus', iconName: 'PT.05'},
            {title: 'Wohnung', iconName: 'PT.06'},
        ]
    };

    propertySize = {
        title: this.TEXTS.SIZE_QUESTION,
        selectedValue: null,
        items: [
            {title: '1 Zimmer', iconName: 'PS.01'},
            {title: '2 Zimmer', iconName: 'PS.02'},
            {title: '3 Zimmer', iconName: 'PS.03'},
            {title: '4 Zimmer', iconName: 'PS.04'},
        ]
    };

    propertyAge = {
        title: this.TEXTS.AGE_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Vor 1919', iconName: 'PA.01'},
            {title: '1920 - 1975', iconName: 'PA.02'},
            {title: '1979 - 1999', iconName: 'PA.03'},
            {title: 'Nach 2000', iconName: 'PA.04'},
        ]
    };

    occupants = {
        title: this.TEXTS.OCCUPANTS_QUESTION,
        selectedValue: null,
        items: [
            {title: '1', iconName: 'OCC.01'},
            {title: '2', iconName: 'OCC.02'},
            {title: '3', iconName: 'OCC.03'},
            {title: '4', iconName: 'OCC.04'},
            {title: '5+', iconName: 'OCC.05'},
        ]
    };

    spaceHeating = {
        title: this.TEXTS.SPACE_HEATING_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Gasheizung', iconName: 'SH.01'},
            {title: 'Wärmespeicher', iconName: 'SH.02'},
            {title: 'Elektroheizung', iconName: 'SH.03'},
            {title: 'Wärmepumpe', iconName: 'SH.04'},
            {title: 'Ölheizung', iconName: 'SH.05'},
            {title: 'Solarzelle', iconName: 'SH.06'},
            {title: 'Andere', iconName: 'SH.07'},
        ]
    };

    waterHeating = {
        title: this.TEXTS.WATER_HEATING_QUESTION,
        selectedValue: null,
        items: [
            {title: 'Gasheizkessel', iconName: 'WH.01'},
            {title: 'Elektrischer Boiler', iconName: 'WH.02'},
            {title: 'Ölheizkessel', iconName: 'WH.03'},
            {title: 'Solartherme', iconName: 'WH.04'},
            {title: 'Andere', iconName: 'WH.05'},
        ]
    };

    appliances = {
        title: this.TEXTS.APPLIANCES_QUESTION,
        items: [
            {title: 'Kühlschrank', iconName: 'A.01', count: 0, selected: false},
            {title: 'Gefrierschrank', iconName: 'A.02', count: 0, selected: false},
            {title: 'Kühlschrank mit Gefrierfach', iconName: 'A.03', count: 0, selected: false},
            {title: 'Backofen', iconName: 'A.04', count: 0, selected: false},
            {title: 'Grill', iconName: 'A.05', count: 0, selected: false},
            {title: 'Herdplatten', iconName: 'A.06', count: 0, selected: false},
            {title: 'Mikrowelle', iconName: 'A.07', count: 0, selected: false},
            {title: 'Wasserkocher', iconName: 'A.08', count: 0, selected: false},
            {title: 'Toaster', iconName: 'A.09', count: 0, selected: false},
            {title: 'Spülmaschine', iconName: 'A.10', count: 0, selected: false},
            {title: 'Waschmaschine', iconName: 'A.11', count: 0, selected: false},
            {title: 'Trockner', iconName: 'A.12', count: 0, selected: false},
            {title: 'Bügeleisen', iconName: 'A.13', count: 0, selected: false},
            {title: 'TV', iconName: 'A.14', count: 0, selected: false},
            {title: 'DVD oder Bluray Player', iconName: 'A.15', count: 0, selected: false},
            {title: 'Digitale TV Box', iconName: 'A.16', count: 0, selected: false},
            {title: 'Spielkonsole', iconName: 'A.17', count: 0, selected: false},
            {title: 'Computer', iconName: 'A.18', count: 0, selected: false},
            {title: 'Tablet', iconName: 'A.19', count: 0, selected: false},
            {title: 'Durchlauferhitzer (Dusche)', iconName: 'A.20', count: 0, selected: false},
            {title: 'Elektroauto Ladesäule', iconName: 'A.21', count: 0, selected: false},
            {title: 'Pool', iconName: 'A.22', count: 0, selected: false},
            {title: 'Sauna', iconName: 'A.23', count: 0, selected: false},
        ]
    };

    state = 'none';

    constructor(private _titleService: Title,
                private _notification: ToastrService,
                public _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService,
                private _userService: UserService,
                public application: ApplicationService,
                private initialization: InitializationService,
                private profile: ProfileService,
                private versionService: VersionService,
                private analytics: TrackAnalyticsService) {
        super();
    }

    ngOnInit() {
        this._titleService.setTitle('Mein Haushalt | iONA');
        this.username = this._userService.getActiveUserName();

        this.initialize();
    }

    initialize(): void {
        this.addSub(this.initialization.get().subscribe((res) => {
            if (res) {
                if ('profile' in res) {
                    this.processContractData(res);
                }
            }
        }));

        this.addSub(this.profile.getAttributes().subscribe((res) => {
            if (res) {
                this.processProfileData(res);
            }
        }));
    }

    ngOnDestroy() {
        clearInterval(this.refresh);
    }

    determineAppliancesSelected(): boolean {
        for (const element of this.appliances.items) {
            if (element.selected) {
                return true;
            }
        }
        return false;
    }

    determineImagePath(element, currentItem): string {
        const path = `url(/assets/img/icons/household/inactive/${currentItem.iconName}.png)`;
        return path;
    }

    isActive(element, currentItem): boolean {
        return element.selectedValue === currentItem.iconName;
    }

    isActiveAppliance(element, currentItem): boolean {
        return currentItem.selected;
    }

    onCountChanged(item, value): void {
        // if (value === 0) {
        //     item.selected = false;
        // }
    }

    /**
     * neue Settings speichern
     */
    saveSettings() {
        let errors: any = [];

        // if ((this.household.Appliances['A.04'] > 0) && (this.household.Attributes.OvenHeating === 'OH.00')) {
        //     errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        // } else if ((this.household.Appliances['A.04'] === 0) && (this.household.Attributes.OvenHeating !== 'OH.00')) {
        //     errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        // }
        //
        // if ((this.household.Appliances['A.05'] > 0) && (this.household.Attributes.GrillHeating === 'GH.00')) {
        //     errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        // } else if ((this.household.Appliances['A.05'] === 0) && (this.household.Attributes.GrillHeating !== 'GH.00')) {
        //     errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        // }
        //
        // if ((this.household.Appliances['A.06'] > 0) && (this.household.Attributes.StoveHeating === 'STH.00')) {
        //     errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        // } else if ((this.household.Appliances['A.06'] === 0) && (this.household.Attributes.StoveHeating !== 'STH.00')) {
        //     errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        // }

        const Appliances = {};
        for (const el of this.appliances.items) {
            if (el.selected) {
                Appliances[el.iconName] = el.count;// : null;
            }
        }
        const Attributes = {
            // GrillHeating: null, // 'GH.00',
            Occupants: this.occupants.selectedValue,
            // OvenHeating: null, // 'OH.00',
            PropertyAge: this.propertyAge.selectedValue,
            // PropertyLocation: null, // '45128',
            PropertySize: this.propertySize.selectedValue,
            PropertyType: this.propertyType.selectedValue,
            SpaceHeating: this.spaceHeating.selectedValue,
            // StoveHeating: null, // 'STH.00',
            WaterHeating: this.waterHeating.selectedValue
        };

        const payload = {Appliances, Attributes};
        console.log('num elements', Object.keys(Appliances).length);
        console.log('num elements', Object.keys(Appliances));
        console.log('Appliances', payload.Appliances);

        // return;
        if (errors.length > 0) {
            for (const error of errors) {
                this._notification.warning(error);
            }
        } else {
            // Wenn Onboarding-Prozess - Google Analytics Event
            if (this._globals.getIsOnboarding()) {
                this.analytics.trackEvent({
                    action: 'onboarding_profile_end',
                    properties: {
                        category: 'Onboarding'
                    }
                });
                this._globals.resetIsOnboarding();
            }

            if (!this.application.isDemoMode()) {
                this.profile.setAttributes(payload).subscribe(
                    // this.profile.setAttributes(this.household).subscribe(
                    (response) => {
                        console.log('Save settings response: ', response);
                        this._notification.success('Ihre Angaben zum Haushalt wurden übernommen!');
                    },
                    (error) => {
                        this._notification.error('Hoppla, da ist etwas schief gelaufen.', 'Fehler');
                        const error_obj = error.error;
                        if (error_obj.error.code === 180) {
                            console.log(error);
                            console.log(error_obj.error.message);
                        }
                    }
                );
            } else {
                this._notification.info('Die Angaben zum Haushalt können im Demo-Modus nicht verändert werden!');
            }
        }
    }


    /**
     * Process contract data
     * @param data
     */
    private processContractData(data): void {
        if (this.application.isDemoMode()) {
            this.fullname = 'Frau Maxi Mustermann';
        } else {
            this.fullname = data.profile.customer_name;
        }
    }

    /**
     * Process Profile information
     * @param attributes
     */
    private processProfileData(attributes): void {
        if ('Attributes' in attributes) {
            this.propertyType.selectedValue = attributes.Attributes.PropertyType;
            this.propertySize.selectedValue = attributes.Attributes.PropertySize;
            this.propertyAge.selectedValue = attributes.Attributes.PropertyAge;
            this.occupants.selectedValue = attributes.Attributes.Occupants;
            this.spaceHeating.selectedValue = attributes.Attributes.SpaceHeating;
            this.waterHeating.selectedValue = attributes.Attributes.WaterHeating;

            for (const element of this.appliances.items) {
                element.count = attributes.Appliances[element.iconName];
                element.selected = attributes.Appliances[element.iconName] > 0;
            }
        }
    }

}
