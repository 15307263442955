import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {HttpClient} from '@angular/common/http';
import {getProviderImprintFileUrl} from '../../lib/ProviderUtil';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    viewProviders: []
})

export class ImprintComponent implements OnInit {

    @ViewChild('imprintContainer', {static: true})
    private imprintContainer: ElementRef<HTMLElement>;

    constructor(private title: Title,
                private http: HttpClient,
                private user: UserService) {
    }

    ngOnInit() {
        this.title.setTitle('Impressum | iONA');

        const provider = this.user.getActiveUserProvider();
        this.loadImprintFile(provider);
    }

    private loadImprintFile(provider: string): void {
        const url = getProviderImprintFileUrl(provider);
        this.http.get(url, {responseType: 'text'}).subscribe(
            (fileContents) => {
                this.imprintContainer.nativeElement.innerHTML = fileContents;
            }
        );
    }
}
