import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-info-view',
    templateUrl: './info-view.component.html',
    styleUrls: ['./info-view.component.scss']
})
export class InfoViewComponent implements OnInit {

    showContinue = true;

    constructor(private popoverRef: PopoverRef) {
        // this.popoverRef.overlay.backdropClick().subscribe(() => {
        //     this.popoverRef.close(null);
        // });
        if ('showContinue' in this.popoverRef.data) {
            this.showContinue = this.popoverRef.data.showContinue;
        }
    }

    close(): void {
        this.popoverRef.close(null);
    }

    ngOnInit() {
    }


    openESC(): void {
        open('https://smartcontrol.eon.de');
    }

}
