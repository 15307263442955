import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {forkJoin, Observable, of, range, Subject, Subscription, throwError, timer} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, count, flatMap, map, mergeMap, take, toArray} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import * as moment from 'moment';
import {ApplicationService} from './application.service';
import {months} from 'moment';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class InstantaneousService extends BaseService {
    onInstantaneousUpdate = new Subject<any>();

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private useTestData = false;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => this.get())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onInstantaneousUpdate.next(res);
                }
            }
        );
    }

    get(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.instantaneous;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.instantaneous}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            flatMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            flatMap((mapped: any) => {
                if (this.validateInstantaneousResponse(mapped)) {
                    return of(mapped);
                }
                return throwError(
                    {msg: 'Error validating instantaneous response after initial mapping'});
            }),
            catchError((error: any) => this.handleError(error))
        );
    }

    getPhase(start: Date, end: Date, ignoreError = false): Observable<any> {
        const s = moment(start).format('YYYY-MM-DD');
        const e = moment(end).format('YYYY-MM-DD');
        const suffix = `/${s}/${e}`;

        let url = this.API_BASE_URL + constants.api.routes.phase + suffix;
        if (start === null && end === null) {
            url = `assets/data/demo/${constants.demo.files.instantaneous_phases}.json`;
        }

        return this.http.get(url, {headers: this.getDefaultHeaders(this.auth.getToken())}).pipe(
            map((res: { status: string, data: any }) => {
                if (!this.responseValid(res)) {
                    console.log('Response invalid', res);
                    return of(null);

                }
                const r = res['data'];
                if ('L1' in r && 'L2' in r && 'L3' in r) {
                    return r;
                }
                return null;
            }),
            catchError((error: HttpErrorResponse) => {
                if (ignoreError) {
                    return of(
                        {
                            total: {power_min: 0, power_max: 0, power_avg: 0},
                            L1: {power_min: 0, power_max: 0, power_avg: 0},
                            L2: {power_min: 0, power_max: 0, power_avg: 0},
                            L3: {power_min: 0, power_max: 0, power_avg: 0}
                        }
                    );
                }
                return this.handleError(error);
            })
        );
    }

    getPhasesForMonths(start, end): Observable<any> {
        const dates = this.generatesMonthlyRange(start, end);

        if (this.application.isDemoMode()) {
            return this.http.get('assets/data/demo/instantaneous_phases_history.json').pipe(
                map((res: any[]) => {
                    res = res.map((el) => el.data);
                    return this.alignPhaseData(res, dates);
                })
            );
        }

        // const num_history_months = moment(end).diff(moment('01-01-2018'), 'months', true);
        // // return dates.reverse();
        // range(0, Math.ceil(num_history_months)).pipe(
        //     flatMap((i) => {
        //             const month = moment(start).subtract(i, 'month');
        //             const month_start = moment(month).startOf('month');
        //             const month_end = moment(month).endOf('month');
        //             return this.getPhase(month_start.toDate(), month_end.toDate()).pipe(
        //                 map(res => {
        //                     console.log(month_start.toDate().getTime());
        //
        //                     return res;
        //                 })
        //             );
        //         }
        //     ),
        //     toArray()
        // ).subscribe((res) => {
        //     console.log('res in service', res);
        // });
        //
        // return of(dates);
        //

        const calls: Observable<any>[] = dates.map((el) => {
            return this.getPhase(el.start, el.end, true);
        });
        return forkJoin(calls).pipe(
            map(res => {
                return this.alignPhaseData(res, dates);
            })
        );
    }

    private generatesMonthlyRange(start, end): any {
        const dates = [{start: start.toDate(), end: end.toDate()}];

        const num_history_months = moment(end).diff(moment('01-01-2018'), 'months', true);
        for (let i = 1; i < Math.ceil(num_history_months); ++i) {
            const month = moment(start).subtract(i, 'month');
            const month_start = moment(month).startOf('month');
            const month_end = moment(month).endOf('month');
            dates.push({start: month_start.toDate(), end: month_end.toDate()});
        }

        return dates.reverse();
    }

    private alignPhaseData(res: any, dates): any {
        const aligned = {phase1: [], phase2: [], phase3: []};
        const original = {phase1: [], phase2: [], phase3: []};

        res.forEach((el, idx) => {
            const date = dates[idx].start.getTime();

            if (el === null) {
                original.phase1.push([date, 0]);
                original.phase2.push([date, 0]);
                original.phase3.push([date, 0]);

                aligned.phase1.push([date, 0]);
                aligned.phase2.push([date, 0]);
                aligned.phase3.push([date, 0]);

            } else {
                const p1 = el.L1;
                const p2 = el.L2;
                const p3 = el.L3;

                original.phase1.push([date, p1.power_max]);
                original.phase2.push([date, p2.power_max]);
                original.phase3.push([date, p3.power_max]);

                aligned.phase1.push([date, p1.power_max]);
                aligned.phase2.push([date, p2.power_max + p1.power_max]);
                aligned.phase3.push([date, p3.power_max + p2.power_max + p1.power_max]);
            }
        });
        console.log({aligned, original});
        return {aligned, original};
    }

    private validateInstantaneousResponse(mappedResponse: any): boolean {
        if ('electricity' in mappedResponse) {
            if ('current_summation' in mappedResponse.electricity) {
                return true;
            }
        }
        return false;
    }

}
