import {
    AfterViewChecked,
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[appMatchHeight]'
})
export class MatchHeightDirective implements OnInit, AfterViewInit, AfterViewChecked {
    private width: number;

    constructor(private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngAfterViewInit(): void {
        this.initialize();
    }

    ngAfterViewChecked() {
        this.width = this.elementRef.nativeElement.offsetWidth;
        if (this.elementRef.nativeElement.offsetHeight !== this.width) {
            this.initialize();
        }
    }

    private initialize(): void {
        this.width = this.elementRef.nativeElement.offsetWidth;
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${this.width}px`);
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initialize();
    }


}
