import {Injectable} from '@angular/core';
import {Angulartics2} from 'angulartics2';
import {Md5} from 'ts-md5';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private readonly storageAttribute = 'sendAnalyticsData';

    constructor(private analytics: Angulartics2) {
    }

    changeTrackingState(state: boolean): void {
        localStorage.setItem(this.storageAttribute, state ? '1' : '0');
        if (state) {
            this.analytics.developerMode(false);
        } else {
            this.analytics.developerMode(true);
        }
    }

    getTrackingState(): boolean {
        return localStorage.getItem(this.storageAttribute) === '1';
    }

    private isDisabled(): boolean {
        return this.getTrackingState() === false;
    }

    trackEvent(payload): void {
        if (this.isDisabled()) {
            return;
        }
        this.analytics.eventTrack.next(payload);
    }

    onboarding_voucher(with_label: boolean): void {
        if (this.isDisabled()) {
            return;
        }
        this.analytics.eventTrack.next({
            action: TRACKING_ACTION.ONBOARDING_VOUCHER_CODE_ENTER,
            properties: {
                category: 'Onboarding',
                label: with_label.toString()
            }
        });
    }

    onboarding_status(event: TRACKING_EVENT, {connection_type, user_mac}): void {
        if (this.isDisabled()) {
            return;
        }
        switch (event) {
            case TRACKING_EVENT.ONBOARDING_START:
                this.analytics.eventTrack.next({
                    action: TRACKING_ACTION.ONBOARDING_FINISH,
                    properties: {
                        category: 'Onboarding',
                        label: 'mode: ' + connection_type + '; gateway_id: ' + user_mac
                    }
                });
                break;
            case TRACKING_EVENT.ONBOARDING_CANCEL:
                this.analytics.eventTrack.next({
                    action: TRACKING_ACTION.ONBOARDING_CANCEL,
                    properties: {
                        category: 'Onboarding',
                        label: 'mode: ' + connection_type
                    }
                });
                break;
            case TRACKING_EVENT.ONBOARDING_FINISH:
                this.analytics.eventTrack.next({
                    action: TRACKING_ACTION.ONBOARDING_START,
                    properties: {
                        category: 'Onboarding',
                        label: 'mode: ' + connection_type
                    }
                });
                break;

        }

    }
}

export interface EventTrackingPayload {
    action: string;
    properties: {
        category?: string,
        label?: string,
        user_group?: string
    };
}


export enum TRACKING_ACTION {
    ONBOARDING_FINISH = 'onboarding_finish',
    ONBOARDING_CANCEL = 'onboarding_cancel',
    ONBOARDING_START = 'onboarding_start',
    ONBOARDING_VOUCHER_CODE_ENTER = 'onboarding_voucher_code_enter'
}

export enum TRACKING_EVENT {
    ONBOARDING_START,
    ONBOARDING_CANCEL,
    ONBOARDING_FINISH,
    ONBOARDING_VOUCER_ENTER,
}
