import {Component, OnInit, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../../shared/constants/constants';
import {UserService} from '../../services/user.service';
import {MeterService} from '../../services/meter.service';
import {ApplicationService} from '../../services/application.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {InitializationService} from '../../services/initialization.service';
import {catchError, flatMap, map} from 'rxjs/operators';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {SmartBridgeService} from '../../services/smart-bridge.service';
import {Popover} from '../../popovers/popover/popover.service';
import {ActivatedRoute} from '@angular/router';
import {EnergySaverControlComponent} from '../../popovers/energy-saver-control/energy-saver-control.component';
import {MeterConnectionConfig, WifiConnectionConfig}
    from '../../shared/constants/connection.constants';
import {TrackAnalyticsService} from '../../services/track-analytics.service';

@Component({
    selector: 'iona-app',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent extends BaseComponent implements OnInit, OnDestroy {

    config: any = {
        tracking: false,
        notify_consumption: 0,
        notify_finance: 0
    };

    // connection qualities
    wifiConnectionQuality = 0;
    wifiConnected = 0;
    lanConnected = false;
    wifiConnectionConfig = WifiConnectionConfig;

    meterConnectionQuality = 0;
    meterStatus = 'disconnected';
    meterConnectionConfig = MeterConnectionConfig;

    deviceIsPlug = false;

    isEDGUser = false;
    batteryStates = [1, 2, 3, 4, 5];
    currentBatteryState = 0;
    currentEnergySaverThreshold = 1;

    private initalChange = false;

    constructor(public application: ApplicationService,
                private pageTitle: Title,
                private notifificationService: ToastrService,
                private userService: UserService,
                private meterService: MeterService,
                private versionService: VersionService,
                private registrationService: RegistrationService,
                private initializationService: InitializationService,
                private analyticsService: TrackAnalyticsService,
                private opticalReader: OpticalReaderService,
                private smartBridge: SmartBridgeService,
                private popover: Popover,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.initComponent();
    }

    ngOnDestroy() {
    }

    /**
     * Base component initialization
     */
    private initComponent(): void {
        this.pageTitle.setTitle('Einstellungen | iONA');

        if (this.userService.getUserDevice() === constants.application.devices.plug) {
            this.deviceIsPlug = true;
        }

        this.initializeMeterStatus();
        this.isEDGUser = this.userService.isEDGUser();
        if (this.isEDGUser) {
            this.getOpticalReaderBatteryStatus();
        }

        this.config.tracking = this.analyticsService.getTrackingState();
        this.listenForRouteChanges();
    }

    /**
     * Initialize the Meter Status to determine the current conneciton quality
     */
    private initializeMeterStatus(): void {
        this.addSub(this.initializationService.getMeterConnectionInfo()
            .subscribe(status => this.meterStatus = status));
        this.getMeterStatus();
    }

    /**
     * Listen for changes on the current component route
     *  if the field 'open' container 'energy-saver' directly
     *  open the energy saver configuration overlay
     */
    private listenForRouteChanges(): void {
        this.route.params.subscribe((params) => {
            if (!('open' in params)) {
                return;
            }
            if (params.open === 'energy-saver') {
                this.onEnergySaverOpen();
            }
        });
    }

    /**
     * Request the current battery status of the optical reader
     */
    private getOpticalReaderBatteryStatus(): void {
        this.opticalReader.getOpticalReaderStatus().subscribe((res) => {
            this.currentBatteryState = res.battery_status;
            this.meterConnectionQuality = res.connection_quality;
        });
    }

    /**
     * Get the current meter status
     */
    private getMeterStatus(): void {
        this.addSub(this.registrationService.getOnline().pipe(
            flatMap((res) => {
                return this.meterService.getStatus();
            }),
            map((res) => {
                this.meterConnectionQuality = ('meter_txrssi' in res) ? res.meter_txrssi : 0;
                if ('wlan_rssi' in res) {
                    this.wifiConnectionQuality = res.wlan_rssi;
                    this.wifiConnected = this.wifiConnectionQuality;
                    if (this.wifiConnectionQuality === 0) {
                        this.lanConnected = true;
                        return true;
                    }
                    return true;
                }
                return null;
            }),
            catchError((error) => {
                console.log('error', error);
                return this.meterService.getStatus().pipe(
                    map((res) => {
                        this.meterConnectionQuality =
                            ('meter_txrssi' in res) ? res.meter_txrssi : 0;
                        this.wifiConnected = 0;
                        return false;
                    })
                );
            })
        ).subscribe((res) => {
            console.log('result', res);
        }));
    }

    /**
     * Set the current tracking state
     * @param value
     */
    setTracking(value: boolean) {
        this.config.tracking = value;
    }

    /**
     * Save the made changes
     */
    saveSettings() {
        this.analyticsService.changeTrackingState(this.config.tracking);
        this.notifificationService.success('Ihre Einstellungen wurden erfolgreich gespeichert!');
    }

    /**
     * Determine the current Meter status message
     */
    determineMeterStatusMessage(): string {
        switch (this.meterStatus) {
            case 'connected':
                return 'verbunden';
            default:
                return 'nicht verbunden';
        }
    }

    /**
     * Opens the EnergySaverConfig Overlay
     */
    onEnergySaverOpen(): void {
        this.popover.open({
            content: EnergySaverControlComponent,
            data: {threshold: this.currentEnergySaverThreshold},
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe((res) => {
        });
    }
}
