import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {Observable, of, Subscription, throwError, timer} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ApiService} from './api.service';
import {catchError, flatMap, map} from 'rxjs/operators';
import {UserService} from './user.service';
import * as moment from 'moment';
import {User} from '../classes/user';
import {InitializationService} from './initialization.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {RegistrationService} from './registration.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {

    private updateRate = (3600 - 30) * 1000;

    private timerSub: Subscription = null;

    private loginErrorCount = 0;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private initialization: InitializationService,
                private router: Router,
                private toast: ToastrService,
                private _apiService: ApiService,
                private registration: RegistrationService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
    }


    login(username: string, password: string, first_call = false): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        let params = new HttpParams();
        params = params.append('method', 'login');
        params = params.append('username', username);
        params = params.append('password', password);

        const body = {method: 'login', username, password};
        return this.http.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers}).pipe(
            flatMap((res: any) => {
                if (!res) {
                    return of(null);
                }
                if (!('access_token' in res)) {
                    return of(null);
                }

                const token_expires = moment().add(res.expires_in, 'seconds');
                this.updateRate = (res.expires_in - 30) * 1000;

                const u: User = {
                    email: username,
                    provider: null,
                    access_token: res.access_token,
                    refresh_token: res.refresh_token,
                    token_expires: token_expires.toDate(),
                    tiles: null,
                    nilm_status: null,
                    storage: null,
                    device: null,
                    edg_user: false
                };
                this.user.setCurrentUser(u);

                if (!first_call) {
                    return this.initialization.get().pipe(
                        map((init_res) => {
                            console.log('init', init_res);
                            if ('profile' in init_res) {
                                return init_res;
                            }
                            return null;
                        }),
                        flatMap((init_data) => {
                            if (!init_data) {
                                return of(null);
                            }
                            // console.warn('reenable provider name check');
                            const providername = init_data.profile.labelpartner;
                            // if (providername.toLowerCase() === 'innogy' ||
                            //     providername.toLowerCase() === 'e.on') {
                            //     return throwError({providername: false});
                            // }
                            this.user.setActiveUserProvider(providername);
                            return of(true);
                        }),
                        flatMap((success) => {
                            if (success) {
                                return this.registration.getModel().pipe(
                                    map((res) => {
                                        console.log('model res in login ');
                                        this.user.updateUserDevice(res.model_identifier);
                                        this.user.setActiveUserProvider(res.labelpartner);
                                        this.user.setEDGUser(res.data.model_identifier === 'SPLR4911');
                                        return true;
                                    }),
                                    catchError((error) => of(true))
                                );
                            }
                        }),
                        catchError((error) => {
                            console.log('error here', error);
                            if ('provider' in error) {
                                return throwError(error);
                            }
                            return of(null);
                        })
                    );
                }
                return res;
            }),
            catchError((error_response) => {
                console.log('error', error_response);
                if ('provider' in error_response) {
                    return throwError(error_response);
                }
                const error = error_response.error;
                const status = error_response.status;
                this.loginErrorCount++;

                if (this.loginErrorCount >= 3) {
                    this.toast.error('Möchten Sie Ihr Passwort zurücksetzen? Sie erhalten zunächst eine Email mit einer Anleitung zum weiteren Vorgehen. <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                        disableTimeOut: true,
                        enableHtml: true
                    });
                }

                if (status === 125) {
                    console.log('User appears to not be onboarded yet');
                }

                this.notifyBasedOnError(error);
                return throwError(error);
            })
        );
    }

    startContinuousTokenRefresh(): void {
        if (this.timerSub) {
            return;
        }

        this.timerSub = timer(0, this.updateRate).pipe(
            flatMap((c) => this.refreshToken())
        ).pipe(
            map((res) => res),
            catchError((error) => this.handleError(error))
        ).subscribe((res) => {
            if (res) {
                if (!res) {
                    return false;
                }
                let expires: any = new Date();
                expires.setSeconds(res.expires_in);
                this.user.updateActiveUserAccessToken(res.access_token);
                this.user.updateActiveUserRefreshToken(res.refresh_token);
                this.user.updateActiveUserTokenExpire(expires);
                return true;
            }
        });
    }

    refreshToken(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        const body = {method: 'refresh', refresh_token: this.user.getActiveUserRefreshToken()};
        return this.http.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers});
    }

    private notifyBasedOnError(error): void {
        console.log('error', error);
        switch (error.error) {
            case 'invalid_request': {
                this.toast.error('E-Mail-Adresse oder Passwort fehlt!');
                break;
            }
            case 'invalid_grant': {
                this.toast.error('E-Mail-Adresse oder Passwort fehlerhaft!');
                break;
            }
            case 'Maximum number of failed attempts reached. The account is now blocked.': {
                this.toast.error(
                    'Ihr Account wurde nach der 10-maligen Eingabe eines falschen Passworts gesperrt. Möchten Sie Ihr Passwort zurücksetzen? <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                        disableTimeOut: true,
                        enableHtml: true
                    });
                break;
            }
            default: {
                this.toast.error('Es ist ein Problem aufgetreten!');
            }
        }
    }

}

export interface LoginResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: any;
    token_type: string;
}
